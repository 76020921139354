import React from "react";
import HeaderForm from "./form/headerForm";
import LoginForm from "./form/loginForm";
import FooterForm from "./form/footerForm";
import styles from './styles/index.module.scss'

const FormLogin = () => {
  return (
    <div className={styles['form-login-responsive']}>
      <div>
        <HeaderForm />
      </div>
      <div style={{marginTop: '32px'}}>
        <LoginForm />
      </div>
      <div>
        <FooterForm />
      </div>
    </div>
  );
};

export default FormLogin;
