import React from 'react'

import Navigation from './components/Navigation'
import MobileHeader from './components/Header'
import BusinessPointTable from './components/BusinessPointTable'
import BusinessPointUsedTable from './components/BusinessPointUsedTable'

export default function PointMobile() {
  return (
    <div
      id="point-mobile"
      style={{ backgroundColor: '#F9F9F9', minHeight: '100vh', paddingBottom: '100px' }}
    >
      <MobileHeader />

      <BusinessPointTable />

      <BusinessPointUsedTable />

      <Navigation />
    </div>
  )
}
