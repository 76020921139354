import styled from 'styled-components'
import BellIcon from 'assets/img/mobile/bell-48x48.png'
import SearchIcon from 'assets/img/mobile/search-48x48.png'
import BackIcon from 'assets/img/mobile/back-100x100.png'
import userIcon from 'assets/img/mobile/user-25.png'
import LogoIcon from 'assets/img/logo-icon.png'

export const HeaderBlock = styled.div`
  height: 60px;
`

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 60px;
  align-items: center;
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 60px;
  align-items: center;
`

export const HeaderTitle = styled.div`
  display: flex;
  font-size: 17px;
  font-weight: bold;
  color: #525252;
`
export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 425px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 3;
  box-shadow: 0 1px 5px 1px #dddddd;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
`

export const Logo = styled.div`
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background-image: url(${LogoIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 10px;
  font-weight: 900;
  color: white;
  font-size: 7px;
  font-weight: 900;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const NotificationBell = styled.div`
  margin-left: 15px;
  width: 40px;
  height: 40px;
  background-color: #e8e8e8;
  border-radius: 50%;
  background-image: url(${BellIcon});
  background-size: 25px;
  background-position-x: 5px;
  background-position-y: 7px;
  background-repeat: no-repeat;
  position: relative;
  background-blend-mode: exclusion;
`

export const NotificationDotted = styled.div`
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 25px;
`

export const SearchButton = styled.div`
  width: 40px;
  height: 40px;
  background-color: #e8e8e8;
  border-radius: 50%;
  background-image: url(${SearchIcon});
  background-size: 22px;
  background-position-x: 9px;
  background-position-y: 9px;
  background-repeat: no-repeat;
  position: relative;
  background-blend-mode: exclusion;
`

export const SearchContainer = styled.div`
  width: calc(100% - (50px * 2));
  height: 40px;
  display: flex;
  align-items: center;

  a {
    width: 100%;
  }
`

export const SearchInput = styled.input`
  height: 38px;
  line-height: 38px;
  width: 100%;
  border-radius: 20px;
  color: black;
  border: 1px solid #2c395400;
  padding-left: 40px;
  transition: box-shadow 0.3s, border 0.3s;
  background-color: #e8e8e8;
  font-size: 14px;
  background-image: url(${SearchIcon});
  background-size: 20px;
  background-position-y: center;
  background-position-x: 10px;
  background-repeat: no-repeat;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  background-blend-mode: exclusion;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: gray;
  }
`

export const UserAvatar = styled.div`
  margin-left: 15px;
  width: 40px;
  height: 40px;
  background-color: #e8e8e8;
  border-radius: 50%;
  ${(props) =>
    props.avatar
      ? `
  background-image: url(${props.avatar});
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  `
      : `
  background-image: url(${userIcon});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 9px;
  background-position-y: 9px;
  background-blend-mode: exclusion;
  `}
  position: relative;
`

export const BackButton = styled.div`
  width: 40px;
  height: 40px;
  background-color: #e8e8e8;
  border-radius: 50%;
  background-image: url(${BackIcon});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 9px;
  background-position-y: 9px;
  position: relative;
  background-blend-mode: exclusion;
`
