import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationsSystem } from 'redux/actions/notification'

const SocketClient = () => {
  const { socket } = useSelector((state) => state)
  let dataUser = useSelector((state) => state.userInfo)
  const { profileUser } = useSelector((state) => state)

  const dispatch = useDispatch()
  // joinUser

  useEffect(() => {
    if (dataUser) {
      socket.emit('joinUser', { ...dataUser })
    }
  }, [socket, dataUser])

  useEffect(() => {
    socket.on('addMessageToClient', (msg) => {
      dispatch({ type: 'ADD_MESSAGE', payload: msg })

      dispatch({
        type: 'ADD_USER',
        payload: {
          ...msg.user,
          text: msg.text,
          media: msg.media,
        },
      })
    })

    return () => socket.off('addMessageToClient')
  }, [socket, dispatch])

  useEffect(() => {
    socket.on(`followToClient`, newUser =>{
        dispatch({type: 'SET_USERINFO', data: newUser.new_following})
        if(profileUser?.users?.length > 0) {
          const newData = profileUser.users.find((e) => e.user_id == newUser.new_follower.user_id)
          if(newData) {
            dispatch({ type: 'FOLLOW', payload: newUser.new_follower})
          }
        }

    })

    return () => socket.off('followToClient')
  },[socket, dispatch, dataUser, profileUser])

  useEffect(() => {
    socket.on('unFollowToClient', newUser =>{
        dispatch({type: 'SET_USERINFO', data: newUser.new_following})
        if(profileUser?.users?.length > 0) {
          const newData = profileUser.users.find((e) => e.user_id == newUser.new_follower.user_id)
          console.log(newData)
          if(newData) {
            dispatch({ type: 'FOLLOW', payload: newUser.new_follower})
          }
        }
    })

    return () => socket.off('unFollowToClient')
  },[socket, dispatch, dataUser, profileUser])

  useEffect(() => {
    socket.on(`noti-follow:action:${dataUser?.user_id}`, async (res) => {
      dispatch(getNotificationsSystem())
    })
    return () => socket.off(`noti-follow:action:${dataUser?.user_id}`)
  }, [socket, dataUser])

  useEffect(() => {
    socket.on(`unnoti-follow:action:${dataUser?.user_id}`, async (res) => {
      dispatch(getNotificationsSystem())
    })
    return () => socket.off(`unnoti-follow:action:${dataUser?.user_id}`)
  }, [socket, dataUser])

  useEffect(() => {
    socket.on(`confirm-follow:action:${dataUser?.user_id}`, async (res) => {
      dispatch(getNotificationsSystem())
    })
    return () => socket.off(`confirm-follow:action:${dataUser?.user_id}`)
  }, [socket, dataUser])

  useEffect(() => {
    socket.on(`un-follow:action:${dataUser?.user_id}`, async (res) => {
      dispatch(getNotificationsSystem())
    })
    return () => socket.off(`un-follow:action:${dataUser?.user_id}`)
  }, [socket, dataUser])

  return <></>
}
export default SocketClient
