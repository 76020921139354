import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProfileUsers } from 'redux/actions/profileUser'
import Info from './info/Info'
import Posts from './posts/posts'
import styles from './styles/profile.module.scss'

const Profile = () => {
  const { profileUser } = useSelector((state) => state)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userInfo)

  const { id } = useParams()
  const [saveTab, setSaveTab] = useState(false)

  useEffect(() => {
    if (profileUser.ids.every((item) => item !== id)) {
      dispatch(getProfileUsers({ id }))
    }
  }, [id, dispatch, profileUser.ids])

  return (
    <div className={styles['profile']}>
        <Info user={user} profileUser={profileUser} dispatch={dispatch} id={id} />

        {user.user_id === id && (
          <div className={styles['profile_tab']}>
            <button className={styles[saveTab ? '' : 'active']} onClick={() => setSaveTab(false)}>
              Posts
            </button>
            <button className={styles[saveTab ? 'active' : '']} onClick={() => setSaveTab(true)}>
              Saved
            </button>
          </div>
        )}

        {saveTab ? (
          // <Saved user={user} dispatch={dispatch} />
          <></>
        ) : (
          <Posts user={user} profileUser={profileUser} dispatch={dispatch} id={id} />
        )}
    </div>
  )
}

export default Profile
