import { useState, useEffect } from 'react'
import styles from './settinginfor.module.scss'
import moment from 'moment'
import cccd1 from 'assets/icons/CCCD1.png'
import cccd2 from 'assets/icons/CCCD2.png'
import DefaultLogo from 'assets/img/store-default.png'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Button, Tabs, List, notification, message, InputNumber } from 'antd'
import { Form, Input, Select, DatePicker, Upload } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { updateCurrentUser, validateCurrentUser } from 'apis/user'
import { regexRemoveUnicode } from 'consts'
import { getUserInfo } from 'redux/actions/user'
const { Option } = Select
export default function SettingInfor({ reload }) {
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }
  const dispatch = useDispatch()

  let user = useSelector((state) => state.userInfo)

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState('')
  const [image1, setImage1] = useState('')
  const [image2, setImage2] = useState('')
  const [birthDay, setBirthDay] = useState(moment(user.date_of_birth))

  const disabledDate = (current) => {
    return current && current >= moment()
  }

  const updateUser = async () => {
    const dataForm = form.getFieldsValue()
    try {
      await form.validateFields()

      const body = {
        ...dataForm,
        first_name: dataForm.first_name || '',
        last_name: dataForm.last_name || '',
        name_identification: dataForm.name_identification || '',
        email: dataForm.email || '',
        status_email: dataForm.status_email,
        address: dataForm.address || '',
        status_address: dataForm.status_address || '',
        web: dataForm.web || '',
        status_web: dataForm.status_web,
        bio: dataForm.bio || '',
        status_bio: dataForm.status_bio,
        gender: dataForm.gender,
        status_gender: dataForm.status_gender,
        date_of_birth: dataForm.date_of_birth || '',
        status_date_of_birth: dataForm.status_date_of_birth,
        avatar: avatar || '',
      }
      setLoading(true)
      let res = await updateCurrentUser(body, user.user_id)
      if (res.status === 200) {
        notification.success({ message: 'Cập nhật thông tin cá nhân thành công' })
        dispatch({
          type: 'UPDATE_USERINFO',
          data: { ...body },
        })
        dispatch(getUserInfo())
        reload({ user_id: user.user_id })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thông tin cá nhân không thành công',
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const validate = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      const body = {
        ...dataForm,
        card_type: dataForm.card_type || '',
        id_card: dataForm.id_card || '',
        location_card: dataForm.location_card || '',
        date_card: dataForm.date_card || '',
        fullname: dataForm.fullname || '',
        image1: image1 || '',
        image2: image2 || '',
      }
      setLoading(true)
      let res = await validateCurrentUser(body, user.user_id)
      if (res.status === 200) {
        notification.success({ message: 'Cập nhật thông tin cá nhân thành công' })
        dispatch({
          type: 'UPDATE_USERINFO',
          data: { ...body },
        })
        reload({ user_id: user.user_id })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thông tin cá nhân không thành công',
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        name_identification: user.name_identification,
        first_name: user.first_name,
        last_name: user.last_name,
        web: user.web,
        bio: user.bio,
        email: user.email,
        address: user.address,
        phone: user.phone,
        gender: user.gender,
        date_of_birth: moment(user.date_of_birth),
        card_type: user.card_type,
        id_card: user.id_card,
        fullname: user.fullname || '',
        location_card: user.location_card,
        date_card: moment(user.date_card),
        status_bio: user.status_bio,
        status_email: user.status_email,
        status_address: user.status_address,
        status_gender: user.status_gender,
        status_web: user.status_web,
        status_date_of_birth: user.status_date_of_birth,
      })
      setAvatar(user.avatar)
      setImage1(user.CMND1)
      setImage2(user.CMND2)
    }
  }, [user])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      notification.warning({ message: 'Bạn chỉ có thể tải lên tệp JPG / PNG / JPEG!' })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.warning({ message: 'Hình ảnh phải có kích thước nhỏ hơn 2MB!' })
    }
    return isJpgOrPng && isLt2M
  }

  const props = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setAvatar(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }
  const props1 = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setImage1(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }

  const props2 = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setImage2(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }

  return (
    <div className={styles['container-layout-setting']}>
      <div className={styles['font-bold']}>Thiết lập thông tin cá nhân </div>

      <div>
        <div
          style={{
            marginLeft: 0,
            marginTop: 0,
            width: '100%',
            height: '100%',
            marginBottom: 15,
          }}
          className={styles['card-overview']}
        >
          <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Tabs centered>
              <Tabs.TabPane tab="Thiết lập thông tin" key="1">
                <div style={{ width: '100%', overflowY: 'scroll' }}>
                  <div className={styles['container']}>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={avatar || DefaultLogo} size={100} />}
                        title={
                          <div style={{ marginLeft: 10 }}>
                            <div className={styles['meta-name']}>
                              {user.first_name + ' ' + user.last_name}
                            </div>
                            <Upload {...props} showUploadList={false}>
                              <div
                                style={{ color: '#0BB2FB', fontSize: '14px', cursor: 'pointer' }}
                              >
                                Thay đổi ảnh đại diện
                              </div>
                            </Upload>
                          </div>
                        }
                      />
                    </List.Item>
                    <Form
                      form={form}
                      layout="horizontal"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 24 }}
                      style={{ marginTop: 20 }}
                      onFinish={updateUser}
                    >
                      <Form.Item label="Họ" className={styles['margin-bottom']}>
                        <Form.Item className={styles['width-input']} name="last_name">
                          <Input placeholder="Nhập họ"></Input>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item label="Tên" className={styles['margin-bottom']}>
                        <Form.Item className={styles['width-input']} name="first_name">
                          <Input placeholder="Nhập tên"></Input>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item label="Tên định danh" className={styles['margin-bottom']}>
                        <Form.Item className={styles['width-input']} name="name_identification">
                          <Input placeholder="Nhập tên định danh"></Input>
                        </Form.Item>
                      </Form.Item>
                      <div className={styles['content']} style={{ marginLeft: '18%' }}>
                        <p>
                          Hãy lấy tên mà bạn thường dùng để tài khoản của bạn dễ tìm thấy hơn. Đó có
                          thể là tên đầy đủ, biệt danh hoặc tên doanh nghiệp. Bạn chỉ có thể đổi tên
                          mình 2 lần trong vòng 14 ngày.
                          <a>Tìm hiểu thêm.</a>
                        </p>
                      </div>
                      <Form.Item label="Website" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item
                            className={styles['width-input']}
                            name="web"
                            rules={[
                              {
                                pattern: RegExp(
                                  /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                                ),
                                message: 'Website không đúng định dạng',
                              },
                            ]}
                          >
                            <Input
                              onInput={(e) => {
                                e.target.value = e.target.value.replaceAll(' ', '')
                              }}
                              placeholder="Nhập website "
                            />
                          </Form.Item>
                          <Form.Item name="status_web">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Bio" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item className={styles['width-input']} name="bio">
                            <Input.TextArea rows={4} placeholder="Nhập nội dung " />
                          </Form.Item>
                          <Form.Item name="status_bio">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Email" name="email" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item
                            name="email"
                            className={styles['width-input']}
                            rules={[
                              {
                                type: 'email',
                                message: 'Email không đúng định dạng',
                              },
                              {
                                pattern: regexRemoveUnicode,
                                message: 'Email không đúng định dạng',
                              },
                            ]}
                          >
                            <Input placeholder="Nhập email " />
                          </Form.Item>
                          <Form.Item name="status_email">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Địa chỉ" name="address" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item name="address" className={styles['width-input']}>
                            <Input placeholder="Nhập địa chỉ " />
                          </Form.Item>
                          <Form.Item name="status_address">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Số điện thoại" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item className={styles['width-input']} name="phone">
                            <Input placeholder="Nhập số điện thoại" disabled={true} />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Giới tính" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item className={styles['width-input']} name="gender">
                            <Select placeholder="Chọn giới tính">
                              <Option value={0}>Nam</Option>
                              <Option value={1}>Nữ</Option>
                              <Option value={2}>Khác</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item name="status_gender">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Ngày sinh" className={styles['margin-bottom']}>
                        <Input.Group compact>
                          <Form.Item className={styles['width-input']} name="date_of_birth">
                            <DatePicker disabledDate={disabledDate} />
                          </Form.Item>
                          <Form.Item name="status_date_of_birth">
                            <Select placeholder="Trạng thái" style={{ width: 120, marginLeft: 10 }}>
                              <Option value={1}>Công khai</Option>
                              <Option value={0}>Riêng tư</Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className={styles['button-push']}
                          loading={loading}
                        >
                          Lưu thông tin
                        </Button>
                        <Button htmlType="button" className={styles['button-push']}>
                          Huỷ bỏ
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Xác thực" key="2">
                <div style={{ width: '100%', paddingTop: 10 }}>
                  <div className={styles['container-accuracy']}>
                    <div className={styles['container-guide']}>
                      <div className={styles['container-item']}>
                        <p className={styles['title-item']}>
                          Xác thực CMND mang lại những lợi ích gì?
                        </p>
                        <p className={styles['content-item']}>
                          Được tạo cửa hàng và sử dụng miễn phí phần mềm quản lý bán hàng Đảm bảo
                          hoàn toàn độ tin cậy đối với khách hàng và cửa hàng.
                        </p>
                        <p className={styles['content-item']}>
                          Quá trình thanh toán nhanh hơn, các vấn đề tài khoản hay khó khăn khi làm
                          việc có thể được giải quyết nhanh chóng.
                        </p>
                      </div>
                      <div className={styles['container-item']}>
                        <p className={styles['title-item']}>Chụp CMND như thế nào cho đúng cách?</p>
                        <p className={styles['content-item']}>
                          1. CMND được chụp chính diện, không nghiêng ngả.
                        </p>
                        <p className={styles['content-item']}>
                          {' '}
                          2. Chụp trên nền mầu trắng, không bị xen lẫn chữ hay hình vẽ.{' '}
                        </p>
                        <p className={styles['content-item']}>
                          4. Xoay ảnh đúng chiều đọc được khi chụp xong.
                        </p>
                        <p className={styles['content-item']}>
                          3. Ảnh chụp CMND phải thấy hết toàn bộ CMND, không bị cắt{' '}
                        </p>
                      </div>
                      <div className={styles['container-item']}>
                        <p className={styles['title-item']}> Làm cách nào để xác thực CMND?</p>
                        <p className={styles['content-item']}>
                          Scan và đăng lên Ảnh CMND cả 2 mặt.
                        </p>
                        <p className={styles['content-item']}>
                          Điền đầy đủ, chính xác Số CMND, Ngày cấp, Nơi cấp.
                        </p>
                        <p className={styles['content-item']}> Bấm nút Lưu thông tin CMND.</p>
                        <p className={styles['content-item']}> Bấm nút Xác thực CMND.</p>
                        <p className={styles['content-item']}>
                          {' '}
                          Ekata sẽ xác thực trong vòng 72 tiếng.
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles['dashboard_manager_bottom_row_col_parent_top']}
                      style={{ marginBottom: 28, marginTop: 24 }}
                    ></div>
                    <div>
                      <Form
                        layout="horizontal"
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <div className={styles['container-input']}>
                          <Form.Item
                            name="card_type"
                            label="Loại thẻ"
                            className={styles['width-input-xt']}
                          >
                            <Select placeholder="Chọn loại thẻ xác thực ">
                              <Option value="CCCD">CCCD</Option>
                              <Option value="CMND">CMND</Option>
                              <Option value="Hộ chiếu">Hộ chiếu</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles['container-guide']}>
                          <div className={styles['conyainer-form']}>
                            <div style={{ display: 'flex' }}>
                              <div className={styles['container-node']}>
                                <div className={styles['node-title']}>Hình ảnh mặt trước</div>
                                <div className={styles['node-describe']}>
                                  Kích thước 10MB. Định dạng jpg, jpeg, png, gif. Độ phân giải tối
                                  thiểu 1200x1200px
                                </div>
                              </div>
                              <Form.Item valuePropName="fileList" noStyle>
                                <Upload
                                  showUploadList={false}
                                  listType="picture-card"
                                  className={styles['ant-upload-select']}
                                  {...props1}
                                >
                                  {image1 ? (
                                    <img
                                      src={image1}
                                      alt="image1"
                                      style={{
                                        width: '100%',
                                        height: 178,
                                        objectFit: 'cover',
                                        borderRadius: 18,
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {loading ? <LoadingOutlined /> : null}
                                      <div>
                                        <div>
                                          {' '}
                                          <Avatar
                                            className={styles['container-icon-image']}
                                            src={cccd1}
                                          />
                                        </div>
                                        <div className={styles['text-upload']}>Mặt trước</div>
                                      </div>
                                    </div>
                                  )}
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className={styles['container-input']}>
                              <Form.Item label="Mã số ID" className={styles['margin-bottom']}>
                                <Form.Item
                                  rules={[
                                    {
                                      pattern: /^(?:\d*)$/,
                                      message: 'Chỉ được nhập kí tự số',
                                    },
                                    {
                                      pattern: /^[\d]{0,50}$/,
                                      message: 'Giá trị nhỏ hơn 50 kí tự',
                                    },
                                  ]}
                                  className={styles['width-input']}
                                  name="id_card"
                                >
                                  <Input
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replaceAll(' ', '')
                                    }}
                                    placeholder="Nhập mã số ID của bạn"
                                  />
                                </Form.Item>
                              </Form.Item>
                              <Form.Item label="Họ và tên" className={styles['margin-bottom']}>
                                <Form.Item className={styles['width-input']} name="fullname">
                                  <Input placeholder="Nhập họ và tên"></Input>
                                </Form.Item>
                              </Form.Item>
                              <Form.Item label="Ngày sinh" name="date_of_birth">
                                <DatePicker className={styles['width-input']} />
                              </Form.Item>
                            </div>
                          </div>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div className={styles['container-node']}>
                                <div className={styles['node-title']}>Hình ảnh mặt sau</div>
                                <div className={styles['node-describe']}>
                                  Kích thước 10MB. Định dạng jpg, jpeg, png, gif. Độ phân giải tối
                                  thiểu 1200x1200px
                                </div>
                              </div>
                              <Form.Item valuePropName="fileList" noStyle>
                                <Upload
                                  showUploadList={false}
                                  listType="picture-card"
                                  className={styles['ant-upload-select']}
                                  {...props2}
                                >
                                  {image2 ? (
                                    <img
                                      src={image2}
                                      alt="image2"
                                      style={{
                                        width: '100%',
                                        height: 178,
                                        objectFit: 'cover',
                                        borderRadius: 18,
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {loading ? <LoadingOutlined /> : null}
                                      <div>
                                        <div>
                                          {' '}
                                          <Avatar
                                            className={styles['container-icon-image']}
                                            src={cccd1}
                                          />
                                        </div>
                                        <div className={styles['text-upload']}>Mặt sau</div>
                                      </div>
                                    </div>
                                  )}
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className={styles['container-input']}>
                              <Form.Item label="Ngày cấp" name="date_card">
                                <DatePicker className={styles['width-input']} />
                              </Form.Item>
                              <Form.Item label="Nơi cấp" className={styles['margin-bottom']}>
                                <Form.Item className={styles['width-input']} name="location_card">
                                  <Input placeholder="Nhập nơi cấp"></Input>
                                </Form.Item>
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <Form.Item {...tailLayout}>
                          <Button
                            htmlType="submit"
                            type="primary"
                            className={styles['button-push']}
                            loading={loading}
                            style={{ width: '22%' }}
                            onClick={validate}
                          >
                            Xác thực thông tin
                          </Button>
                          <Button htmlType="button" className={styles['button-push']}>
                            Huỷ bỏ
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
