import React from 'react'
import styles from './mycart.module.scss'
import ImageDefault from 'assets/img/logo-icon.png'
import { Tag } from 'antd'
import { formatCash } from 'utils'

const CartData = ({ item }) => {
  if (!item) return <></>
  return (
    <div className={styles['items']}>
      <div className={styles['item-main']}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className={styles['img-voucher']}
            alt="example"
            src={item.image[0] || ImageDefault}
          />
        </div>
        <div className={styles['item-body']}>
          <div>
            <span>{item.title}</span>
          </div>
          <div>
            <Tag style={{ color: 'red', background: 'white', borderColor: 'red' }}>
              Khuyến mãi 5%
            </Tag>
          </div>
          <div>
            <span>VNĐ {formatCash(item.price)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartData
