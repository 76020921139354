import { useDispatch } from 'react-redux'
import { getAllProductPublic } from 'apis/feed'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

export async function LoadFeed() {
  const dispatch = useDispatch()

  try {
    const accessToken = Cookies.get('accessToken')
    const payload = accessToken && jwt_decode(accessToken)
    if (!payload) return
    const res = await getAllProductPublic()
    dispatch({
      type: 'SET_ALL_PRODUCTS',
      data: res.data.data,
    })
  } catch (error) {
    console.log(error)
  }
}
