import {useDispatch} from 'react-redux'
import { getNotificationStaff } from 'apis/nofitication';
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export async function LoadNotification() {
    const dispatch = useDispatch();

    try {

        const accessToken = Cookies.get('accessToken');
        const payload = accessToken && jwt_decode(accessToken)
        
        if (!payload) return;
        const res = await getNotificationStaff();
        dispatch({
            type: 'SET_NOTIFICATION',
            data: res.data.data
        })
    } catch (error) {
    }
}


