import { Button } from "antd";
import React from "react";

const ButtonCustom = ({ text, height, color, bgColor, bdColor, none }) => {
  const stylesButton = {
    width: "100%",
    backgroundColor: bgColor || "#335BD3",
    borderColor: bdColor || "#325de1",
    color: color || "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    height: height || "44px",
    fontFamily: "Inter",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    fontStyle: "normal",
    cursor: "pointer",
  };
  return (
    <Button style={stylesButton} htmlType="submit">
      {text}
    </Button>
  );
};

export default ButtonCustom;
