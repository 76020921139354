import { Popover } from 'antd'
import React from 'react'
import QRCode from 'qrcode.react'
import { UpOutlined } from '@ant-design/icons'
import styles from '../styles/discount-info.module.scss'

const DiscountInfo = () => {
  return (
    <Popover
      placement="top"
      title={
        <div className={styles['title-discount']}>
          <span>Chi tiết khuyến mãi</span>
        </div>
      }
      content={
        <div className={styles['content-discount']}>
          <div>
            <div>
              <span className={styles['span-1']}>Tổng tiền hàng</span>
            </div>
            <div>
              <span className={styles['span-1']}>Giảm giá sản phẩm</span>
            </div>
            <div>
              <span className={styles['span-1']}>Tổng số tiền</span>
            </div>
            <div>
              <span className={styles['span-1']}>Tiết kiệm</span>
            </div>
          </div>
          <div>
            <div>
              <span className={styles['span-1']}>900,000đ</span>
            </div>
            <div>
              <span className={styles['span-1']}>-57,000đ</span>
            </div>
            <div>
              <span className={styles['span-1']}>843,000đ</span>
            </div>
            <div>
              <span style={{color: '#1E4DB7'}} className={styles['span-1']}>-57,000đ</span>
            </div>
          </div>
        </div>
      }
    >
      <span>
        Chi tiết khuyến mãi <UpOutlined />
      </span>
    </Popover>
  )
}

export default DiscountInfo
