import { Layout, Typography, Row, Col,Skeleton, Divider} from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { EllipsisOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationListStaff from './NotificationLists/notification-list'

const { Title } = Typography

const NotificationList = () => {
  let notification = useSelector((state) => state.notification)
  const [load, setLoad] = useState(false);

  const loadMoreData = () => {
    if (load) {
      return;
    }
    setLoad(true);
    if(notification){
      setLoad(false)
    } 
  };


  return (
    <Layout style={{
      marginTop:'15px',
      overflowY: 'scroll'
    }}>
      <Row >
        <Col
          xs={{
            span: 5,
            offset: 1,
          }}
          lg={{
            span: 3,
            offset: 2,
          }}
        ></Col>
        <Col 
          xs={{
            span: 11,
            offset: 1,
          }}
          lg={{
            span: 11,
            offset: 2,
          }}
        >
          <InfiniteScroll
        dataLength={notification.length}
        next={loadMoreData}
        hasMore={notification.length > 10}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={<Divider plain>Đã hết thông báo</Divider>}
        scrollableTarget="scrollableDiv"
      >
          <Layout

          style={{
            maxHeight: '800px',
          }}
          >
            <Header
              style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Title style={{ marginTop: '10px', marginLeft: '-33px' }} level={3}>
                Thông báo
              </Title>
              
              <EllipsisOutlined style={{ marginTop: '25px', marginRight: '-33px' }} />

            </Header>
            <Content>
              <NotificationListStaff notification={notification}/>

            </Content>
          </Layout>
          </InfiniteScroll>
        </Col>
        <Col
          xs={{
            span: 5,
            offset: 1,
          }}
          lg={{
            span: 3,
            offset: 2,
          }}
        >
        </Col>
      </Row>
    </Layout>
  )
}

export default NotificationList
