import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import styles from './otp.module.scss'
import ButtonCustom from 'components/Button/buttonCustom'
import CarouselCustom from 'components/carousel/carouselCustom'
import { ConfigProvider, notification } from 'antd'
import BgAuth from '../../../assets/img/bg-auth.png'
import { ACTION, ROUTES } from 'consts'
import { forgetPasswordSendOtp, forgetPasswordVerify, sendOtp, verify } from 'apis/auth'
import { useDispatch } from 'react-redux'

const OTP = () => {
  const location = useLocation()
  const [counter, setCounter] = useState()
  const history = useHistory()
  const action = location?.state?.action || 'REGISTER'
  const phone = location?.state?.phone || ''
  const [otp, setOtp] = useState('')
  const dispatch = useDispatch()
  const [errOtp, setErrOtp] = useState('')

  const _verifyAccount = async () => {
    try {
      if(otp?.length === 0) return setErrOtp("Vui lòng nhập mã OTP")
      const body = { phone: phone, otp_code: otp }
      let res
      if (action == 'FORGET_PASSWORD') {
        res = await forgetPasswordVerify(body)
      } else if (action == 'REGISTER') {
        res = await verify(body)
      }
      if (res.status === 200 || res.data.status) {
        setCounter(1)
        localStorage.setItem('counter', 1)
        dispatch({ type: ACTION.LOGIN, data: { accessToken: res.data.accessToken } })
        setErrOtp("")
        if (action == 'FORGET_PASSWORD') {
          window.location.href = ROUTES.CHANGE_PASSWORD
        } else if (action == 'REGISTER') {
          notification.success({ message: res.data.message })
          window.location.href = ROUTES.HOME_PAGE
        }
      } else {
        setErrOtp("Mã OTP không chính xác")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _resendOtp = async () => {
    try {
      setErrOtp("")
      if (counter > 0) return notification.error({ message: 'Vui lòng đợi trong giây lát.' })
      let res
      if (action == 'REGISTER') {
        res = await sendOtp(phone)
      } else if (action == 'FORGET_PASSWORD') {
        res = await forgetPasswordSendOtp(phone)
      }

      if (res.status === 200 && res.data.status) {
        notification.success({ message: 'Gửi lại otp thành công' })
        setCounter(300)
      } else {
        notification.error({ message: res.data.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _countdown = function () {
    if (counter > 0) {
    setTimeout(() => setCounter(counter - 1), 1000)
    localStorage.setItem('counter', counter)
    var minutes = Math.floor(counter / 60) || 0
    var seconds = counter % 60 || 0
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }}
    return (
      <div>
        <p className={styles['note-2']}>
          {errOtp?.length > 0 ? (
            <p style={{ color: '#F04438' }}>{errOtp}</p>
          ) : minutes !== undefined && seconds !== undefined ?  (
            <p>Mã OTP có hiệu lực trong vòng {minutes + ':' + seconds} giây.</p>
          ) : null}
          <p>
            <span onClick={_resendOtp}>Gửi lại mã OTP</span>
          </p>
        </p>
      </div>
    )
  }
  useEffect(() => {
    if (!location.state) history.push(ROUTES.LOGIN)
    if (localStorage.getItem('counter') <= 1) {
      setCounter(300)
    } else {
      setCounter(localStorage.getItem('counter'))
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
          colorTextPlaceholder: '#667085',
          colorPrimary: '#335BD3',
        },
      }}
    >
      <div className={styles['layout-otp']}>
        <div style={{ background: `url(${BgAuth})` }} className={styles['layout-carousel']}>
          <CarouselCustom />
        </div>
        <div className={styles['layout-otp-form']}>
          <div className={styles['form-otp-responsive']}>
            <p className={styles['title']}>Xác minh mã OTP</p>
            <p className={styles['note']}>
              Mã OTP đã được gửi về <span>{phone}</span>.
            </p>
            <p className={styles['count-length-otp']}>Mã 6 chữ số</p>
            <OtpInput
              value={otp}
              onChange={(e) => {
                setOtp(e)
                if(errOtp.length > 0) {
                  setErrOtp("")
                }
              }
              }
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => {
                if(props?.value?.length > 0) {
                  props.style['border'] = "1px solid #7592E1";
                } else {                  
                  props.style['border'] = "1px solid #d0d5dd";
                }
                return <input {...props} />
              }}
              containerStyle={`${styles['custom-container-otp']}`}
              inputStyle={`${styles[`${errOtp ? 'custom-otp-err' : `custom-otp`}`]}`}
              shouldAutoFocus={true}
            />
            {_countdown()}
            <div style={{width: '100%'}} onClick={_verifyAccount}>
              <ButtonCustom text={'Xác nhận'} />
            </div>
            <Link to="/login" style={{ marginTop: '32px' }}>
              <span className={styles['link']}>Trở về đăng nhập</span>
            </Link>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default OTP
