import { Button, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteMessageUser } from 'redux/actions/message'

const PopupDeleteMessage = ({visible, setVisible, data, msg}) => {
    const dispatch = useDispatch()
    const handleDeleteMessages = () => {
      if (!data) return
      dispatch(deleteMessageUser({ msg, data }))
      setVisible(false)
    }
  return (
    <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        style={{top: '30%'}}
        footer={null}
    >
        <h1>Bạn có muốn thu hồi tin nhắn này không?</h1>

        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button onClick={() => setVisible(false)} style={{width: '47%', marginRight: '6%'}}>Huỷ bỏ</Button>
            <Button onClick={handleDeleteMessages} type='primary' style={{width: '47%'}}>Thu hồi</Button>
        </div>

    </Modal>
  )
}

export default PopupDeleteMessage