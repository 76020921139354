import { List, Rate, Space } from 'antd'
import styled from 'styled-components'
import { ButtonCustom } from 'components/Button/Button'
import { SubscriberCard } from 'components/card/card'
import { formatCash } from 'utils'
import styles from './shopping-history.module.scss'
import { DialogVote } from './items/dialogVote'
import StatusOrder from './items/statusOrder'

const RateStyled = styled(Rate)`
  color: #fcac12;
  font-size: 14px;

  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
`

const SpacedStyled = styled(Space)`
  .ant-space-item:last-child {
    align-self: flex-end;
  }
`

const HistoryHeader = ({ order }) => (
  <SpacedStyled className={styles.history__header}>
    <div>
      <p className={styles.history__para}>
        Đơn hàng: <span className={styles.history__id}>#{order?.code}</span>
      </p>
      {order?.shipping_company_id !== null ? (
        <p className={styles.history__para}>
          Mã vận chuyển:{' '}
          <span className={styles['history__transcode']}>
            #{order?.shipping_info?.tracking_number}
          </span>
        </p>
      ) : null}
    </div>

    <StatusOrder status={order?.status}/>
  </SpacedStyled>
)

const HistoryMain = ({ orders }) => {
  const renderOrder = (order) => {
    const { sku, title, price, product_info } = order
    const rating = 4
    return (
      <Space key={sku} className={styles.history__order} align="center">
        <SubscriberCard
          classes="shopping-history"
          name={sku}
          description={title}
          avatar={{ image: `${product_info?.images[0]}` }}
        />
        <span className={styles.history__price}>{formatCash(price)}Đ</span>
        <RateStyled defaultValue={rating} allowHalf />
        <DialogVote />
      </Space>
    )
  }

  return (
    <div className={styles.history__main}>
      <List itemLayout="horizontal" dataSource={orders?.order_details} renderItem={renderOrder} />
    </div>
  )
}

function History({ orders }) {
  return (
    <div className={styles.history}>
      <HistoryHeader order={orders} />
      <HistoryMain orders={orders} />
    </div>
  )
}

export default History
