import { CloseOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import styles from '../shopping-history.module.scss'
import StatusCheck from './statusOrderLayout/status-check'
import StatusDelivery from './statusOrderLayout/status-delivery'
import StatusHeader from './statusOrderLayout/status-header'
import StatusTag from './statusOrderLayout/status-tab'

const StatusOrder = ({status}) => {
  const [open, setOpen] = useState(false)
  const openModal = () => {
    setOpen(!open)
  }
  const handelStatus = (status) => {
    switch (status) {
      case 200: return 'Hoàn thành'
      case 400: return 'Đơn trả'
      case 500: return 'Đã hủy'
      case 100: return 'Chờ lấy hàng'
      case 101: return 'Chờ xác nhận'
      case 102: return 'Đang giao'
      default : return 'Đang giao'
    }
  }
  return (
    <div>
      <span onClick={openModal} role="button" className={styles['history__btn-status']}>
        {handelStatus(status)}
      </span>
      <Modal style={{minWidth: '720px'}} bodyStyle={{padding: 0}} width={'70%'} title={null} centered visible={open} closable={false} footer={null}>
        <Row style={{padding: '2%'}} justify="space-between">
          <Col></Col>
          <Col>
            <CloseOutlined
              style={{ fontSize: 17, cursor: 'pointer', fontWeight: 1200 }}
              onClick={openModal}
            />
          </Col>
        </Row>
        <StatusHeader/>
        <StatusTag/>
        <StatusCheck/>
        <StatusDelivery/>
      </Modal>
    </div>
  )
}

export default StatusOrder
