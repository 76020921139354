/**
 *
 * Navigation
 *
 */

import React, { memo } from 'react'

import {
  NavigationContainer,
  NavigationBlock,
  NavigationItem,
  NavigationPlaceHolder,
} from './styled.components'

import FeedsIcon from './icons/feeds.png'
import AccountIcon from './icons/account.png'
import VoucherIcon from './icons/voucher.png'
import PointIcon from './icons/point.png'

function Navigation() {
  return (
    <>
      <NavigationContainer>
        <NavigationBlock>
          <NavigationItem>
            <NavigationPlaceHolder image={FeedsIcon} />
          </NavigationItem>

          <NavigationItem>
            <NavigationPlaceHolder image={PointIcon} isPrimary={true} />
          </NavigationItem>

          <NavigationItem>
            <NavigationPlaceHolder image={VoucherIcon} />
          </NavigationItem>

          <NavigationItem>
            <NavigationPlaceHolder image={AccountIcon} />
          </NavigationItem>
        </NavigationBlock>
      </NavigationContainer>
    </>
  )
}

export default memo(Navigation)
