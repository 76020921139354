import { CheckCircleOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './status.module.scss'

const StatusDelivery = () => {
  const data = [
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đơn hàng đã đặt', finish: true },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đang xác nhận đơn hàng', finish: true },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đã xác nhận đơn hàng', finish: true },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đang chờ lấy hàng', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đã Giao cho ĐVVC', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đang giao hàng', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đã giao hàng', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Hoàn thành', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đang trả hàng', finish: false },
    { time: '12:12 12-12-2022', status: '[Quốc tế] Đã trả hàng', finish: false },
  ]
  return (
    <div className={styles['delivery-status']}>
      <div className={styles['delivery-information']}>
        <p className={styles['p-0']}>Địa chỉ nhận hàng</p>
        <p className={styles['p-1']}>Cristiano Ronaldo</p>
        <p className={styles['p-2']}>0123456789</p>
        <p className={styles['p-3']}>
          244 Phan Châu Trinh, Phường Chính Gián, Quận Hải Châu, Thành Phố Đà Nẵng
        </p>
      </div>
      <div className={styles['delivery-shipping']}>
        {data.map((item, index) => (
          <div key={index} className={styles['status-shipping']}>
            <span
              style={{
                borderLeft: item.finish === true ? '1px solid #1E4DB7' : '1px solid #bbb',
                marginTop: index == 0 && '25px',
                marginBottom: (data.length - 1) == index && '25px',
              }}
              className={styles['span-line']}
            >
              .
            </span>
            <span
              style={{ backgroundColor: item.finish === true ? '#1E4DB7' : '#bbb' }}
              className={styles['span-dot']}
            ></span>
            <span className={styles['span-0']}>{item.time}</span>
            <span style={{color: index > 2 ? '#91919F' : '#1E4DB7'}} className={styles['span-1']}>{item.status}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatusDelivery
