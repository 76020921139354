import React from 'react'

import { isMobile } from 'react-device-detect'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ROUTES } from 'consts'
import { getNotificationStaff } from 'apis/nofitication'

import BaseLayout from 'components/layout'
import Authentication from 'components/authentication'

import Login from './auth-v2/login'
// import RegisterV2 from './auth-v2/register'
import Register from './auth-v2/register'
import OTP from './auth-v2/otp'
import ChangePassword from './auth-v2/change-password'
import ForgetPassword from './auth-v2/forget-password'
import HomePage from './home-page'
import NotFound from './not-found/404'
import RegisterBusiness from './business/register'
import Infor from '../components/layout/infor'
import SettingInfor from '../components/layout/setting-infor'
import { Preload } from '../preloads'
import NotificationList from 'components/layout/Notifications/notification-list'
import DetailBusiness from './business/detail'
import PointMobile from './point/mobile'
import Point from './point'
import DetailPoint from './point/detail-point'
import Voucher from './voucher'
import Cart from './cart'
import VerifyBusiness from './business/register/verify-business'
import BusinessFinished from './business/register/business-finished'
import About from './ekataInfo'
import { socketPos } from 'socket'
import { getNotificationsSystem } from 'redux/actions/notification'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import DetailPost from './forum/detailPosts/detailPost'
import Profile from './profile'
import Message from './message'
import Conversation from './message/conversation'
import  PrivacyPolicy  from './privacy-policy'
import Search from './data-search'
const DEFINE_ROUTER = [
  {
    path: ROUTES.HOME_PAGE,
    Component: () => (isMobile ? <PointMobile /> : <HomePage />),
    haveResponsiveDesign: true,
    title: 'Ekata',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.REGISTER_BUSINESS,
    Component: () => <RegisterBusiness />,
    title: 'Ekata',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.VERIFY_BUSINESS,
    Component: () => <VerifyBusiness />,
    title: 'Ekata',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.FINISHED_BUSINESS,
    Component: () => <BusinessFinished />,
    title: 'Ekata',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.INFOR,
    Component: () => <Infor />,
    title: 'Thông tin cá nhân ',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.INFO_USER,
    Component: () => <Profile />,
    title: 'Thông tin người dùng ',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_INFOR,
    Component: () => <SettingInfor />,
    title: 'Xác thực thông tin cá nhân',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.NOTIFICATION_LIST,
    Component: () => <NotificationList />,
    title: 'Danh sách thông báo',
    permissions: 'auth',
    exact: true,
  },
  {
    path: ROUTES.DETAIL_POST,
    Component: () => <DetailPost />,
    title: 'Chi tiết bài viết',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.POINT,
    Component: () => (isMobile ? <PointMobile /> : <Point />),
    haveResponsiveDesign: true,
    title: 'Điểm',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.DETAIL_POINT,
    Component: () => <DetailPoint />,
    title: 'Chi tiết điểm',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.VOUCHER,
    Component: () => <Voucher />,
    title: 'Chi tiết khuyến mãi',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CART,
    Component: () => <Cart />,
    title: 'Chi tiết giỏ hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.MESSAGE,
    Component: () => <Message />,
    title: 'Hộp thoại',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONVERSATION,
    Component: () => <Conversation />,
    title: 'Hộp thoại',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SEARCH,
    Component: () => <Search />,
    exact: true,
    title: 'Search Results |Ekata.vn ',
    permissions: [],
  },
  {
    path: ROUTES.DETAILBUSINESS,
    Component: () => <DetailBusiness />,
    title: 'Chi tiết cửa hàng',
    permissions: [],
    exact: true,
  },
]

const AUTH_ROUTER = [
  //auth
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: 'Ekata',
    permissions: [],
  },
  {
    path: ROUTES.REGISTER,
    Component: () => <Register />,
    exact: true,
    title: 'Ekata',
    permissions: [],
  },
  {
    path: ROUTES.OTP,
    Component: () => <OTP />,
    exact: true,
    title: 'Xác nhận tài khoản | Ekata.vn',
    permissions: [],
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    Component: () => <ChangePassword />,
    exact: true,
    title: 'Thay đổi mật khẩu | Ekata.vn ',
    permissions: [],
  },
  {
    path: ROUTES.FORGET_PASSWORD,
    Component: () => <ForgetPassword />,
    exact: true,
    title: 'Quên mật khẩu | Ekata.vn ',
    permissions: [],
  },
  // {
  //   path: ROUTES.ABOUT,
  //   Component: () => <About />,
  //   exact: true,
  //   title: 'About |Ekata.vn ',
  //   permissions: [],
  // },
  {
    path: ROUTES.PRIVACY,
    Component: () => <PrivacyPolicy />,
    exact: true,
    title: 'Chính sách bảo hành |Ekata.vn ',
    permissions: [],
  },
]

export default function Views() {
  Preload()

  return (
    <BrowserRouter>
      <Switch>
        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}
        {DEFINE_ROUTER.map(({ Component, haveResponsiveDesign, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              {haveResponsiveDesign && isMobile ? (
                <Component />
              ) : (
                  <BaseLayout
                    check={
                      (rest.path == ROUTES.DETAIL_POST ||
                        rest.path == ROUTES.INFO_USER ||
                        rest.path == ROUTES.CONVERSATION ||
                        rest.path == ROUTES.MESSAGE) &&
                      true
                    }
                  >
                    <Component />
                  </BaseLayout>
              )}
            </Authentication>
          </Route>
        ))}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
