import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'
import styles from '../styles/add-user-inbox.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import { SearchOutlined } from '@ant-design/icons'

export const ItemPersonSearch = ({ item }) => {
  const [checked, setChecked] = useState(false)
  if(!item) return <></>
  return (
    <div className={styles['item']} onClick={() => setChecked(!checked)}>
      <div className={styles['layout-item']}>
        <div className={styles['layout-item-child']}>
          <img
            src={item.avatar || AvatarDefault}
            alt=""
            style={{ width: '44px', height: '44px', marginRight: '7px', borderRadius: '50%' }}
          />
          <div className={styles['child-text']}>
            <span className={styles['span-1']}>{item.first_name + ' ' + item.last_name}</span>
            <span className={styles['span-2']}>{item.bio || 'Rạp xiếc trung ương'}</span>
          </div>
        </div>
        <label className={styles['container']}>
          <input checked={checked} type="checkbox" />
          <span className={styles['checkmark']}></span>
        </label>
      </div>
    </div>
  )
}

const AddUserInbox = ({ visible, setVisible, handleSearch, search, setSearch, searchUsers, handleAddUser, setSearchUsers }) => {
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false)
        setSearch('')
        setSearchUsers([])
      }}
      title={[<div>Tin nhắn mới</div>]}
      footer={[
        <div>
          <Button onClick={() => handleAddUser()}>Thêm</Button>
        </div>,
      ]}
      width={400}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span>Tới: </span>
        <input
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Tìm kiếm..."
          style={{ borderStyle: 'hidden', outline: 'none', width: '80%', marginLeft: '7px', marginRight: '7px' }}
        />
        <Button onClick={handleSearch} size='small'><SearchOutlined /> Tìm kiếm</Button>
      </div>
      <span>Gợi ý:</span>
      {searchUsers.map((item, index) => (
        <ItemPersonSearch key={index} item={item} />
      ))}
    </Modal>
  )
}

export default AddUserInbox
