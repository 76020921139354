import Comment from './comment'
import Feedbacks from './feedbacks'
import styles from './comment-feedbacks.module.scss'
import { Divider } from 'antd'

function CommentAndFeedBacks({ classes, item, showComment }) {
  return (
    <div className={`${styles.wrapper} ${styles[`wrapper-${classes}`]}`}>
      <Comment item={item} showComment={showComment} />
      {showComment && (
        <>
          <Divider style={{ margin: 0 }} />
          <Feedbacks item={item} showComment={showComment} />
        </>
      )}
    </div>
  )
}

export default CommentAndFeedBacks
