import { getProductPosts, likePostFeed, unLikePostFeed, pinPostFeed, unPinPostFeed } from 'apis/feed'
import { createCommentPost, deleteCommentPost, likeCommentPost, unLikeCommentPost, updateCommentPost } from "apis/comment"
import { DeleteData, EditData } from "./global"

export const getPostsSystem = () => async (dispatch) => {
    try {
      const res = await getProductPosts()
      dispatch({ type: 'SET_POSTS_PRODUCT', payload: res.data.data });
    } catch (error) {
      console.log(error)
    }
  };
export const likePost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: [...post.likes, user.user_id]}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
  
    try {
      await likePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  
export const unLikePost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: post.likes.filter(id => id !== user.user_id )}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
    try {
      await unLikePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  export const pinPost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, pins: [...post?.pins, user.user_id]}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
    try {
      await pinPostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  
export const unPinPost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, pins: post?.pins?.filter(id => id !== user.user_id )}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
    try {
      await unPinPostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };

  export const createComment = ({post, newComment, user}) => async (dispatch) => {
    const newPost = {...post, comments: [...post.comments, newComment]}
    
    dispatch({ type: 'UPDATE_POST', payload: newPost })

    try {
        const data = {...newComment, post_id: post.post_id, post_user_id: post.creator_id}
        const res = await createCommentPost(data)
        const newData = {...res.data.data, user: user, tag: newComment.tag}
        const newPost = {...post, comments: [...post.comments, newData]}
        dispatch({ type: 'UPDATE_POST', payload: newPost })

    } catch (error) {
        console.log(error)
    }
}

export const updateComment = ({comment, post, content}) => async (dispatch) => {
    const newComments = EditData(post.comments, comment.comment_id, {...comment, comments: content})
    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST', payload: newPost })
    try {
        await updateCommentPost(comment.comment_id, { content })
    } catch (error) {
        console.log(error)
    }
}

export const likeComment = ({comment, post, user}) => async (dispatch) => {
    const newComment = {...comment, likes: [...comment.likes, user]}

    const newComments = EditData(post.comments, comment.comment_id, newComment)

    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST', payload: newPost })

    try {
        await likeCommentPost(comment.comment_id)
    } catch (error) {
        console.log(error)
    }
}

export const unLikeComment = ({comment, post, user}) => async (dispatch) => {

    const newComment = {...comment, likes: DeleteData(comment.likes, user.user_id)}

    const newComments = EditData(post.comments, comment.comment_id, newComment)

    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST', payload: newPost })

    try {
        await unLikeCommentPost(comment.comment_id)
    } catch (error) {
        console.log(error)
    }
}

export const deleteComment = ({post, comment, auth}) => async (dispatch) => {
    const deleteArr = [...post.comments.filter(cm => cm.comment_reply_id === comment.comment_id), comment]
    
    const newPost = {
        ...post,
        comments: post.comments.filter(cm => !deleteArr.find(da => cm.comment_id === da.comment_id))
    }

    dispatch({ type: 'UPDATE_POST', payload: newPost })

    try {
       deleteArr.forEach(item => {
            deleteCommentPost(item.comment_id)
       })
    } catch (error) {
        console.log(error)
    }

}