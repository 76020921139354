import React from 'react'
import styles from './status.module.scss'

const StatusHeader = () => {
  return (
    <div className={styles['header-status']}>
      <div>
        <span className={styles['span-0']}>Trạng thái đơn hàng</span>
      </div>
      <div>
        <span className={styles['span-1']}>
          Trạng thái đơn hàng
          <span className={styles['span-1-0']}>Đơn hàng đang giao đến bạn</span>
        </span>
      </div>
    </div>
  )
}

export default StatusHeader
