import { EditData } from 'redux/actions/global';

const detailPostUser = (state = [], action) => {
    switch (action.type){
        case 'GET_DETAIL_POST':
            return [...state, action.payload]
        case 'UPDATE_POST_USER':
            return EditData(state, action.payload.post_id, action.payload)
        default:
            return state;
    }
}


export default detailPostUser