const initialState = {}
const voucher = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_VOUCHER': {
            return {
                voucher: action.data,
                count: action.count,
                my_voucher: state.my_voucher,
                my_voucher_count: state.my_voucher_count
            }
        }
        case 'SET_MY_VOUCHER': {
            return {
                voucher: state.voucher,
                count: state.count,
                my_voucher: action.data,
                my_voucher_count: action.count
            }
        }
        case 'UPDATE_DELETE_VOUCHER': {
            return {
                voucher: state.voucher.map(item => {
                    if(item.voucher_id === action.data.voucher_id) {
                        item.user_vouchers = item.user_vouchers.filter(e => e !== action.user_id)
                    }
                    return item
                }),
                count: state.count,
                my_voucher: state.my_voucher.filter(item => item.voucher_id !== action.data.voucher_id),
                my_voucher_count: state.count - 1
            }
        }
        case 'UPDATE_SAVE_VOUCHER': {
            return {
                voucher: state.voucher.map(item => {
                    if(item.voucher_id === action.data.voucher_id) {
                        item.user_vouchers.push(action.user_id)
                    }
                    return item
                }),
                count: state.count,
                my_voucher: [...state.my_voucher, action.data],
                my_voucher_count: state.my_voucher_count + 1,
            }
        }
        default:
            return state
    }
};
export default voucher
