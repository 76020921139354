/* eslint-disable jsx-a11y/iframe-has-title */
import { useCallback, useEffect, useState } from 'react'
import { Avatar, Image, Space, Row } from 'antd'
import { EmptyList } from 'components/card/card'
import avatarRound from 'assets/img/avatar-round.png'
import styles from './feedbacks.module.scss'
import { imageShow, videoShow } from '../comment/item'
import Comment from '../comment'
import { Link } from 'react-router-dom'
import moment from 'moment'

function Feedback({ comment, item }) {
  const [onReply, setOnReply] = useState(false)

  const handleReply = () => {
    if (onReply) return setOnReply(false)
    setOnReply({ ...comment, comment_id: comment.comment_id })
  }
  return (
    <div>
      <div className={styles['feedback']}>
        <div style={{ width: '65px' }}>
          <Row justify="center">
            <Avatar
              className={styles['feedback__avatar']}
              size={45}
              src={comment?.user?.avatar || avatarRound}
            />
          </Row>
          <Row justify="center">
            <small style={{ fontWeight: 'bold', color: 'black', fontSize: 10 }}>
              {!comment?.tag_business_id && comment?.business_id ? '(Cửa hàng)' : ''}
            </small>
          </Row>
        </div>
        <div className={styles['feedback__info']}>
          <div style={{ background: '#f0f0f0', borderRadius: '15px', padding: 5, paddingLeft: '12px', paddingRight: '12px' }}>
            <span className={styles['feedback__name']}>
              {!comment?.tag_business_id && comment?.business_id
                ? item.business.business_name
                : comment?.user?.first_name + ' ' + comment?.user?.last_name}
            </span>
            <p className={styles['feedback__description']}>
              <Link>
                <strong>
                {comment?.tag_business_id
                ? item.business.business_name
                : (comment?.tag?.first_name || '') + ' ' + (comment?.tag?.last_name || '')}
                </strong>
              </Link>{' '}
              {comment.comments}
            </p>
          </div>

          {comment?.image?.length > 0 && (
            <div style={{ marginTop: '5px' }}>
              {comment?.type_image == 'image'
                ? imageShow(comment.image, false)
                : videoShow(comment.image, false)}
            </div>
          )}

          <div className={styles['feedback__cta']}>
            <span className={styles['feedback__btn']}>Thích</span>
            <span className={styles['feedback__btn']} onClick={handleReply}>
              {onReply ? 'Huỷ bỏ' : 'Phản hồi'}
            </span>
            <small
              style={{ cursor: 'unset', fontSize: 11, marginLeft: '16px', lineHeight: '15px' }}
            >
              {moment(comment?.create_date).fromNow()}
            </small>

            {onReply && (
              <Comment
                item={item}
                onReply={onReply}
                setOnReply={setOnReply}
                comment_reply_id={comment.comment_reply_id}
              >
                <Link to={`/profile/${onReply.user.user_id}`}>
                {onReply?.business_id
                ? item.business.business_name
                : onReply?.user?.first_name + ' ' + onReply?.user?.last_name}
                </Link>
              </Comment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function Feedbacks({ item }) {
  // const [showComments, setShowComments] = useState([])
  const [commentReply, setCommentReply] = useState(
    item?.comments?.filter((item) => item.comment_reply_id !== null)
  )
  // const [countComment, setCountComment] = useState(2)
  // const [newComment, setNewComment] = useState([])

  useEffect(() => {
    if (!item?.comments) return
    let comment_reply = [...item.comments]
    comment_reply = comment_reply.filter((item) => item.comment_reply_id !== null)
    setCommentReply(comment_reply)
    // setNewComment(item.comments)
  }, [item?.comments])

  const displayFeedbacks = useCallback(() => {
    return item?.comments
      ?.filter((item) => item.comment_reply_id == null)
      .map((comment, index) => (
        <div key={index} style={{ marginTop: '7px' }}>
          {' '}
          <Feedback comment={comment} item={item}/>
          {commentReply.length > 0 &&
            commentReply.map(
              (commentRep, index2) =>
                commentRep.comment_reply_id === comment.comment_id && (
                  <div key={index2} style={{ marginLeft: '70px' }}>
                    <Feedback comment={commentRep} item={item} />
                  </div>
                )
            )}
        </div>
      ))
  }, [item?.comments])

  return <div className={styles.feedbacks}>{item?.comments?.length ? displayFeedbacks() : ''}</div>
}

export default Feedbacks
