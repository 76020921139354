import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Rate, notification } from 'antd'
import styles from '../feeds.module.scss'
import { CheckCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { ButtonCustom } from 'components/Button/Button'
import { formatCash } from 'utils'
import { InteractionIcons } from '../items/interactionIcons'
import { addToCart, getCartBusiness } from 'apis/cart'
import CartAuth from 'components/Profile/cartAuth'
import { ModalDetailProduct } from 'components/Product/items/detailProductCart'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export const FooterPost = ({ item, setCarts, carts, showComment, setShowComment }) => {
  const [cartCheck, setCartCheck] = useState(false)
  const [loadingCart, setLoadingCart] = useState(false)

  const _getCartBusiness = async (id) => {
    try {
      setLoadingCart(true)
      let res = await getCartBusiness(id)
      if (res.status === 200) {
        if (!res.data.data) return
        setCarts(res.data.data)
      }
      setLoadingCart(false)
    } catch (error) {
      console.log(error)
    }
  }
  const handleAddToCart = async (product) => {
    try {
      if(!product?.business_id) return
      let res = await addToCart(product)
      if (res.status === 200) {
        notification.success({ key: 'addtocart', message: 'Thêm vào giỏ hàng thành công' })
        _getCartBusiness(product.business_id)
      } else {
        notification.error({ key: 'addtocart', message: res.data.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   _getCartBusiness()
  // }, [])
  // useEffect(() => {
  //   if(item) {
  //     setCartCheck(carts.some(e => e.product_id == item.product_id))
  //   }
  // },[item, carts])

  return (
    <div style={{ paddingTop: '0px', paddingBottom: '3px' }} className={styles['container-item']}>
      <Row className={styles['container-content']}>
        <Col style={{ display: 'inline-block', width: '70%' }}>
          <h1 className={styles['text-price']}>
            {' '}
            {item.products?.variants?.length > 1
              ? Math.min(...item.products?.variants?.map((item) => item.price)) !==
                Math.max(...item.products?.variants?.map((item) => item.price))
                ? `${formatCash(
                    Math.min(...item.products?.variants?.map((item) => item.price))
                  )} - ${formatCash(
                    Math.max(...item.products?.variants?.map((item) => item.price))
                  )}`
                : `${formatCash(item.products?.variants[0].price)}`
              : `${formatCash(item.products?.variants[0].price)}`}{' '}
            VND
          </h1>

          <InteractionIcons item={item} showComment={showComment} setShowComment={setShowComment} />

          <Row>
            <p className={styles['text-like']}>
              <a>{item.likes.length} người</a> đã thích sản phẩm này
            </p>
          </Row>
        </Col>
        <Col style={{ width: '152px' }}>
          <ButtonCustom classes="btn__blue-fill" text="Mua ngay" />
          {/* {cartCheck === true ? (
            <ButtonCustom
              classes="btn__blue-sky-fill"
              style={{ marginTop: '8px' }}
              icon={() => <CheckCircleOutlined className={styles['icon-btn']} />}
              text={'Đã được thêm'}
              functionOnClick={null}
            />
          ) : ( */}
            <ModalDetailProduct
              handleAddToCart={handleAddToCart}
              product={{
                ...item?.products,
                ...{
                  image: (item?.images && item.images[0]) || '',
                  business: item?.business_id,
                  product_id: item?.product_id,
                  priceOld: '450.000đ',
                  amount: '3.1k',
                  deal: 'Mua kèm Deal sốc',
                  discount: '30%',
                },
              }}
              check={true}
            />
          {/* )} */}
        </Col>
      </Row>
    </div>
  )
}
