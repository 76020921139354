import {useDispatch} from 'react-redux'
import { getAllVoucher, getMyVoucher } from 'apis/voucher';
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export async function LoadVoucher () {
    const dispatch = useDispatch();

    try {
        const accessToken = Cookies.get('accessToken');
        const payload = accessToken && jwt_decode(accessToken)
        if (!payload) return;
        const res = await getAllVoucher({page: 1, page_size: 20});
        dispatch({
            type: 'SET_VOUCHER',
            data: res.data.data,
            count: res.data.count
        })
        const res2 = await getMyVoucher({page: 1, page_size: 20});
        dispatch({
            type: 'SET_MY_VOUCHER',
            data: res2.data.data,
            count: res2.data.count
        })
    } catch (error) {
        console.log(error)
    }
}

