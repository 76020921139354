import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../style.module.scss'

import { ButtonCustom } from 'components/Button/Button'

import defaultLogoUser from 'assets/img/avatar-round.png'
import Tooltip from './Tooltip'
export default function ListPeople({ people }) {
  return (
    <div className={styles['people']}>
      <div className={styles['people__header']}>Người dùng</div>
      {people &&
        people.map((item, index) => (
          <div className={styles['item']}>
            <Link
              style={{ display: 'flex', alignItems: 'center' }}
              to={`/info-user/${item.user_id}`}
            >
              <img
                src={item.avatar ? item.avatar : defaultLogoUser}
                className={styles['item__logo']}
                alt=""
              />
            </Link>

            <div className={styles['item__text']}>
              <div className={styles['item__title']}>
                {item.first_name} {item.last_name}{' '}
                <Tooltip
                  id={item.user_id}
                  avatar={item.avatar ? item.avatar : defaultLogoUser}
                  first_name={item.first_name}
                  last_name={item.last_name}
                  name={`${item.first_name} ${item.last_name}`}
                  prefix=''
                  people={1}
                />
              </div>
              <div className={styles['people__bio']}> {item.address}</div>
            </div>
            <ButtonCustom classes="btn__blue-sky" text="+ Theo dõi" />
          </div>
        ))}
    </div>
  )
}
