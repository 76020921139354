import { CommentOutlined } from '@ant-design/icons'
import React from 'react'
import LeftSide from './left-side/leftSide'

const Message = () => {
  return (
    <div style={{minWidth: '1000px', padding: '50px', height: '100%', display: 'flex', flexDirection: 'row'}}>
      <LeftSide/>
      <div style={{width: 'calc(100% - 350px)', background: 'white', borderRadius: '0px 4px 4px 0px', border: '1px solid #DBDBDB', borderLeft: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <CommentOutlined style={{fontSize: '30px'}}/>
        <span>Gửi tin nhắn cho bạn bè của bạn</span>
      </div>
    </div>
  )
}

export default Message