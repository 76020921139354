import { follow, getInfoUser, getPostsUser, unFollow } from "apis/profile";
import { DeleteData } from "redux/reducers/profileUser";

export const getProfileUsers = ({id}) => async (dispatch) => {
    dispatch({type: 'GET_ID_USER', payload: id})

    try {
        const users = await getInfoUser(id);
        const posts = await getPostsUser(id);

        dispatch({
            type: 'GET_INFO_USER',
            payload: users.data
        })

        dispatch({
            type: 'GET_POSTS_USER',
            payload: posts.data.data
        })

    } catch (error) {
        console.log(error)
    }
}

export const followUser = ({users, user, auth, socket, notifi}) => async (dispatch) => {
    let newUser;
    
    if(users.every(item => item.user_id != user.user_id)){
        newUser = {...user, following: [...user.following, auth]}
    }else{
        users.forEach(item => {
            if(item.user_id == user.user_id){
                newUser = {...item, following: [...item.following, auth]}
            }
        })
    }

    if(notifi) {
        notifi.status = 1
    }

    dispatch({ type: 'FOLLOW', payload: newUser })

    dispatch({
        type: "SET_USERINFO", 
        data: {...auth, followers: [...auth.followers, newUser]}
    })

    dispatch({
        type: "UPDATE_NOTIFICATION", 
        data: notifi
    })


    try {
        const res = await follow(user.user_id)
        socket.emit('follow', {new_following: res.data.data, new_follower: res.data.data2})

    } catch (err) {
       console.log(err)
    }
}

export const unFollowUser = ({users, user, auth, socket}) => async (dispatch) => {

    let newUser;

    if(users.every(item => item.user_id != user.user_id)){
        newUser = {...user, followers: DeleteData(user.followers, auth.user_id)}
    }else{
        users.forEach(item => {
            if(item.user_id == user.user_id){
                newUser = {...item, followers: DeleteData(item.followers, auth.user_id)}
            }
        })
    }

    dispatch({ type: 'UNFOLLOW', payload: newUser })

    dispatch({
        type: 'SET_USERINFO', 
        data: {
                ...auth, 
                following: DeleteData(auth.following, newUser.user_id) 
            }
    })

    try {
        const res = await unFollow(user.user_id)
        socket.emit('unFollow', {new_following: res.data.data, new_follower: res.data.data2})
    } catch (err) {
        console.log(err)
    }
}