import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducers from './reducers'
import thunk from 'redux-thunk'

const enhancers = []
const middleware = [thunk]
const persistConfig = {
  keyPrefix: '',
  key: 'invoice',
  storage,
  whitelist: ['invoice'],
}

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension())
}

const composeEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const pReducer = persistReducer(persistConfig, rootReducers)

export const store = createStore(pReducer, composeEnhancers)
export const persistor = persistStore(store)
