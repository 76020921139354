import React from "react";
import styles from "../styles/footerForm.module.scss";
import { Link } from "react-router-dom";

const FooterForm = () => {
  return (
    <div className={styles["footer-form"]}>
      <p className={styles["p-1"]}>Bạn chưa có tài khoản?</p>
      <Link to={"/register"}>
        <p className={styles["p-2"]}>Đăng kí tài khoản mới.</p>
      </Link>
    </div>
  );
};

export default FooterForm;
