import { HeartOutlined, MessageOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/profile.module.scss'

export const Post = ({ posts }) => {
  if (posts.length === 0) return <h2 className="text-center text-danger">No Post</h2>

  return (
    <div className={styles["post_thumb"]}>
      {posts.map((post) => (
        <Link key={post.post_id} to={`/detail-post/${post.post_id}`}>
          <div className={styles["post_thumb_display"]}>
            {post.images[0].url.match(/video/i) ? (
              <video controls src={post.images[0].url} alt={post.images[0].url} />
            ) : (
              <img src={post.images[0].url} alt={post.images[0].url} />
            )}

            <div className={styles["post_thumb_menu"]}>
              <i className={styles["far fa-heart"]}><HeartOutlined/> {post.likes.length}</i>
              <i className={styles["far fa-comment"]}><MessageOutlined/> {post.comments.length}</i>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

const Posts = ({ user, id, dispatch, profileUser }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    let new_data = []

    profileUser.posts.forEach((data) => {
      if (data.creator_id == id) {
        new_data.push(data)
      }
    })
    setPosts(new_data)

  }, [profileUser.posts, id])

  return (
    <div>
      <Post posts={posts}  />
    </div>
  )
}

export default Posts
