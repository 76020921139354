import React from 'react'
import styles from './privacy.module.scss'
export default function index() {
  return (
    <div className={styles['container']}>
      <header className={styles['header']}>
        <a href="" className={styles['logo']}>
          <img
            width={60}
            height={60}
            src="https://support.ekata.vn/wp-content/uploads/2023/06/Logo-app-1.png"
            alt="logo"
          />
        </a>
      </header>
      <div className={styles['title']}>
        <div className={styles['name']}>Chính Sách Bảo Mật</div>
      </div>
      <div className={styles['body']}>
        <div className={styles['strong']}>
          Chào mừng bạn đến với chính sách bảo mật của phần mềm quản lý bán hàng và chăm sóc khách
          hàng Ekata.vn. Chính sách này nhằm mô tả cách chúng tôi thu thập, sử dụng, bảo vệ và chia
          sẻ thông tin cá nhân của người dùng khi truy cập và sử dụng website Ekata.vn và ứng dụng
          Ekata. Trong phạm vi pháp luật cho phép, việc tiếp tục sử dụng các Dịch Vụ hoặc nền tảng
          mà Ekata cung cấp, bao gồm giao dịch của bạn, được xem là bạn đã công nhận và đồng ý với
          các điều khoản trong Chính Sách Bảo Mật này. Vì vậy, vui lòng đọc kỹ chính sách này để
          hiểu rõ về cách chúng tôi xử lý thông tin cá nhân của bạn.
        </div>
        <h2 className={styles['item']}>1. Thu thập thông tin cá nhân</h2>

        <p className={styles['content']}>
          Chúng tôi thu thập thông tin cá nhân từ bạn khi bạn đăng ký tài khoản, thực hiện giao dịch
          mua bán, điền vào các biểu mẫu trên trang web, tương tác với chúng tôi qua email hoặc trò
          chuyện trực tiếp. Thông tin cá nhân mà chúng tôi thu thập có thể bao gồm:
        </p>
        <ul className={styles['content']}>
          <li>Tên đăng nhập và mật khẩu</li>
          <li>Tên và địa chỉ email</li>
          <li>Ngày tháng năm sinh</li>
          <li>Địa chỉ giao hàng</li>
          <li>Số điện thoại liên lạc</li>
          <li>Thông tin về sở thích và lợi ích cá nhân</li>
        </ul>

        <h2 className={styles['item']}>2. Quyền sở hữu thông tin khách hàng</h2>
        <p className={styles['content']}>
          Công ty trách nhiệm hữu hạn Ekata và các chủ cửa hàng trên Ekata đều hiểu và nắm rõ việc
          thông tin cá nhân khách hàng là thuộc sở hữu của khách hàng, Công ty trách nhiệm hữu hạn
          Ekata và các chủ cửa hàng trên Ekata chỉ là đơn vị lưu giữ và quản lý thông tin cho khách
          hàng. Khách hàng sử dụng dịch vụ trên Ekata có toàn quyền quản lý thông tin cá nhân của
          mình trên Ekata bao gồm: Quyền truy cập, Quyền chỉnh sửa, Quyền xóa, Quyền giới hạn.
        </p>

        <h2 className={styles['item']}>3. Mục đích sử dụng thông tin cá nhân</h2>
        <p className={styles['content']}>
          Chúng tôi sử dụng thông tin cá nhân của bạn cho các mục đích sau:
        </p>
        <ul className={styles['content']}>
          <li>Xác nhận danh tính và đảm bảo an toàn tài khoản của bạn.</li>
          <li>Xử lý và giao dịch mua bán, bao gồm thanh toán, đặt hàng và vận chuyển sản phẩm.</li>
          <li>
            Cung cấp thông tin, dịch vụ và hỗ trợ liên quan đến sản phẩm và dịch vụ của chúng tôi.
          </li>
          <li>
            Tùy chỉnh trải nghiệm người dùng, cung cấp nội dung và quảng cáo cá nhân hóa dựa trên sở
            thích và lịch sử tương tác của bạn.
          </li>
          <li>
            Cải thiện dịch vụ, phân tích dữ liệu và nghiên cứu thị trường để cung cấp trải nghiệm
            người dùng tốt hơn.
          </li>
          <li>Tuân thủ các yêu cầu pháp luật, quy định và nghĩa vụ pháp lý.</li>
        </ul>

        <h2 className={styles['item']}>4. Chia sẻ thông tin cá nhân</h2>
        <p className={styles['content']}>
          Chúng tôi không chia sẻ thông tin cá nhân của bạn với bất kỳ bên thứ ba nào mà không có sự
          đồng ý của bạn, trừ khi cần thiết để thực hiện các dịch vụ và hoạt động liên quan đến
          trang web Ekata.vn. Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các đối tác,
          nhà cung cấp dịch vụ và bên thứ ba khác trong các trường hợp sau:
        </p>
        <ul className={styles['content']}>
          <li>Để xử lý thanh toán, đặt hàng và vận chuyển sản phẩm cho bạn.</li>
          <li>Để cung cấp hỗ trợ khách hàng và trả lời yêu cầu của bạn.</li>
          <li>Để cung cấp quảng cáo cá nhân hóa và nội dung tương tự cho bạn.</li>
          <li>Để tuân thủ các yêu cầu pháp luật, quy định và nghĩa vụ pháp lý.</li>
        </ul>
        <p className={styles['content']}>
          Chúng tôi cam kết rằng chúng tôi sẽ áp dụng các biện pháp bảo mật và ký kết các thỏa thuận
          để đảm bảo rằng thông tin cá nhân của bạn được bảo vệ an toàn và tuân thủ quyền riêng tư.
        </p>
        <h2 className={styles['item']}>5. Bảo mật thông tin cá nhân</h2>
        <p className={styles['content']}>
          Chúng tôi áp dụng các biện pháp bảo mật vật lý, kỹ thuật và quản lý để bảo vệ thông tin cá
          nhân của bạn khỏi truy cập trái phép, mất mát hoặc hủy hoại. Chúng tôi sử dụng mã hóa dữ
          liệu, hạn chế quyền truy cập và thiết lập các quy trình nội bộ để bảo vệ thông tin cá nhân
          của bạn.
        </p>

        <h2 className={styles['item']}>6. Quyền riêng tư của người dùng</h2>
        <p className={styles['content']}>
          Bạn có các quyền riêng tư sau đối với thông tin cá nhân của mình:
        </p>
        <ul className={styles['content']}>
          <li>
            Quyền truy cập: Bạn có quyền truy cập thông tin cá nhân mà chúng tôi đã thu thập về bạn
            và yêu cầu sao chép hoặc xem nội dung đó.
          </li>
          <li>
            Quyền chỉnh sửa: Bạn có quyền yêu cầu chúng tôi chỉnh sửa hoặc cập nhật thông tin cá
            nhân của bạn nếu nó không chính xác hoặc không đầy đủ.
          </li>
          <li>
            Quyền xóa: Bạn có quyền yêu cầu chúng tôi xóa thông tin cá nhân của bạn khỏi hệ thống
            của chúng tôi.
          </li>
          <li>
            Quyền giới hạn: Bạn có quyền yêu cầu chúng tôi giới hạn việc xử lý thông tin cá nhân của
            bạn trong một số trường hợp.
          </li>
          <li>
            Quyền rút lại đồng ý: Bạn có quyền rút lại đồng ý của mình về việc sử dụng thông tin cá
            nhân theo quy định trong chính sách bảo mật này.
          </li>
        </ul>
        <p className={styles['content']}>
          Để thực hiện các quyền riêng tư của mình, bạn có thể liên hệ với chúng tôi qua thông tin
          liên hệ được cung cấp dưới đây.
        </p>

        <h2 className={styles['item']}>7. Sử dụng Cookie và công nghệ tương tự</h2>
        <p className={styles['content']}>
          Trang web Ekata.vn sử dụng cookie và công nghệ tương tự để thu thập thông tin và cung cấp
          trải nghiệm tốt hơn cho người dùng. Cookie là các tệp tin nhỏ được lưu trữ trên thiết bị
          của bạn khi bạn truy cập vào trang web của chúng tôi. Chúng tôi sử dụng cookie để nhớ
          thông tin đăng nhập, tùy chỉnh nội dung và quảng cáo, phân tích dữ liệu và nghiên cứu thị
          trường. Bạn có thể kiểm soát việc sử dụng cookie bằng cách thay đổi cài đặt trình duyệt
          của mình. Tuy nhiên, việc từ chối sử dụng cookie có thể ảnh hưởng đến trải nghiệm của bạn
          trên trang web Ekata.vn.
        </p>
        <h2 className={styles['item']}>8. Thay đổi chính sách bảo mật</h2>
        <p className={styles['content']}>
          Chính sách bảo mật này có thể được cập nhật theo thời gian mà không cần thông báo trước.
          Mọi thay đổi sẽ được đăng trên trang web và áp dụng kể từ ngày đăng. Việc tiếp tục sử dụng
          trang web sau khi có bất kỳ thay đổi nào đồng nghĩa với việc bạn chấp nhận và đồng ý với
          chính sách bảo mật mới.
        </p>
        <h2 className={styles['item']}>9. Liên hệ</h2>
        <p className={styles['content']}>
          Nếu bạn có bất kỳ câu hỏi, ý kiến hoặc yêu cầu liên quan đến chính sách bảo mật, vui lòng
          liên hệ với chúng tôi qua thông tin sau:
        </p>
        <ul className={styles['content']}>
          <li>Công ty tổ chức: Công ty trách nhiệm hữu hạn Ekata</li>
          <li>Địa chỉ: 244 Phan Châu Trinh, phường Nam Dương, quận Hải Châu, thành phố Đà Nẵng</li>
          <li>
            Địa chỉ email: <a href="https://ekata.vietnam@gmail.com">ekata.vietnam@gmail.com</a>{' '}
          </li>
          <li>
            Địa chỉ trang web: <a href="https://ekata.vn">www.ekata.vn</a>
          </li>
          <li>Số điện thoại: 0967487179</li>
        </ul>
      </div>
    </div>
  )
}
