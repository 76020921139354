import { useEffect, useState } from 'react'
import {useLocation } from 'react-router-dom'
import styled from 'styled-components'
import styles from './detail_business.module.scss'

import { Col, notification, Row, Tabs } from 'antd'
import { ShopOutlined, DiffOutlined } from '@ant-design/icons'
//api
import { detailBusiness } from 'apis/business'
import Tab from 'components/newfeed/tab'
import NewFeedsLayout from 'components/layout/NewFeeds/NewFeeds.layout'
import NewFeeds from 'components/newfeed/feeds'
import Products from './products'
import Profile from 'components/Profile/Profile'

// import AvatarProfileImage from 'assets/img/avatar-profile.png'
import BgProfileImage from 'assets/img/bg-profile.png'
import { useSelector } from 'react-redux'
import { addToCart, getCartBusiness } from 'apis/cart'

export default function Detail_business() {
  const location  = useLocation()
  const id = location.state
  const { TabPane } = Tabs
  const [business, setBusiness] = useState()
  const [productList, setProductList] = useState([])
  const [loadingCart, setLoadingCart] = useState(false)
  const [carts, setCarts] = useState([])
  const feed = useSelector((state) => state.feed)

  const tabPaneData = [
    {
      key: 'NewFeeds',
      classes: styles.tabpane__newfeeds,
      content: NewFeeds,
      tab: () => <Tab classes={styles.tabpane} text="Bản tin" icon={DiffOutlined} />,
    },
    {
      key: 'Products',
      classes: styles.tabpane__products,
      content: Products,
      tab: () => <Tab classes={styles.tabpane} text="Sản phẩm" icon={ShopOutlined} />,
    },
  ]


  const _getDetailBusinesses = async (id) => {
    try {
      const res = await detailBusiness(id.business_id)
      if (res.status === 200) {
        let data = res.data.data
        if(!data) return
        const profileData = {
          avatar: data.business_logo || '',
          bgAvatar: data.business_cover_image || BgProfileImage,
          name: data.business_name || '',
          status: data.business_website || '',
          follower: '100M',
          following: 6,
          quantity: 250,
          feedbackRate: '98%',
          feedbackTime: 'Vài phút',
          rate: '2.5/5',
          finishedRate: '100%',
          description: data.business_description || '',
          phone: data.business_phone,
        }
       setBusiness(profileData) 
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleAddToCart = async (product) => {
    try {
      await addToCart(product)
      notification.success({ key: 'addtocart', message: 'Thêm vào giỏ hàng thành công' })
      _getCartBusiness()
    } catch (error) {
      console.log(error)
    }
  }

  const _getCartBusiness = async () => {
    try {
      setLoadingCart(true)
      let res = await getCartBusiness(id)
      if (res.status === 200) {
        setCarts(res.data.data)
      }
      setLoadingCart(false)
    } catch (error) {
      console.log(error)
      setLoadingCart(false)
    }
  }
  useEffect(() => {
    _getCartBusiness()
    _getDetailBusinesses(id)
  }, [id])

  useEffect(() => {
    let newProduct = [...feed]
    newProduct = newProduct
      .filter((item) => item.business == id)
      .map((item) => ({
        ...item,
        image: (item?.images && item.images[0]) || '',
        priceOld: '450.000đ',
        amount: '3.1k',
        deal: 'Mua kèm Deal sốc',
        discount: '30%',
      }))
    setProductList(newProduct)
  }, [id, feed])
  return (
    <Row gutter={[32, 32]}>
      {/* Profile-aside */}
      <Col span={7}>
        <Profile
          profile={business}
          setLoadingCart={setLoadingCart}
          loadingCart={loadingCart}
          setCarts={setCarts}
          carts={carts}
        />
      </Col>
      <Col span={16}>
        {/*Content */}
        <NewFeedsLayout>
          <Tabs defaultActiveKey={1} className={styles['container-tabs']}>
            {tabPaneData?.map((item) => {
              const { key, classes, tab: Tab, content: Content } = item
              return (
                <TabPane key={key} tab={<Tab />} className={classes}>
                  <Content list={productList} handleAddToCart={handleAddToCart} setCarts={setCarts} carts={carts} />
                </TabPane>
              )
            })}
          </Tabs>
        </NewFeedsLayout>
      </Col>
      <Col></Col>
    </Row>
  )
}
