import { Avatar, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import styles from '../styles/profile.module.scss'
import MessageIcon from 'assets/icons/message.svg'
import IdIcon from 'assets/icons/id.svg'
import PhoneIcon from 'assets/icons/phone.svg'
import EarthIcon from 'assets/icons/earth.svg'
import SettingIcon from 'assets/icons/setting.svg'
import LocationIcon from 'assets/icons/location.png'
import DefaultLogo from 'assets/img/store-default.png'
import moment from 'moment'
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { sendNotificationFollow, unSendNotificationFollow } from 'apis/profile'
import { useSelector } from 'react-redux'
import { socketEcom } from 'socket'
import { getNotificationsSystem } from 'redux/actions/notification'
import { unFollowUser } from 'redux/actions/profileUser'

const Info = ({ id, user, profileUser, dispatch }) => {
  const [userData, setUserData] = useState([])
  let dataUser = useSelector((state) => state.userInfo)
  const {socket} = useSelector(state => state)

  const [checkFollow, setCheckFollow] = useState(false)

  const handleRequestFollow = async (e) => {
    try {
      e.preventDefault()
      if (!dataUser) return

      const res = await sendNotificationFollow({ recipient_id: id })
      if (res.data.data) {
        if (res.data.data.recipient_id == id) {
          setCheckFollow(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnRequestFollow = async (e) => {
    try {
      e.preventDefault()
      if (!dataUser) return

      const res = await unSendNotificationFollow(id)
      if (res.data.success && res.data.success === true) {
        setCheckFollow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnFollow = async (data) => {
    await dispatch(unFollowUser({users: profileUser.users, user: data, auth: dataUser, socket}))
    setCheckFollow(false)
    dispatch(getNotificationsSystem())
}


  useEffect(() => {
    if (id == user.user_id) {
      setUserData([user])
    } else {
      const newData = profileUser.users.filter((e) => e.user_id == id)
      setUserData(newData)
      console.log(newData)
      if (newData.find((item) => item.recipient_id && item.recipient_id == id)) {
        setCheckFollow(true)
      }
    }
  }, [id, user, dispatch, profileUser.users])


  return (
    <div className={styles['info']}>
      {userData.map((data) => (
        <div className={styles['info_container']} key={data.user_id}>
          <div>
            <div className={styles['container-account']}>
              <div>
                <div className={styles['container-avt']}>
                  <Avatar size={104} src={data.avatar || DefaultLogo} />
                </div>

                {id != user.user_id &&
                  (checkFollow == true ? (
                    data.followers.find((item) => item.user_id == dataUser.user_id) ? (
                      <Button
                        onClick={() => handleUnFollow(data)}
                        style={{ width: '130px', marginTop: '15px' }}
                      >
                        <UserDeleteOutlined /> Huỷ theo dõi
                      </Button>
                    ) : (
                      <Button
                        onClick={handleUnRequestFollow}
                        style={{ width: '130px', marginTop: '15px' }}
                      >
                        <UserDeleteOutlined /> Huỷ yêu cầu
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={handleRequestFollow}
                      style={{ width: '130px', marginTop: '15px' }}
                    >
                      <UserAddOutlined /> Theo dõi
                    </Button>
                  ))}
              </div>

              <div className={styles['container-infor']}>
                <div className={styles['display-flex']}>
                  <div className={styles['infor-name']}>
                    <p>
                      {data.first_name} {data.last_name}
                    </p>
                  </div>
                  <div className={styles['container-icon-phone']}>
                    <img src={PhoneIcon} alt="" style={{ color: '#39CB7F', fontSize: 12 }} />
                  </div>
                  <div className={styles['container-icon-id']}>
                    <img src={IdIcon} alt="" />
                    {/* <span style={{ color: '#91919F' }}>id</span> */}
                  </div>
                </div>

                <div className={styles['container-status']}>
                  <p>Cuộc đời là một chuỗi ngày cần khám phá và trải nghiệm</p>
                </div>
                <div className={styles['container-flex']}>
                  <div className={styles['container-item']}>
                    {moment(data.date_of_birth).format('DD/MM/YYYY')}
                  </div>

                  <div className={styles['container-item']}>SĐT: {data.phone}</div>
                  <div
                    className={styles['container-item']}
                    title="fake data vì user trong db chưa có gender field"
                  >
                    {data.gender === 0 ? 'Nam' : data.gender === 1 ? 'Nữ' : 'Khác'}
                  </div>
                </div>
                <div className={styles['container-flex']}>
                  <div className={styles['container-item']}>
                    <img src={MessageIcon} alt="" />
                    <span>{data.email || 'JohnDoe@email.com.vn'}</span>
                  </div>

                  <div className={styles['container-item']}>
                    <img src={EarthIcon} alt="" />
                    <span>{data.web || 'ekata.com.vn'}</span>
                  </div>

                  <div className={styles['container-item']}>
                    <img src={LocationIcon} alt="" />
                    <span>{data.address || 'Đà Nẵng'}</span>
                  </div>
                </div>
                <div className={styles['container-dh']}>
                  <div className={styles['center']}>
                    <h1 className={styles['dh-number']}>{data.followers.length}</h1>
                    <p className={styles['dh-content']}>lượt theo dõi</p>
                  </div>
                  <div className={styles['center']}>
                    <h1 className={styles['dh-number']}>{data.following.length}</h1>
                    <p className={styles['dh-content']}>đang theo dõi</p>
                  </div>
                  <div className={styles['center']}>
                    <h1 className={styles['dh-number']}>6,2k</h1>
                    <p className={styles['dh-content']}>lượt mua sắm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Info
