import {useDispatch} from 'react-redux'
import {getCurrentUser} from "../apis/user";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";


export async function UserInfo() {
    const dispatch = useDispatch();

    try {
        const accessToken = Cookies.get('accessToken');
        const payload = accessToken && jwt_decode(accessToken)

        if (!payload) return;

        const res = await getCurrentUser()
        if (res.status === 200 && res.data.user) {
            dispatch({
                type: 'SET_USERINFO',
                data: {...res.data.user}
            });
        }
    } catch (error) {
        // log
    }
}
