import React from 'react'
import { useSelector } from 'react-redux'
import { Divider, Menu } from 'antd'
import { Link, useRouteMatch } from 'react-router-dom'
import styles from './layout.module.scss'

import Points from 'assets/icons/points.svg'
import News from 'assets/icons/news.svg'
import Voucher from 'assets/icons/voucher.svg'
import Message from 'assets/icons/message.png'

const MainMenu = ({ collapsed, showMenu, setShowMenu }) => {
  let menu = useSelector((state) => state.menu)
  const routeMatch = useRouteMatch()
  const linkto = (a) => {
    if (a.status === 4) {
      return a.url
    } else return '/messageError'
  }
  const renderMenuItem = (menu) => {
    let iconSvg
    switch (menu.name) {
      case 'Feed':
        iconSvg = News
        break
      case 'Voucher':
        iconSvg = Voucher
        break
      case 'Message':
        iconSvg = Message
        break
      default:
        iconSvg = Points
        break
    }

    return menu.subMenu ? (
      <Menu.SubMenu
        icon={<img src={iconSvg} alt="" />}
        style={{
          width: '100%',
          height: collapsed ? 40 : '',
          display: 'block',
        }}
        key={menu.url}
        title={menu.name}
      >
        {menu.subMenu.map((subMenu) => (
          <Menu.Item
            key={subMenu.url}
            icon={subMenu.icon}
            style={{
              padding: 0,
              fontSize: '0.9rem',
            }}
          >
            <Link to={linkto(subMenu)}>{subMenu.name}</Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item
        icon={<img src={iconSvg} alt="" style={{ width: '25px', height: '25px' }} />}
        key={menu.url}
        style={{
          width: '100%',
          height: collapsed ? 40 : '',
          display: 'block',
        }}
      >
        <Link to={linkto(menu)}>{menu.name}</Link>
      </Menu.Item>
    )
  }
  return (
    <>
      {collapsed ? (
        <Divider style={{ marginTop: 0, marginBottom: '5px' }} />
        ) : (
        <Divider style={{ margin: 0 }} plain>
          <div style={{cursor: 'pointer'}} className="master-menu">
            Chức năng
          </div>
        </Divider>
      )}
        <Menu
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'all 0.5s linear',
            height: showMenu == false ? 0 : '180px',
            opacity: showMenu == false ? 0 : 1,
          }}
          theme="light"
          selectedKeys={routeMatch.path}
          mode="inline"
          className={styles['sidebar-menu']}
        >
          {menu.map(renderMenuItem)}
        </Menu>
    </>
  )
}

export default MainMenu
