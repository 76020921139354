import firebase from "firebase/app";
import "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID
};

let messaging;
try {
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
} catch (e) {
    // console.log(e)
}

export const getToken = async (setTokenFound) => {
    let currentToken = "";
    try {
        if (!messaging) {
            return;
        }
        currentToken = await messaging.getToken({vapidKey: process.env.REACT_APP_FCM_VAPI_KEY});
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }

    return currentToken;
};
