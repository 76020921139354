import React, { useState } from 'react'
import styles from './style.module.scss'
import { Link,useHistory } from 'react-router-dom'

//antd
import { Input, AutoComplete } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
//icon
import defaultLogoUser from 'assets/img/avatar-round.png'
import defaultLogoBusiness from 'assets/img/store-default.png'
import { searchBusinessAndUser } from 'apis/search'
import { useEffect } from 'react'
import { ROUTES } from 'consts'
export default function DropdownLanguage() {
  const [valueSearch, setValueSearch] = useState('')
  const [options, setOptions] = useState([])
  const [business, setBusiness] = useState([])
  const [users, setUsers] = useState([])
  const history = useHistory()
  const onSearch = (e) => {
    let value = e.target.value
    setTimeout(setValueSearch(value), 650)
  }

  const getDataBusinessAndUser = async () => {
    let search = valueSearch !== '' ? valueSearch.trim() : ''
    if (search.length === 0) {
      setBusiness([])
      setUsers([])
    } else {
      const res = await searchBusinessAndUser({ search: search })
      if (res.status === 200) {
        setBusiness(res.data.data.business)
        setUsers(res.data.data.users)
      }
    }
  }
  useEffect(() => {
    getDataBusinessAndUser()
  }, [valueSearch])
  useEffect(() => {
    setOptions([
      {
        label: renderTitle('Người dùng'),
        options: users.length > 0 ? users.map((e, index) =>
          renderItem(e.user_id,'', e.first_name + ' ' + e.last_name, e.avatar, 1, index)
        ) : []
      },
      {
        label: renderTitle('Cửa hàng'),
        options: business.length > 0 ? business.map((e, index) =>
          renderItem(e.business_id,e.prefix, e.business_name, e.business_logo, 0, index)
        ) : []
      },
    ])
  },[business, users])
  const renderTitle = (title) => <p>{title}</p>
  const renderItem = (id, prefix,title, logo, change, index) => ({
    key: id,
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {change === 1 ? (
          <Link style={{ display: 'flex', alignItems: 'center' }} to={`/info-user/${id}`}>
            <img src={logo ? logo : defaultLogoUser} className={styles['business-logo']} alt="" />
            <div className={styles['title']}>{title}</div>
          </Link>
        ) : (
          <Link style={{ display: 'flex', alignItems: 'center' }} to={{pathname: `/${prefix}`,state: {business_id: id}}} >
            <img
              src={logo ? logo : defaultLogoBusiness}
              className={styles['business-logo']}
              alt=""
            />
            <div className={styles['title']}>{title}</div>
          </Link>
        )}

        <CloseOutlined
          style={{ fontSize: 15 }}
          onClick={(e) => {
            e.stopPropagation()
            if(change === 1){
              let newUsers = [...users]
              newUsers.splice(index,1)
              setUsers(newUsers)
            }else {
              let newBusiness = [...business]
              newBusiness.splice(index,1)
              setUsers(newBusiness)
            }
          }}
        />
      </div>
    ),
  })
  const moveSearchPage = (e)=>{
    let value = e.target.value
    if(value.length > 0){
      const url = `${ROUTES.SEARCH}?q=${encodeURIComponent(JSON.stringify(value))}`;
      history.push(url);
    }
  }
  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={300}
      style={{
        width: 250,
      }}
      options={options}
    >
      <Input
        className={styles['header-search']}
        prefix={<SearchOutlined />}
        placeholder="Search here"
        onChange={onSearch}
        allowClear={true}
        onPressEnter={(e)=> moveSearchPage(e)}
      />
    </AutoComplete>
  )
}
