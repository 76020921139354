const initialState = [];
const menu = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MENU': {
            state = action.data
            return [...state]
        }
        default:
            return state
    }
};
export default menu
