import {useEffect, useState} from "react";
import {getToken} from "../../firebase";
import {updateUserFcmToken} from "../../apis/user";

const Notifications = () => {
    const [isTokenFound, setTokenFound] = useState(false);
    console.log("Token found", isTokenFound);
    useEffect(() => {
        let data;

        async function tokenFunc() {
            data = await getToken(setTokenFound);
            if (data) {
                updateUserFcmToken({
                    fcmToken: data
                });
            }
            return data;
        }

        tokenFunc();
    }, [setTokenFound]);
    return <></>;
};
export default Notifications;