import React from 'react'
import HeaderForm from './form/headerForm'
import RegisterForm from './form/registerForm'
import FooterForm from './form/footerForm'
import styles from './styles/index.module.scss'

const FormRegister = () => {
  return (
    <div className={styles['form-register-responsive']}>
      <div>
        <HeaderForm />
      </div>
      <div style={{marginTop: '32px'}}>
        <RegisterForm />
      </div>
      <div>
        <FooterForm/>
      </div>
    </div>
  )
}

export default FormRegister