import { LeftOutlined, RightOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
  Radio,
  Tag,
  InputNumber,
  notification,
} from 'antd'
import { ButtonCustom } from 'components/Button/Button'
import { useRef } from 'react'
import { useState } from 'react'
import { formatCash } from 'utils'
const { Meta } = Card

export const ModalDetailProduct = ({ handleAddToCart, product, check }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imgVariant, setImgVariant] = useState('')
  const [dataAddCart, setDataAddCart] = useState({})
  const [variantId, setVariantId] = useState(null)

  const carousel = useRef()

  const handleNext = () => carousel.current.next()

  const handlePrev = () => carousel.current.prev()

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setDataAddCart({})
    setVariantId(null)
    setIsModalOpen(false)
    setImgVariant('')
  }

  return (
    <>
      {!check ? (
        <Button icon={<ShoppingCartOutlined />} type="primary" onClick={showModal} />
      ) : (
        <ButtonCustom
          classes="btn__blue-sky-fill"
          style={{ marginTop: '8px' }}
          icon={() => <ShoppingCartOutlined style={{ fontSize: '16px' }} />}
          text={'Thêm vào giỏ'}
          functionOnClick={showModal}
        />
      )}
      <Modal
        title={<span>Thông tin sản phẩm</span>}
        visible={isModalOpen}
        footer={[
          <Row gutter={[12, 12]} justify="end">
            <Col>
              <Button onClick={handleCancel}>Đóng</Button>
            </Col>
          </Row>,
        ]}
        closable={false}
        width={600}
        headStyle={{ backgroundColor: '#1e4db8' }}
      >
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Card
              hoverable
              style={{ width: '100%' }}
              cover={
                <div>
                  <Carousel ref={carousel} autoplay>
                    {imgVariant.length > 0 ? (
                      <div>
                        <Image src={imgVariant} />
                      </div>
                    ) : (
                      product?.images?.map(
                        (item, index) =>
                          item.length > 0 && (
                            <div key={index}>
                              <Image src={item} />
                            </div>
                          )
                      )
                    )}
                  </Carousel>
                  <Row gutter={[12, 0]} align="center">
                    <Col span={12}>
                      <Button
                        style={{ width: '100%' }}
                        type="text"
                        icon={<LeftOutlined />}
                        onClick={handlePrev}
                      />
                    </Col>
                    <Col span={12}>
                      <Button
                        style={{ width: '100%' }}
                        type="text"
                        icon={<RightOutlined />}
                        onClick={handleNext}
                      />
                    </Col>
                  </Row>
                </div>
              }
            >
              <Meta
                title={<span>Sản phẩm: {product?.name}</span>}
                description={
                  <span style={{ overflow: 'scroll', display: 'block', maxHeight: '150px' }}>
                    Mô tả: {product?.description}
                  </span>
                }
              />
            </Card>
          </Col>
          <Col span={12}>
            <div>
              <h1 style={{ fontSize: 18 }}>Thông tin sản phẩm</h1>
              <p>
                Giá bán:{' '}
                <span style={{ textDecoration: 'line-through' }}>
                  {formatCash(product?.priceOld)}
                </span>{' '}
                <strong>
                  {formatCash(
                    variantId === null
                      ? product?.price
                      : Object(product?.variants?.find((item) => item.variant_id == variantId))
                          .price
                  )}
                  đ
                </strong>
              </p>
              <Tag color="#108ee9">{product?.discount} giảm giá</Tag>
              <Tag color="processing">{product?.amount} đã bán</Tag>
            </div>
            <div style={{ marginTop: 10 }}>
              <h1 style={{ fontSize: 18 }}>Danh sách phiên bản</h1>
              <Radio.Group
                value={variantId}
                onChange={(e) => {
                  let variant = product?.variants?.find((item) => item.variant_id == e.target.value)
                  setImgVariant(variant?.image[0] || '')
                  if (e.target.value) {
                    setVariantId(variant.variant_id)
                  } else {
                    setVariantId(null)
                  }
                  setDataAddCart({
                    ...variant,
                    quantity: 1,
                    business_id: product.business,
                    product_id: product.product_id,
                  })
                }}
              >
                <Row>
                  {product?.variants?.map((item, index) => (
                    <Col style={{ margin: 5 }}>
                      <Radio.Button key={index} value={item.variant_id}>
                        {item.title}
                      </Radio.Button>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
              <h1 style={{ fontSize: 15 }}>Số lượng:</h1>
              {Object.keys(dataAddCart).length > 0 && (
                <InputNumber
                  defaultValue={1}
                  min={1}
                  value={dataAddCart.quantity || 1}
                  onChange={(e) => {
                    let newData = { ...dataAddCart }
                    if (variantId !== null) {
                      newData.quantity = e
                    }
                    setDataAddCart(newData)
                  }}
                />
              )}
              <span> 123 sản phẩm mở bán</span>
            </div>
            <Row style={{ marginTop: 30 }}>
              <Col span={16}>
                <Button
                  onClick={async () => {
                    if (variantId === null) {
                      return notification.error({
                        key: 'errorAddToCart',
                        message: 'Vui lòng chọn phiên bản!',
                      })
                    }
                    if (Object.keys(dataAddCart).length == 0) {
                      return notification.error({
                        key: 'quantityError',
                        message: 'Vui lòng nhập số lượng lớn hơn 0',
                      })
                    }
                    handleAddToCart(dataAddCart)
                    setIsModalOpen(false)
                    setDataAddCart({})
                    setVariantId(null)
                    setImgVariant('')
                  }}
                  style={{ borderColor: '#1e4db8', color: '#1e4db8' }}
                >
                  <ShoppingCartOutlined /> Thêm vào giỏ hàng
                </Button>
              </Col>
              <Col span={8}>
                <Button style={{ backgroundColor: '#1e4db8', color: 'white' }}>Mua ngay</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
