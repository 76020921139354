import React from "react";
import { Redirect} from 'react-router-dom'
import CarouselCustom from "components/carousel/carouselCustom";
import styles from "./styles/index.module.scss";
import FormLogin from "./formLogin";
import { ConfigProvider } from "antd";
import BgAuth from '../../../assets/img/bg-auth.png'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { ROUTES } from "consts";
const Login = () => {

  const accessToken = Cookies.get('accessToken')
  const payload = accessToken && jwt_decode(accessToken)
  if (payload) return <Redirect to={ROUTES.HOME_PAGE} />
  
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
          colorTextPlaceholder: '#667085',
          colorPrimary: "#335BD3",
        },
      }}
    >
      <div className={styles["layout-login"]}>
        <div style={{background: `url(${BgAuth})`}} className={styles["layout-carousel"]}>
          <CarouselCustom />
        </div>
        <div className={styles["layout-form-login"]}>
          <FormLogin />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Login;
