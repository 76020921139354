import { Col, Row, Tabs } from 'antd'
import styled from 'styled-components'
import { UserInfo } from 'preloads/user-info'
import { LoadMyBusiness } from 'preloads/my-business'
import { LoadNotification } from 'preloads/notification'
import TopBusiness from 'components/newfeed/top-business'
import ShoppingHistory from 'components/newfeed/shopping-history'
import NewFeeds from 'components/newfeed/feeds'
import Tab from 'components/newfeed/tab'
import styles from './home-page.module.scss'
import NewFeedsLayout from 'components/layout/NewFeeds/NewFeeds.layout'

import News from 'assets/icons/news.svg'
import Time from 'assets/icons/time.svg'
import { useState, useEffect } from 'react'
import { getToken } from '../../firebase'
import Notifications from '../../components/FcmNotification'
import { useDispatch, useSelector } from 'react-redux'
import { socketPos } from 'socket'
import { getNotificationsSystem } from 'redux/actions/notification'
import { getNotificationStaff } from 'apis/nofitication'
import Forum from 'views/forum'
const TabsStyled = styled(Tabs)`
  .ant-tabs-content {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ant-tabs-nav-list {
  }

  .ant-tabs-nav-list > .ant-tabs-tab {
    margin: 0;
    justify-content: center;
  }
`

const tabPaneData = [
  {
    key: 3,
    content: Forum,
    tab: () => (
      <Tab
        classes={styles.tabpane}
        text="Bản tin"
        icon={() => <img src={News} alt="" style={{ marginRight: '5px' }} />}
      />
    ),
  },
  {
    key: 1,
    content: NewFeeds,
    tab: () => (
      <Tab
        classes={styles.tabpane}
        text="Marketplace"
        icon={() => <img src={News} alt="" style={{ marginRight: '5px' }} />}
      />
    ),
  },
  {
    key: 2,
    content: ShoppingHistory,
    tab: () => (
      <Tab
        classes={styles.tabpane}
        text="Lịch sử mua sắm"
        icon={() => <img src={Time} alt="" style={{ marginRight: '5px' }} />}
      />
    ),
  },
]

const { TabPane } = Tabs

function TabPaneList({ list, defaultActiveKey = 1 }) {
  return (
    <TabsStyled defaultActiveKey={defaultActiveKey} className={styles['container-tabs']}>
      {list.map((item) => {
        const { key, tab: Tab, content: Content } = item

        return (
          <TabPane key={key} tab={<Tab />}>
            <Content />
          </TabPane>
        )
      })}
    </TabsStyled>
  )
}

function App() {
  UserInfo()
  LoadMyBusiness()
  LoadNotification()
  return (
    <>
      <Notifications />
      <Row>
        <Col span={7}></Col>
        <Col span={16}>
          <NewFeedsLayout classes={styles['container-tabs__newfeeds']}>
            <TabPaneList list={tabPaneData} />
          </NewFeedsLayout>
        </Col>
        <Col></Col>
      </Row>
    </>
  )
}

export default App
