export const MASTER_COLORS = {
  BACKGROUND: '#f9f9f9',
  HEADER: '#fff',
  SIDEBAR: '#fff',
  MENU_ICON: '#a5a5a5',
  NOTIFY_ICON: '#a5a5a5',
  USER_ROLE_NAME: '#8F8F8F',

  DEFAULT: '#fff',
}
