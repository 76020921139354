/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, memo } from 'react'
import styles from './layout.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, ROUTES } from 'consts'
import { useHistory, Link } from 'react-router-dom'
import DefaultLogo from 'assets/img/store-default.png'

import { Affix, Avatar, BackTop, Badge, Col, Dropdown, Input, Layout, Row } from 'antd'

import {
  BellOutlined,
  ExportOutlined,
  MenuOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { socketPos } from 'socket'
// apis
import { logout } from 'apis/auth'

//components
import DropdownLanguage from 'components/dropdown-language'
import DropdownSearch from 'components/dropdownSearch'
import MainMenu from './main-menu'
import MyBusiness from './my-business'

import logoIcon from 'assets/img/logo-icon.png'
import logoText from 'assets/img/logo-text.png'

// color const
import { MASTER_COLORS } from 'consts/colors'

//apis
import { CarretDown } from '../../utils/icon'
import Notification from './Notifications/notification'
import TopBusiness from 'components/newfeed/top-business'
import { getNotificationsSystem } from 'redux/actions/notification'
import FilterMenu from './filter-menu'
import MyCart from './my-cart'

const { Sider } = Layout
const BaseLayout = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH_MENU_OPEN = 231
  const WIDTH_MENU_CLOSE = 90

  let user = useSelector((state) => state.userInfo)
  let notification = useSelector((state) => state.notification)
  const isCollapsed = localStorage.getItem('collapsed')
    ? JSON.parse(localStorage.getItem('collapsed'))
    : false
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const [isMobile, setIsMobile] = useState(false)

  const [showFilter, setShowFilter] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showBusiness, setShowBusiness] = useState(true)

  const [windowSize, setWindowSize] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    if (window.innerWidth < 1300) {
      localStorage.setItem('collapsed', JSON.stringify(true))
      setCollapsed(true)
    } else if (window.innerWidth > 1300) {
      if (collapsed === true) {
        localStorage.setItem('collapsed', JSON.stringify(false))
        setCollapsed(false)
      }
    }
    setWindowSize({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  const onSignOut = async () => {
    dispatch({ type: ACTION.LOGOUT })
    dispatch({ type: 'UPDATE_INVOICE', data: [] })
    localStorage.removeItem('accessToken')
    await logout()
    history.push(ROUTES.LOGIN)
  }

  const userHeaderPopup = (
    <div className={styles['user_information']}>
      <div>
        <Link style={{ color: '#565656', paddingLeft: 10 }} to="/infor">
          <UserOutlined style={{ fontSize: '1rem', marginRight: 10, color: ' #565656' }} />
          Tài khoản của tôi
        </Link>
      </div>

      <div>
        <a onClick={onSignOut} style={{ color: '#565656', paddingLeft: 10 }}>
          <div>
            <ExportOutlined style={{ fontSize: '1rem', marginRight: 10, color: '#565656' }} />
            Đăng xuất
          </div>
        </a>
      </div>
    </div>
  )

  const NotifyContent = () => (
    <dic>
      <div className={styles['notificationBox']}>
        <div className={styles['title']}>Thông báo</div>
      </div>

      <div>
        <a onClick={onSignOut} style={{ color: '#565656', paddingLeft: 10 }}>
          <div>
            <ExportOutlined style={{ fontSize: '1rem', marginRight: 10, color: '#565656' }} />
            Đăng xuất
          </div>
        </a>
      </div>
    </dic>
  )

  var toggle = () => {
    localStorage.setItem('collapsed', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  const setCollapsedMobile = () => {
    if (window.innerWidth < 1280) {
      setCollapsed(true)
    }

    if (window.innerWidth < 768) {
      setIsMobile(true)
      setCollapsed(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    setCollapsedMobile()
  }, [])

  const socketListener = () => {
    if (user) {
      socketPos.on(`noti-staff:action:${user.user_id}`, async (res) => {
        dispatch(getNotificationsSystem())
      })
    }
  }

  useEffect(() => {
    socketListener()
  }, [])

  useEffect(() => {
    if (props.check !== true) {
      window.addEventListener('resize', detectSize)

      return () => {
        window.removeEventListener('resize', detectSize)
      }
    } else {
      localStorage.setItem('collapsed', JSON.stringify(true))
      setCollapsed(true)
    }
  }, [windowSize, props.check])
  return (
    <Layout style={{ backgroundColor: MASTER_COLORS.BACKGROUND, height: '100vh' }}>
      <BackTop style={{ right: 10, bottom: 15 }} />

      <Sider
        trigger={null}
        collapsible
        width={isMobile ? '100%' : WIDTH_MENU_OPEN}
        collapsedWidth={isMobile ? 0 : WIDTH_MENU_CLOSE}
        style={{
          backgroundColor: MASTER_COLORS.SIDEBAR,
          zIndex: isMobile && 6000,
          height: '100vh',
          position: 'fixed',
        }}
        collapsed={collapsed}
        className={styles['sidebar']}
      >
        <Row
          style={{
            display: collapsed ? 'flex' : 'flex',
            height: 130,
            paddingLeft: 24,
          }}
        >
          <Link to="/" className={styles['logo-block']}>
            <img src={logoIcon} alt="" />
            <img
              style={{
                display: collapsed ? 'none' : 'block',
              }}
              src={logoText}
              alt=""
            />
          </Link>
        </Row>

        <MainMenu showMenu={showMenu} setShowMenu={setShowMenu} collapsed={collapsed} />
        <MyBusiness
          showBusiness={showBusiness}
          setShowBusiness={setShowBusiness}
          collapsed={collapsed}
        />
        {/* {props.check !== true && (
          <FilterMenu
            showMenu={showMenu}
            showBusiness={showBusiness}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            collapsed={collapsed}
          />
        )} */}
      </Sider>
      <Layout style={{ marginLeft: collapsed ? WIDTH_MENU_CLOSE : WIDTH_MENU_OPEN }}>
        <Affix offsetTop={0}>
          <Row
            wrap={isMobile}
            justify="space-between"
            align="middle"
            style={{ backgroundColor: MASTER_COLORS.HEADER }}
            className={styles['header-row']}
          >
            <Row align="middle" wrap={false} justify={isMobile && 'space-between'}>
              {props.check !== true && windowSize.winWidth > 1300 && (
                <MenuOutlined
                  onClick={toggle}
                  style={{ color: MASTER_COLORS.MENU_ICON }}
                  className={styles['menu-icon']}
                />
              )}
              {props.check !== true && (

              <DropdownSearch />
              )}

            </Row>
            <Row wrap={false} align="middle" style={{ maxWidth: 440, columnGap: 15 }}>
              <div style={{ marginRight: '10px' }}>
                <Dropdown
                  overlayStyle={{ position: 'fixed' }}
                  overlay={<MyCart />}
                  placement="bottom"
                  trigger="click"
                >
                  <Badge count={notification.length} showZero={true} size="small">
                    <ShoppingCartOutlined
                      style={{ color: MASTER_COLORS.NOTIFY_ICON }}
                      className={styles['notify-icon']}
                    />
                  </Badge>
                </Dropdown>
              </div>

              <div style={{ marginRight: '10px' }}>
                <Dropdown
                  overlayStyle={{ position: 'fixed' }}
                  overlay={<Notification />}
                  placement="bottom"
                  trigger="click"
                >
                  <Badge count={notification.length} showZero={true} size="small">
                    <BellOutlined
                      style={{ color: MASTER_COLORS.NOTIFY_ICON }}
                      className={styles['notify-icon']}
                    />
                  </Badge>
                </Dropdown>
              </div>
              {/* <DropdownLanguage /> */}

              <Dropdown
                overlayStyle={{ position: 'fixed' }}
                overlay={userHeaderPopup}
                trigger="click"
              >
                <Row
                  align="middle"
                  wrap={false}
                  style={{ cursor: 'pointer' }}
                  className={styles['user-info-block']}
                >
                  <Avatar
                    src={user && (user.avatar || '')}
                    style={{
                      color: 'black',
                      backgroundImage: `url(${user.avatar || DefaultLogo})`,
                      width: 35,
                    }}
                  />
                  <div className={styles['user-info']}>
                    <span>{user && (user.first_name + ' ' + user.last_name || '--')}</span>
                    <span style={{ color: MASTER_COLORS.USER_ROLE_NAME }}>Người dùng</span>
                  </div>
                  <CarretDown />
                </Row>
              </Dropdown>
            </Row>
          </Row>
        </Affix>
        <Row
          style={{
            backgroundColor: '#F5F5F5',
            width: '100%',
            height: 'calc(100vh - 72px)',
            // display: 'flex',
            // flexWrap: 'wrap',
            // justifyContent: 'center',
            padding: props.check !== true && '0 15px',
          }}
        >
          <Col span={props.check !== true ? 18 : 24}>{props.children}</Col>
          {props.check !== true && (
            <Col span={6}>
              <Affix offsetTop={100}>
                <TopBusiness />
              </Affix>
            </Col>
          )}
        </Row>
      </Layout>
    </Layout>
  )
}

export default memo(BaseLayout)
