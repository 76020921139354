import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, notification, Row, Upload, message, Select } from 'antd'
import styles from './register-business.module.scss'
import { UploadOutlined } from '@ant-design/icons'
import { ROUTES } from 'consts'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createNewBusiness } from 'apis/business'
import jwt_decode from 'jwt-decode'
import { myBusiness } from 'apis/business'
import ImgCrop from 'antd-img-crop'
const BusinessInfo = ({ form, gotoStep, current, formLoading, submit }) => {
  const dataUser = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken'))
    : {}
  const [businessLogo, setBusinessLogo] = useState('')
  const dispatch = useDispatch()

  const formConf = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  }

  const props = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg']
      if (allowedExtensions.includes(extension)) {
        const { status } = info.file
        if (status === 'done') {
          setBusinessLogo(info.file.response.url)
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } else {
        notification.warning({ key: 'fail file', message: 'Yêu cầu nhập file đúng định dạng' })
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  const _addBusiness = async (data) => {
    try {
      if(data.business_website){
      const regexPassword =/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
        if (!regexPassword.test(data.business_website)) {
          notification.error({
            message:
              'Website không đúng định dạng',
          })
          return
        }
      }
      
      const body = {
        business_logo: data?.business_logo,
        business_name: data?.business_name.trim(),
        business_phone: data?.business_phone,
        business_address: data.business_address !== undefined ? data.business_address.trim() : '',
        business_website: data?.business_website !== undefined ? data.business_website.trim() : '',
        career: data.career,
        business_registration_number:
          data?.business_registration_number !== undefined
            ? data.business_registration_number.trim()
            : '',
      }
      const res = await createNewBusiness(body)
      if (res.status === 200 && res.data.status) {
        notification.info({ message: res.data.message })
        const loadBusiness = await myBusiness()
        dispatch({
          type: 'SET_MY_BUSINESS',
          data: loadBusiness.data.data,
        })
        gotoStep(current + 1)

      } else {
        notification.error({
          message: res.data.message,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // set for data test
    form.setFieldsValue({
      business_phone: dataUser.data.phone,
    })
  }, [form])

  return (
    <Row>
      <div id="TTCH" className={styles['card-overview']}>
        <Form form={form}>
          <Col>
            <Form.Item
              label="Tên cửa hàng"
              name="business_name"
              {...formConf}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên cửa hàng!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Số điện thoại"
              name="business_phone"
              {...formConf}
              rules={[
                { required: true, message: 'Vui lòng nhập số điện thoại' },
                {
                  pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
                  message: 'Vui lòng nhập số điện thoại đúng định dạng',
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Địa chỉ" name="business_address" {...formConf}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Website" name="business_website" {...formConf}>
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Ngành nghề"
              name="career"
              {...formConf}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập ngành nghề!',
                },
              ]}
            >
              <Select style={{ width: '92%' }}>
                <Select.Option value={1}>Cửa hàng quần áo</Select.Option>
                <Select.Option value={2}>F&B</Select.Option>
                <Select.Option value={3}>Ngành hàng tiêu dùng</Select.Option>
                <Select.Option value={4}>Điện tử công nghệ</Select.Option>
                <Select.Option value={5}>Các loại dịch vụ khác</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Logo" name="avatar" {...formConf}>
              <ImgCrop rotate>
                <Upload
                  {...props}
                  onRemove={() => {
                    setBusinessLogo('')
                  }}
                >
                  {businessLogo ? (
                    <img
                      src={businessLogo}
                      alt="business logo"
                      style={{ width: 130, height: 100, objectFit: 'cover' }}
                    />
                  ) : (
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Mã số đăng ký" name="business_registration_number" {...formConf}>
              <Input />
            </Form.Item>
          </Col>

          <Form.Item style={{ marginTop: 40, textAlign: 'center' }}>
            <Link to={ROUTES.HOME_PAGE}>
              <Button className={styles['cancel-business-btn']}>Hủy bỏ</Button>
            </Link>
            <Button
              className={styles['register-business-btn']}
              type="primary"
              loading={formLoading}
              onClick={() => {
                submit({ business_logo: businessLogo, _addBusiness: _addBusiness })
              }}
            >
              Đăng ký cửa hàng
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Row>
  )
}

export default BusinessInfo
