// const initialState = []

import { EditData, SearchData } from "redux/actions/global";

const initialState = {
  loading: false,
  posts: [],
  postsFilter: [],
  result: 0,
  page: 2
}


const postsProduct = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_POSTS_PRODUCT': 
    return {
      ...state,
      postsFilter: action.payload,
      posts: action.payload,
    };
    case 'UPDATE_POST':
      return {
        ...state,
        postsFilter: EditData(state.posts, action.payload.post_id, action.payload),
        posts: EditData(state.posts, action.payload.post_id, action.payload),
    };
    case 'SEARCH_POSTS': 
    return {
      ...state,
      postsFilter: SearchData(state.posts, action.payload),
    };
    default:
      return state
  }
}
export default postsProduct
