import { UserInfo } from './user-info'
import { LoadMyBusiness } from './my-business'
import { LoadMenu } from './menu'
import { LoadNotification } from './notification'
import { LoadVoucher } from './voucher'
import { LoadFeed } from './feed'
import {Visit} from './visit'

export async function Preload() {
  await Promise.all([
    LoadMenu(),
    UserInfo(),
    LoadMyBusiness(),
    LoadNotification(),
    LoadVoucher(),
    LoadFeed(),
    Visit()
  ])
}
