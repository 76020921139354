const initialState = {
  users: [],
  countUsers: 0,
  data: [],
  firstLoad: false,
  media: {},
}
export const EditData = (data, id, new_data) => {
  const newData = data.map((item) => (item.user_id == id ? new_data : item))
  return newData
}

export const DeleteMessage = (data, id) => {
  const newData = data.map((item) => {
    if (item.message_id == id) {
      item.is_delete = true
    }
  })
  return newData
}

export const DeleteConversation = (data, id) => {
    const newData = data.filter(item => item.user_id != id)
    return newData;
}

const message = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_USER':
      if (state.users.every((item) => item.user_id != action.payload.user_id)) {
        return {
          ...state,
          users: [action.payload, ...state.users],
        }
      }
      return state
    case 'ADD_MESSAGE':
      return {
        ...state,
        data: state.data.map((item) =>
          item.user_id == action.payload.recipient_id || item.user_id == action.payload.sender_id
            ? {
                ...item,
                messages: [...item.messages, action.payload],
                count: item.count + 1,
              }
            : item
        ),
        users: state.users.map((user) =>
          user.user_id == action.payload.recipient_id || user.user_id == action.payload.sender_id
            ? {
                ...user,
                text: action.payload.text,
                media: action.payload.media,
                call: action.payload.call,
              }
            : user
        ),
      }
    case 'GET_CONVERSATIONS':
      return {
        ...state,
        users: action.payload.newArr,
        countUsers: action.payload.count,
        firstLoad: true,
      }
    case 'GET_MESSAGES':
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case 'GET_MEDIA_MESSAGES':
      return {
        ...state,
        media: action.payload,
      }
    case 'UPDATE_MEDIA_MESSAGES':
        return {
          ...state,
          media: {...state.media, data: [...action.payload, ...state?.media?.data]},
        }
    case 'UPDATE_MESSAGES':
      return {
        ...state,
        data: EditData(state.data, action.payload.user_id, action.payload),
      }
    case 'DELETE_MESSAGES':
        return {
            ...state,
            data: state.data.map(item => 
                item.user_id === action.payload.recipient_id
                ? {...item, messages: action.payload.newData}
                : item
            )
        };
    case 'DELETE_CONVERSATION':
        return {
            ...state,
            users: DeleteConversation(state.users, action.payload),
            data: DeleteConversation(state.data, action.payload)
        };
    default:
      return state
  }
}

export default message
