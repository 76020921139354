import {post} from './httpClient'

const SSO_API_ENDPOINT = process.env.REACT_APP_SSO_API_ENDPOINT;

export const register = (body) => post('/auth/register', body, {}, SSO_API_ENDPOINT)
export const login = (body) => post('/auth/login', body, {}, SSO_API_ENDPOINT)
export const logout = () => post('/auth/logout', {}, {}, SSO_API_ENDPOINT)
export const verify = (body) => post('/auth/verify', body, {}, SSO_API_ENDPOINT)
export const sendOtp = (phone) => post('/auth/send-otp', {phone}, {}, SSO_API_ENDPOINT)
export const forgetPasswordSendOtp = (phone) => post('/auth/forget-password/send-otp', {phone}, {}, SSO_API_ENDPOINT)
export const forgetPasswordVerify = (body) => post('/auth/forget-password/verify', body, {}, SSO_API_ENDPOINT)


