import React from 'react'
import styles from './point.module.scss'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { Card, Col, Row, Table, Timeline } from 'antd'
import logo1 from 'assets/img/Product-2.png'
import customerIcon from 'assets/point/Vector.png'
import huyhieu from 'assets/point/huyhieu.png'
import date from 'assets/point/date.png'
import defaultLogo from 'assets/img/store-default.png'
import { useSelector } from 'react-redux'
import { formatCash } from 'utils'
import ProgressPoint from './items/progress-point'
import HistoryPoint from './items/history-point'

export default function DetailPoint() {
  const location = useLocation()

  const user = useSelector((state) => state.userInfo)
  const [point, setPoint] = useState(location.state.point)
  return (
    <div
      style={{
        width: '75%',
        marginTop: '5%',
        marginLeft: '10%',
        marginRight: '10%',
        minWidth: '575px',
      }}
    >
      <div className={styles.card_bg}>
        <div style={{ height: '100%' }}>
          <div
            style={{
              backgroundImage: point.business_cover_image
                ? `url(${point.business_cover_image})`
                : `url(${logo1})`,
            }}
            className={styles['profile__avatar-box']}
          >
            <div className={styles['profile__avatar-bg']}>
              <img
                src={point.logo ? point.logo : defaultLogo}
                alt=""
                className={styles.profile__avatar}
              />
            </div>
            <div>
              <div className={styles.profile__name}>{point.business_name}</div>
              <div className={styles.profile__online}>Online 10 phút trước</div>
            </div>
          </div>
        </div>
        <div className={styles[`point`]}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '15%' }}>
              <div className={styles.point_text}>Tổng chi tiêu</div>
              <div className={styles.point_number}>{formatCash(point.order_total_cost)} VND</div>
            </div>
            <div>
              <div className={styles.point_text}>Điểm</div>
              <div className={styles.point_number}>
                {formatCash(point.point)}
              </div>
            </div>
          </div>
          <div>
            <div className={styles.point_date}>1.569 điểm sẽ hết hạn vào 30/09/2022</div>
          </div>
        </div>
      </div>

      <div className={styles.card_info}>
        <div className={styles.info} style={{ borderRight: '1px solid #e3dddd', paddingRight: '5%', marginLeft: '5%' }}>
          <div className={styles['info__title']}>
            <img src={customerIcon} className={styles['info__img']} alt="" />
            <span>Thông tin khách hàng</span>
          </div>
          <div className={styles['info__name']}>
            {user.first_name} {user.last_name}
          </div>
          <div className={styles['info__sdt']}>{user.phone}</div>
        </div>

        <div className={styles.info} style={{ borderRight: '1px solid #e3dddd', paddingRight: '5%', marginLeft: '5%' }}>
          <div className={styles['info__title']}>
            <img src={huyhieu} className={styles['info__img']} alt="" />
            <span>Hạng khách hàng</span>
          </div>
          <div className={styles['info__name']}>Khách hàng thân thiết</div>
          <div className={styles['info__sdt']}></div>
        </div>

        <div className={styles.info} style={{ borderRight: 'none', marginLeft: '5%', paddingRight: '5%' }}>
          <div className={styles['info__title']}>
            <img src={date} className={styles['info__img']} alt="" />
            <span>Thời điểm xét hàng</span>
          </div>
          <div className={styles['info__name']}>30/05/2022</div>
          <div className={styles['info__sdt']}></div>
        </div>
      </div>
      <ProgressPoint/>
      <HistoryPoint point={point}/> 

    </div>
  )
}
