import { Button, Modal } from 'antd'
import React from 'react'

const PopupDeleteConversation = ({visible, setVisible, handleDeleteConversation }) => {

  return (
    <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        style={{top: '30%'}}
        footer={null}
    >
        <h1>Bạn có muốn xoá cuộc trò chuyện này không? Đó là một kỉ niệm đẹp...</h1>

        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button onClick={() => setVisible(false)} style={{width: '47%', marginRight: '6%'}}>Huỷ bỏ</Button>
            <Button onClick={handleDeleteConversation} type='primary' style={{width: '47%'}}>Xoá</Button>
        </div>

    </Modal>
  )
}

export default PopupDeleteConversation