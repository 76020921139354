import styled from 'styled-components'

export const NavigationContainer = styled.div`
  display: block;
  width: 100%;
  height: 60px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 424px;
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translateX(calc(-50% - 15px));
  background-color: white;
  box-shadow: 0 -1px 5px 1px #dddddd;
`

export const NavigationBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
`

export const NavigationItem = styled.div`
  width: 20%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const NavigationPlaceHolder = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  ${(props) =>
    props.isPrimary
      ? `
      width: 48px;
      height: 46px;
      background-color: #2196f3;
      background-image: url(${props.image});
      border-radius: 10px;
      background-blend-mode: screen;
    `
      : `
      width: 38px;
      height: 38px;
      background-color: #e8e8e8;
      background-image: url(${props.image});
      border-radius: 7px;
    `}
`
