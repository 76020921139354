import React, {useEffect, useState} from 'react'
import styles from './register-business.module.scss'

//antd
import {Steps} from 'antd'

//icons antd
import {useStepsForm} from 'sunflower-antd'

//apis
// components
import BusinessInfo from "./business-info";
import VerifyBusiness from "./verify-business";
import BusinessFinished from "./business-finished";
import { useLocation } from 'react-router-dom';

function RegisterBusiness() {
    const location = useLocation()
    const {Step} = Steps
    const [formData, setFormData] = useState({})

    const {form, current, gotoStep, stepsProps, formProps, submit, formLoading} = useStepsForm({
        isBackValidate: false,
        async submit(values) {
            const _addBusiness = values._addBusiness;
            delete values._addBusiness;
            setFormData(values)
            await _addBusiness(values);
            return values
        }
    })
    const formList = [
        <BusinessInfo
            form={form}
            gotoStep={gotoStep}
            current={location.state ? location.state.current :current}
            formLoading={formLoading}
            submit={submit}
            formData={formData}
        />,
        <VerifyBusiness
            form={form}
            gotoStep={gotoStep}
            current={location.state ? location.state.current :current}
            formLoading={formLoading}
            submit={submit}
            formData={formData}
        />,
        <BusinessFinished/>
    ]
    useEffect(()=> {
        if(location.state){
            gotoStep(location.state.current)
        }
    },[location.state])
    return (
        <div className={styles['container']}>
            <h2 style={{fontSize: 30, height: 50}}>Tạo cửa hàng</h2>
            <div className={styles['title']}>
                <div>
                    <h1 style={{marginTop: 20}}>
                        Để tạo cửa hàng thành công vui lòng hoàn thành 2 bước sau:
                    </h1>
                    <p style={{marginLeft: 20}}>1. Thông tin cửa hàng</p>
                    <p style={{marginLeft: 20}}>2. Xác thực thông tin </p>
                </div>

                <div className={styles['heading-right']}>
                    <p>- Ứng với mỗi tài khoản Ekata chỉ có thể tạo được 1 cửa hàng</p>
                    <p>
                        Dữ liệu của cửa hàng nếu chưa được hoàn thành xác thực thông tin thì chỉ có thể tồn tại
                        tối đa 60 ngày (kể từ ngày ghi nhận giao dịch cuối cùng của cửa hàng đó)
                    </p>
                </div>
            </div>

            <Steps type="navigation" {...stepsProps}>
                <Step disabled={true} title="Đăng ký cửa hàng"/>
                <Step disabled={true} title="Xác thực cửa hàng"/>
                <Step disabled={true} title="Trạng thái xác thực"/>
            </Steps>
            <div>
                {location.state && location.state.current > current ? formList[location.state.current] : formList[current]}
            </div>
        </div>
    )
}

export default RegisterBusiness
