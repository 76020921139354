import React from 'react'
import { Provider, useDispatch } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
//components
import Views from 'views'
import Loading from 'components/loading/loading'
import { persistor, store } from './redux/store'
import SocketClient from 'socketClient'
import { useEffect } from 'react'
import {io} from 'socket.io-client'

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const socket = io(process.env.REACT_APP_ECOM_SOCKET);
    dispatch({type: 'SOCKET', payload: socket})
    return () => socket.close()
  },[dispatch])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SocketClient />
        <Loading />
        <Views />
      </PersistGate>
    </Provider>
  )
}

export default App
