import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styles from './point.module.scss'
import removeUnicode from 'utils/removeUnicode'
import { Row, Table, Input, Affix, Space, Switch, Icon, notification, message } from 'antd'

import { SearchOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import defaultLogo from 'assets/img/store-default.png'
import { formatCash } from 'utils'
//apis
import { getPointBusiness, getPointOrder, autoSyncPoint } from 'apis/point'

//component
import Chart from './Chart'
import { useSelector } from 'react-redux'
import TitlePage from 'components/title-page'

export default function Point() {
  const [tableLoading, setTableLoading] = useState(false)
  const [tableOrderLoading, setTableOrderLoading] = useState(false)
  const [pointInBusiness, setPointInBusiness] = useState([])
  const [pointInOrder, setPointInOrder] = useState([])

  const dataUser = useSelector((state) => state.userInfo)
  const [inputText, setInputText] = useState('')
  let onSearch = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }
  const filteredData =
    pointInBusiness.length > 0 &&
    pointInBusiness.filter((e) => {
      if (inputText === '') {
        return e
      } else {
        return removeUnicode(e.business_name.toLowerCase()).includes(inputText)
      }
    })

  const _getPointInBusiness = async () => {
    try {
      setTableLoading(true)
      const res = await getPointBusiness()
      if (res.status === 200) {
        setPointInBusiness(res.data.data)
      }
      setTableLoading(false)
    } catch (e) {
      setTableLoading(false)
      console.log(e)
    }
  }
  const _getPointInOrder = async () => {
    try {
      setTableOrderLoading(true)
      const res = await getPointOrder()
      if (res.status === 200) {
        setPointInOrder(res.data.data)
      }
      setTableOrderLoading(false)
    } catch (error) {
      setTableOrderLoading(false)
      console.log(error)
    }
  }
  const _getDetailPoint = (pointBusiness, pointOrder) => {
    const objTmp = { ...pointBusiness }
    let arrayPoint = pointOrder.filter((point) => point.business_id === objTmp.business_id)
    objTmp['listPoints'] = [...arrayPoint]
    return objTmp
  }
  const columnSpending = [
    {
      render: (text, record) => (
        <img
          src={record.logo ? record.logo : defaultLogo}
          className={styles['business-logo']}
          alt=""
        />
      ),
      width: '10%',
    },
    {
      render: (text, record) => record.point > 0 ? (
        <>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Tích điểm tại {record.business_name} - {record.branch}
            </p>
            <p>{moment(record.time).format('DD/MM/YYYY HH:mm')}</p>
          </div>
        </>
      ) : (
        <>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Dùng điểm tại {record.business_name} - {record.branch}
            </p>
            <p>{moment(record.time).format('DD/MM/YYYY HH:mm')}</p>
          </div>
        </>
      ),   
      width: '60%',
    },
    {
      render: (text, record) =>
        record.point > 0 ? (
          <>
            <p style={{ textAlign: 'end' }}>+{formatCash(record.point)}</p>
            <p style={{ color: 'green', textAlign: 'end' }}>Thành công</p>
          </>
        ) : (
          <>
            <p style={{ textAlign: 'end' }}>{formatCash(record.point)}</p>
            <p style={{ color: 'green', textAlign: 'end' }}>Thành công</p>
          </>
        ),
      width: '30%',
    },
  ]
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: '5%',
      align:'center',
    },
    {
      title: 'Tên cửa hàng',
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <img
            src={record.logo ? record.logo : defaultLogo}
            className={styles['business-logo']}
            alt=""
          />
          <Link
            to={{pathname: `/${record.prefix}`,state: {business_id: record.business_id}}}
          >
            <h3 style={{ color: '#0069d5' }}>{record.business_name}</h3>
          </Link>
        </div>
      ),
    },
    {
      title: 'Chính sách',
      render: (record) => (
        <Link
          to={{
            pathname: '/detail-point',
            state: { point: _getDetailPoint(record, pointInOrder) },
          }}
        >
          <h3 style={{ color: '#0069d5' }}>Chi tiết</h3>
        </Link>
      ),
      width:'10%',
      align: 'center',
    },
    {
      title: 'Tổng chi tiêu',
      render: (text, record) => (
        <div
          style={{ width: '100%', fontWeight: 'bold' }}
          onClick={() => _getDetailPoint(record, pointInOrder)}
        >
          {formatCash(record?.order_total_cost)}
        </div>
      ),
      width:'15%',
      align: 'center',
    },
    {
      title: 'Tổng điểm',
      render: (text, record) => (
        <div style={{ width: '100%', fontWeight: 'bold' }}>
          {formatCash(record?.point + record?.used_point) || 0}
        </div>
      ),
      width:'10%',
      align: 'center',
    },
    {
      title: 'Điểm đã dùng',
      render: (text, record) => (
        <div style={{ width: '100%', fontWeight: 'bold' }}>
          {formatCash(record?.used_point || 0)}
        </div>
      ),
      width:'12%',
      align: 'center',
    },
    {
      title: 'Điểm còn lại',
      render: (text, record) => (
        <div style={{ width: '100%', fontWeight: 'bold' }}>{formatCash(record?.point || 0)}</div>
      ),
      width: '10%',
      align: 'center',
    },
    {
      title: 'Cập nhật lúc',
      render: (text, record) => moment(record.last_updated).format('YYYY/MM/DD HH:mm:ss'),
      width: '15%',
      align: 'center',
    },
  ]
  const _changeAutoSync = async (value) => {
    try {
      let body = {
        phone: dataUser.phone,
        auto_sync: value
      }
      let res = await autoSyncPoint(body)
      if(res.status === 200){
        if(res.data.success){
          notification.success({message: res.data.message || 'Cập nhật đồng bộ thành công'})
        }else{
          notification.error({message: res.data.message || 'Cập nhật đồng bộ không thành công'})
        }
      }else{
        notification.error({message: 'Cập nhật đồng bộ không thành công'})
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    _getPointInOrder()
    _getPointInBusiness()
  }, [dataUser])
  return (
    <div>
      <TitlePage title="Kho tích điểm" isAffix styles={{ margin: '5px 55px 5px 0px' }}>
        <Space>
          <span className={styles['title-auto-sync']}>Tự động đồng bộ</span>
          <Switch checked={dataUser.auto_sync} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={_changeAutoSync}/>
        </Space>
      </TitlePage>

      <div className={styles['tablePoint']}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
          <div className={styles['title']}>Điểm tại cửa hàng</div>
          <Input
            style={{ height: '42px', width: '31%' }}
            allowClear
            suffix={<SearchOutlined style={{ fontSize: 20 }} />}
            placeholder="Tìm kiếm theo cửa hàng"
            onChange={onSearch}
            value={inputText}
            bordered={true}
          />
        </div>
        <Table
          loading={tableLoading}
          size="small"
          columns={columns}
          dataSource={filteredData}
          style={{ width: '100%' }}
          pagination={false}
          scroll={{ y: 400 }}
        />
      </div>
      <div className={styles['container']}>
        <div className={styles['chart']}>
          <div className={styles['title']}>Phân tích nhu cầu</div>
          <div style={{ height: 280, marginTop: 20 }}>
            <Chart />
          </div>
        </div>
        <div className={styles['spending']}>
          <div className={styles['title']}>Giao dịch gần đây</div>
          <div>
            <Table
              loading={tableOrderLoading}
              size="small"
              columns={columnSpending}
              dataSource={pointInOrder}
              style={{ width: '95%', marginLeft: 20 }}
              pagination={false}
              scroll={{ y: 260 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
