import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styles from '../style.module.scss'
import MessageIcon from 'assets/icons/message.png'

import { TeamOutlined, UserSwitchOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'

export default function Tooltip({ id, avatar, first_name = '', last_name = '',name, prefix,people = 0 }) {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <div className={styles['tooltip']}>
      <div className={styles['tooltip__top']}>
        <div className={styles['tooltip__top-left']}>
          <Link style={{ display: 'flex', alignItems: 'center' }} to={{ pathname: people === 1 ? `/info-user/${id}` : `/${prefix}`,state: {business_id: id}}}>
            <img src={avatar} className={styles['tooltip__top-logo']} alt="" />
          </Link>
        </div>
        <div className={styles['tooltip__top-right']}>
          <Link style={{ display: 'flex', alignItems: 'center' }} to={{ pathname: people === 1 ? `/info-user/${id}` : `/${prefix}`,state: {business_id: id}}}>
            <div className={styles['tooltip__top-title']}>{name}</div>
          </Link>
          <div className={styles['tooltip__frendy']}>
            <UserSwitchOutlined />
            <span style={{ fontSize: 12, marginLeft: 5 }}></span>
          </div>
          <div className={styles['tooltip__frendy']}>
            <TeamOutlined />
            <span style={{ fontSize: 12, marginLeft: 5 }}></span>
          </div>
        </div>
      </div>
      {people === 1 ? (
        <div className={styles['tooltip__footer']}>
          <Button className={styles['tooltip__button']}>
            <UserAddOutlined />
            Add Friends
          </Button>
          <Button
            className={styles['tooltip__button']}
            onClick={() => {
              let item = {
                user_id: id,
                first_name: first_name,
                last_name: last_name,
                avatar: avatar
              }
              dispatch({type: 'ADD_USER', payload: {...item, text: '', media: []}})
              history.push(`/conversation/${id}`)
            }}
          >
            <img style={{ marginRight: 10 }} width={20} height={20} src={MessageIcon} alt="" />
            Message
          </Button>
          <Button style={{ fontWeight: 700 }}>...</Button>
        </div>
      ) : null}
    </div>
  )
}
