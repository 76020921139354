import React from 'react'
import { Button, Col, Divider, Image, InputNumber, Popconfirm, Row, Space, Tag } from 'antd'
import { formatCash } from 'utils'
import styles from './item.module.scss'
import { DeleteOutlined } from '@ant-design/icons'

const CartItem = ({ carts, setCarts }) => {
  const handleChangeQuantity = (quantity, index) => {
    let newCarts = [...carts]
    newCarts[index].quantity = quantity
    setCarts(newCarts)
  }

  const handleDeleteProduct = (index) => {
    let newCarts = [...carts]
    newCarts.splice(index, 1)
    setCarts(newCarts)
  }

  return (
    <div className={styles['item-cart']}>
      {carts?.map((item, index) => {
        return (
          <div>
            <Row align="middle" justify="left" gutter={[12, 0]} key={index}>
              <Col span={4}>
                <Image style={{ borderRadius: 10 }} height={75} width={75} src={item?.image[0]} />
              </Col>
              <Col span={10}>
                <div>
                  <strong>Sản phẩm:</strong> {item.title}
                  <span></span>
                </div>
                <div style={{ fontSize: 11 }}>Mã sản phẩm: {item.sku}</div>
                <div style={{ marginTop: 10 }}>
                  <Tag style={{ width: 150, borderColor: '#1e4db8' }} color="blue">
                    Giá bán: {formatCash(item.price)}đ
                  </Tag>
                </div>
              </Col>
              <Col span={8}>
                <div>Tổng tiền: {formatCash(item.quantity * item.price)}đ </div>
                <div>Số lượng:</div>
                <div>
                  <InputNumber
                    onChange={(quantity) => handleChangeQuantity(quantity, index)}
                    min={1}
                    value={item.quantity}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col span={2}>
                <Popconfirm
                  placement="topLeft"
                  title="Xoá sản phẩm này khỏi giỏ hàng?"
                  onConfirm={() => handleDeleteProduct(index)}
                  okText="Đồng ý"
                  cancelText="Huỷ"
                >
                  <Button style={{ borderColor: 'red', color: 'red' }} icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            </Row>
            <Divider style={{ margin: 12 }} />
          </div>
        )
      })}
    </div>
  )
}

export default CartItem
