import React ,{ useState, useEffect } from 'react'
import styles from './voucher.module.scss'
import { Layout, Tabs } from 'antd'
import TotalVoucher from './tab-voucher/total-voucher'
import MyVoucher from './tab-voucher/my-voucher';
const { Content } = Layout;


export default function Voucher()  {
  const { TabPane } = Tabs
  const [tabs, setTabs] = useState(1)
  return (
    <Layout>
    <Content
        id='layout-content-voucher'
        style={{
          padding: '0 20px',
          paddingBottom: '10px',
          margin: '10px',
          backgroundColor: 'white',
        }}
    >
    <div>
        <Tabs onTabClick={(e) => setTabs(e)} style={{alignItems: 'center'}} defaultActiveKey="1">
            <TabPane tab='Tất cả' key='1'>
                <TotalVoucher tabs={tabs} />
            </TabPane>
            <TabPane tab='Voucher của bạn' key='2'>
                <MyVoucher tabs={tabs}/>
            </TabPane>            
        </Tabs>
    </div>
    </Content>
  </Layout>

  )
}