import styled from 'styled-components'

export const Table = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const TableHeader = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #535353;
  flex-direction: column;
  padding: 15px;
`

export const TableBody = styled.div`
  background-color: white;
  padding: 15px 20px;
  border-radius: 20px;
  box-shadow: 0 0px 5px 0px #ededed;
`

export const TableItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px dashed #e1e1e1;
  padding-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const ShopInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
`

export const ShopName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1e4db7;
`
export const TotalSpending = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1e4db7;
  width: 25%;
  text-align: end;
`
export const TotalPoint = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1e4db7;
  width: 25%;
  text-align: end;
`

export const ShopBranch = styled.div`
  font-size: 14px;
  text-transform: lowercase;
`
export const ActionTime = styled.div`
  font-size: 12px;
  text-transform: lowercase;
`
