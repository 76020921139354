import { Badge, Button, Dropdown, Modal, notification, Popconfirm, Select, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import styles from '../styles/modal-status.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import PublicIcon from 'assets/icons/public.png'
import PrivateIcon from 'assets/icons/private.png'
import EmotionIcon from 'assets/icons/emotion.png'
import MediaIcon from 'assets/icons/media.png'
import EditIcon from 'assets/icons/edit-icon.png'
import styles2 from '../styles/post.module.scss'

import { useDispatch, useSelector } from 'react-redux'
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import { imageShow, MenuIcon, videoShow } from '../items/items'
import { editPostUser } from 'redux/actions/postsUser'

const EditPost = ({ data, setVisibleMenuEdit }) => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(data.status)
  const [title, setTitle] = useState(data.title)
  const [images, setImages] = useState(data.images)
  let dataUser = useSelector((state) => state.userInfo)
  const dispatch = useDispatch()

  const handleChangeImages = (e) => {
    const files = [...e.target.files]
    let newImages = []

    files.forEach((file) => {
      if (!file) return notification.error({ message: 'File không tồn tại' })

      if (file.size > 1024 * 1024 * 5) {
        return notification.error({ message: 'The image/video largest is 5mb.' })
      }
      return newImages.push(file)
    })
    if(images.length > 0) {
      newImages = newImages.concat(images)
  }
    setImages([...newImages])
  }
  
  const deleteImages = (index) => {
    let newArr = [...images]
    newArr.splice(index, 1)
    setImages(newArr)
  }

  const handleEditPost = (e) => {
    e.preventDefault()
    dispatch(editPostUser({title, images, status, old_data: data}))
    setVisible(false)
  }

  if (!data) return <></>
  return (
    <>
      <div
        onClick={() => {
          setVisibleMenuEdit(false)
          setVisible(true)
        }}
        className={styles2['item-edit']}
      >
        <span>
          <img
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
            alt=""
            src={EditIcon}
          />{' '}
          Chỉnh sửa
        </span>
      </div>
      <Modal
        width={750}
        visible={visible}
        footer={null}
        closable={false}
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={styles['title']}>
              <span>Chỉnh sửa bài viết</span>
            </div>
            <Popconfirm
              placement="bottom"
              title="Huỷ bỏ chỉnh sửa"
              description="Are you sure to delete this task?"
              onConfirm={() => {
                setVisible(false)
                setTitle(data.title)
                setImages(data.images)
                setStatus(data.status)
              }}
              okText="Huỷ bỏ chỉnh sửa"
              cancelText="Tiếp tục chỉnh sửa"
            >
              <div
                style={{ cursor: 'pointer' }}
              >
                <CloseCircleFilled style={{ fontSize: 32 }} />
              </div>
            </Popconfirm>
          </div>
        }
      >
        <div className={styles['header']}>
          <img
            style={{ width: '55px', height: '55px' }}
            alt=""
            src={dataUser?.avatar || AvatarDefault}
          />
          <div className={styles['information']}>
            <span>
              {dataUser?.first_name} {dataUser?.last_name}
            </span>
            <Select
              onChange={(e) => {
                setStatus(e)
              }}
              value={status}
              defaultValue="public"
              style={{ width: '140px' }}
            >
              <Select.Option value="public">
                <span
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '18px', height: '18px', marginRight: '3px' }}
                    alt=""
                    src={PublicIcon}
                  />
                  Công khai
                </span>
              </Select.Option>
              <Select.Option value="private">
                <span
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '18px', height: '18px', marginRight: '3px' }}
                    alt=""
                    src={PrivateIcon}
                  />
                  Chỉ mình tôi
                </span>
              </Select.Option>
            </Select>
          </div>
        </div>

        <div className={styles['body']}>
          <div className={styles['body-content']}>
            <textarea
              name="title"
              value={title}
              placeholder={`${dataUser.last_name} ơi, tình hình mua sắm của bạn thế nào rồi?`}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
          </div>
          {images?.length > 0 && (
            <div className={styles['body-media']}>
              {images?.map((img, index) => (
                <Badge
                  count={
                    <CloseCircleFilled
                      onClick={() => {
                        deleteImages(index)
                      }}
                      style={{ fontSize: 18, color: '#313131' }}
                    />
                  }
                >
                  <div key={index} id="file_img">
                    {img.camera ? (
                      imageShow(img.camera)
                    ) : img.url ? (
                      <>{img.url.match(/video/i) ? videoShow(img.url) : imageShow(img.url)}</>
                    ) : (
                      <>
                        {img.type.match(/video/i)
                          ? videoShow(URL.createObjectURL(img))
                          : imageShow(URL.createObjectURL(img))}
                      </>
                    )}
                  </div>
                </Badge>
              ))}
            </div>
          )}
          <div className={styles['body-footer']}>
            <span>Thêm vào bài viết của bạn</span>
            <div>
              <label style={{ cursor: 'pointer', marginRight: '16px' }}>
                <Tooltip placement="top" title="Thêm ảnh/video">
                  <img alt="" src={MediaIcon} />
                </Tooltip>

                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="image/*,video/*"
                  multiple
                  onChange={handleChangeImages}
                  style={{ display: 'none' }}
                />
              </label>
              <Dropdown
                placement="top"
                trigger="click"
                overlay={<MenuIcon data={title} setData={setTitle} />}
              >
                <Tooltip placement="top" title="Biểu tượng cảm xúc">
                  <img style={{ cursor: 'pointer' }} alt="" src={EmotionIcon} />
                </Tooltip>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className={styles['footer']}>
          <Button
            onClick={handleEditPost}
            disabled={images.length === 0 && title.length === 0 && true}
            style={{
              width: '100%',
              padding: 0,
              backgroundColor: images.length === 0 && title.length === 0 ? '#d9d9d9' : '#1e4db7',
            }}
          >
            <span className={styles['footer-span']}>Lưu</span>
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default EditPost
