import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'

import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  ShopInfo,
  ShopAvatar,
  ShopName,
  TotalSpending,
  TotalPoint,
  ShopBranch,
  ActionTime,
} from './styled.components'
import { getPointOrder } from 'apis/point'
import { formatCash } from 'utils'
import emptyPointIcon from './icons/empty.png'

function BusinessPointUsedTable() {
  const [pointInOrder, setPointInOrder] = useState([])

  const _getPointInOrder = async () => {
    try {
      const res = await getPointOrder()
      if (res.status === 200) {
        setPointInOrder(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getPointInOrder()
  }, [])

  return (
    <>
      <Table>
        <TableHeader>Giao dịch gần đây</TableHeader>
        <TableBody>
          {pointInOrder.length == 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={emptyPointIcon} />
              <p>Chưa có hoạt động nào. Tiếp tục mua sắm để tích điểm nhé</p>
            </div>
          )}

          {pointInOrder.length > 0 &&
            pointInOrder.map((business) => (
              <TableItem>
                <ShopInfo>
                  <ShopName>{business.business_name}</ShopName>
                  <ShopBranch>{business.branch}</ShopBranch>
                  <ActionTime>{moment(business.time).format('DD/MM/YYYY HH:mm')}</ActionTime>
                </ShopInfo>
                <TotalSpending style={{ color: business.point > 0 ? 'mediumseagreen' : 'tomato' }}>
                  {business.point > 0 ? 'Tích điểm' : 'Dùng điểm'}
                </TotalSpending>
                <TotalPoint>
                  {(business.point > 0 ? '+' : '') + formatCash(business?.point)}
                </TotalPoint>
              </TableItem>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

export default memo(BusinessPointUsedTable)
