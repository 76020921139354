import jwt_decode from 'jwt-decode'
import { Link, useHistory } from 'react-router-dom'
import styles from './infor.module.scss'
import moment from 'moment'
import ShoppingHistory from 'components/newfeed/shopping-history'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Avatar, Tabs } from 'antd'
import { Row, Col } from 'antd'

//custom icons
import MessageIcon from 'assets/icons/message.svg'
import IdIcon from 'assets/icons/id.svg'
import PhoneIcon from 'assets/icons/phone.svg'
import EarthIcon from 'assets/icons/earth.svg'
import TimeIcon from 'assets/icons/time.svg'
import NewsIcon from 'assets/icons/news.svg'
import SettingIcon from 'assets/icons/setting.svg'
import LocationIcon from 'assets/icons/location.png'
import { ROUTES } from 'consts'
import DefaultLogo from 'assets/img/store-default.png'
import NewFeeds from 'components/newfeed/feeds'
import Tab from 'components/newfeed/tab'

const TabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    border-bottom: 1px solid #6c757d;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav-list > .ant-tabs-tab {
    width: 50%;
    margin: 0;
    justify-content: center;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #91919f;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1e4db7;
  }
`

const tabPaneData = [
  {
    key: 1,
    content: NewFeeds,
    tab: () => (
      <Tab classes={styles.tabpane} text="Bản tin" icon={() => <img src={NewsIcon} alt="" />} />
    ),
  },
  {
    key: 2,
    content: ShoppingHistory,
    tab: () => (
      <Tab
        classes={styles.tabpane}
        text="Lịch sử mua sắm"
        icon={() => <img src={TimeIcon} alt="" />}
      />
    ),
  },
]

export default function Infor() {
  const history = useHistory()
  let user = useSelector((state) => state.userInfo)

  const { first_name, last_name, phone,bio,email,date_of_birth,avatar,web,gender,status_bio,status_email,status_gender,status_web,status_date_of_birth,address, status_address} = user
  const clickHandle = () => history.push({ pathname: ROUTES.SETTING_INFOR})

  return (
    <div className={styles['container-layout']}>
      <div className={styles['dashboard_manager_bottom_row_col_parent_top']}>
        <div className={styles['font-bold']}>Thông tin cá nhân</div>
        <div className={styles['container-icon-setting']}>
          <span className={styles['icon-setting']} onClick={clickHandle}>
            <img src={SettingIcon} alt="" style={{ color: '#5B5B5B' }} />
          </span>
        </div>
      </div>
      <div>
        <div className={styles['container-account']}>
          <div className={styles['container-avt']}>
            <Avatar 
            size={104} src={avatar || DefaultLogo} 
            />
          </div>
          <div className={styles['container-infor']}>
            <div className={styles['display-flex']}>
              <div className={styles['infor-name']}>
                <p>
                  {last_name} {first_name}
                </p>
              </div>
              <div className={styles['container-icon-phone']}>
                <img src={PhoneIcon} alt="" style={{ color: '#39CB7F', fontSize: 12 }} />
              </div>
              <div className={styles['container-icon-id']}>
                <img src={IdIcon} alt="" />
                {/* <span style={{ color: '#91919F' }}>id</span> */}
              </div>
            </div>

            <div className={styles['container-status']}>
            {status_bio === 1 ?(<p> {bio || 'Cuộc đời là một chuỗi ngày cần khám phá và trải nghiệm'}</p>):(<p>Cuộc đời là một chuỗi ngày cần khám phá và trải nghiệm</p>)}
            </div>
            <div className={styles['container-flex']}>
              {status_date_of_birth === 1?(<div className={styles['container-item']}>
                {moment(date_of_birth).format('DD/MM/YYYY')}
              </div>):null }
              
              <div className={styles['container-item']}>SĐT: {phone}</div>
              {status_gender === 1 ? (<div className={styles['container-item']}
                title="fake data vì user trong db chưa có gender field"
              >
                {gender === 0 ? 'Nam' : gender === 1 ? 'Nữ' : 'Khác'}
              </div>):null}
              
            </div>
            <div className={styles['container-flex']}>
              {status_email === 1 ? (<div className={styles['container-item']}>
                <img src={MessageIcon} alt="" />
                <span>{email || 'JohnDoe@email.com.vn'}</span>
              </div>):null}
              
              {status_web === 1 ? (<div
                className={styles['container-item']}
              >
                <img src={EarthIcon} alt="" />
                <span>{web || 'ekata.com.vn'}</span>
              </div>):null}

              {status_address=== 1 ? (<div
                className={styles['container-item']}
              >
                <img src={LocationIcon} alt="" />
                <span>{address || 'Đà Nẵng'}</span>
              </div>):null}
              
            </div>
            <div className={styles['container-dh']}>
              <div className={styles['center']}>
                <h1 className={styles['dh-number']}>180k</h1>
                <p className={styles['dh-content']}>lượt theo dõi</p>
              </div>
              <div className={styles['center']}>
                <h1 className={styles['dh-number']}>10</h1>
                <p className={styles['dh-content']}>đang theo dõi</p>
              </div>
              <div className={styles['center']}>
                <h1 className={styles['dh-number']}>6,2k</h1>
                <p className={styles['dh-content']}>lượt mua sắm</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['container-fe-hi']}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <div className={styles['card-overview']}>
              <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <TabsStyled centered style={{ background: '#F5F5F5' }}>
                  {tabPaneData.map((item) => {
                    const { key, tab: Tab, content: Content } = item

                    return (
                      <Tabs.TabPane key={key} tab={<Tab />}>
                        <Content />
                      </Tabs.TabPane>
                    )
                  })}
                </TabsStyled>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
