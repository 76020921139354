import styles from './button.module.scss'

function ButtonCustom({ icon: Icon, text, classes, style, functionOnClick }) {
  return (
    <button onClick={functionOnClick} className={`${styles.btn} ${styles[classes]}`} style={style}>
      {Icon && <Icon />}
      {text}
    </button>
  )
}

export { ButtonCustom }
