import React, { useState } from 'react'
import styles from '../notification.module.scss'
import moment from 'moment'
import NotificationMenuItem from '../notification-menu-item'
import { Button, Space } from 'antd'

export const Staff = ({item, onReplyNotification}) => {
    return (
          <div
            className={
              item.status != 0
                ? styles['notifications__item']
                : styles['notifications__item__seen']
            }
          >
            <div className={styles['notifications__item__avatar']}>
              <img alt='' src={(item?.business?.business_logo.length > 0 ?  item?.business?.business_logo : item?.business?.business_registration_image) || ''} />
            </div>

            <div className={styles['notifications__item__content']}>
              <span className={styles['notifications__item__message']}>
                <strong>Cửa hàng {item.business.business_name}</strong> mời bạn tham gia cửa hàng
                với chức năng {item.role.name} tại chi nhánh <strong>{item.branch.name}</strong>
              </span>
              <div>
                <small>{moment(item.create_date).fromNow()}</small>
              </div>

              {item.status != -2 ? (
                item.status == -1 ? (
                  <span>Đã từ chối</span>
                ) : item.status == 0 ? (
                  <Space>
                    <Button
                      onClick={() =>
                        onReplyNotification(
                          1,
                          item.notification_staff_id,
                          item.phone,
                          item.branch.branch_id
                        )
                      }
                      type="primary"
                      size="small"
                      style={{ width: '100px' }}
                    >
                      Xác nhận
                    </Button>
                    <Button
                      onClick={() =>
                        onReplyNotification(
                          -1,
                          item.notification_staff_id,
                          item.phone,
                          item.branch.branch_id
                        )
                      }
                      style={{ width: '100px' }}
                      size="small"
                    >
                      Từ chối
                    </Button>
                  </Space>
                ) : (
                  <span>Đã xác nhận</span>
                )
              ) : (
                <span>Lời mời bị thu hồi</span>
              )}
            </div>

            <div className={styles['notifications__item__button']}>
              <NotificationMenuItem notification_staff_id={item.notification_staff_id} />
            </div>
          </div>

    )
}