import React, { useRef, useState } from 'react'
import styles from './styles/carousel.module.scss'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel } from 'antd'
import BgTest from '../../assets/img/bg-test.png'

const layoutCarousel = {
  position: 'relative',
  height: '596px',
  width: '456px',
}
const contentStyle = {
  width: '456px',
  height: '560px',
  color: '#fff',
  textAlign: 'center',
}
const buttonPrevNext = {
  color: 'white',
  fontSize: '10px',
  cursor: 'pointer',
}
const textStyle = {}

const slides = [
  {
    img: BgTest,
    title: 'Miễn phí và luôn luôn như vậy',
  },
  {
    img: BgTest,
    title: 'Miễn phí và luôn luôn như vậy',
  },
  {
    img: BgTest,
    title: 'Miễn phí và luôn luôn như vậy',
  },
  {
    img: BgTest,
    title: 'Miễn phí và luôn luôn như vậy',
  },
]

const CarouselCustom = () => {
  const carousel = useRef()

  const handleNext = () => carousel.current.next()

  const handlePrev = () => carousel.current.prev()

  return (
    <div style={layoutCarousel}>
      <div onClick={handlePrev} className={styles['button-prev']}>
        <LeftOutlined style={buttonPrevNext} />
      </div>

      <div style={{ height: '596px', width: '456px' }}>
        <Carousel className={styles['carousel-styled']} ref={carousel}>
          {slides.map((item, index) => (
              <div key={index} style={contentStyle}>
                <div>
                  <img alt="" style={{ width: '100%' }} src={item.img} />
                  <p>{item.title}</p>
                </div>
              </div>
          ))}
        </Carousel>
      </div>

      <div onClick={handleNext} className={styles['button-next']}>
        <RightOutlined style={buttonPrevNext} />
      </div>
    </div>
  )
}

export default CarouselCustom
