import React from 'react'
import styles from './change-password.module.scss'
import ButtonCustom from 'components/Button/buttonCustom'
import CarouselCustom from 'components/carousel/carouselCustom'
import { ConfigProvider, Form, Input, notification } from 'antd'
import BgAuth from '../../../assets/img/bg-auth.png'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import delay from 'delay'
import { changePassword } from 'apis/user'
import { ROUTES } from 'consts'

const ChangePassword = () => {
  const [formChangePassword] = Form.useForm()
  const location = useLocation()
  let history = useHistory()
  var phone = location.state && location.state.phone

  const _changePassword = async (dataForm) => {
    try {
      const regexPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()?])[A-Za-z\d!@#$%^&*()?]{8,}$/

      if (!regexPassword.test(dataForm.password)) {
        formChangePassword.setFields([
          {
            name: 'password',
            errors: [
              'Mật khẩu không được chứa dấu và tối thiểu 8 ký tự, ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt',
            ],
          },
        ])
        return
      }
      if (dataForm.password !== dataForm.password_confirmation) {
        formChangePassword.setFields([
          {
            name: 'password_confirmation',
            errors: ['Nhập lại mật khẩu không chính xác'],
          },
        ])
        return
      }
      const body = { ...dataForm, phone: phone }
      const res = await changePassword(body)
      if (res.status === 200 && res.data.status) {
        notification.success({ message: 'Thay đổi mật khẩu thành công' })
        await delay(300)
        history.push({ pathname: ROUTES.HOME_PAGE })
      } else {
        formChangePassword.setFields([
          {
            name: 'password',
            errors: [
              res.data.message,
            ],
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
          colorTextPlaceholder: '#667085',
          colorPrimary: '#335BD3',
        },
      }}
    >
      <div className={styles['layout-change-password']}>
        <div style={{ background: `url(${BgAuth})` }} className={styles['layout-carousel']}>
          <CarouselCustom />
        </div>
        <div className={styles['layout-change-password-form']}>
          <div className={styles['form-change-password-responsive']}>
            <p className={styles['title']}>Tạo mật khẩu mới</p>
            <p className={styles['note']}>Mật khẩu mới của bạn phải khác với trước đó.</p>

            <Form
              requiredMark={false}
              form={formChangePassword}
              onFinish={_changePassword}
              layout="vertical"
              style={{ width: '100%' }}
            >
              <Form.Item
                label={<p className={styles['label-style']}>Mật khẩu</p>}
                name="password"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                style={{ marginBottom: '16px' }}
              >
                <Input.Password
                  className={styles['input-item']}
                  size="large"
                  type="password"
                  placeholder="Nhập mật khẩu"
                />
              </Form.Item>

              <Form.Item
                label={<p className={styles['label-style']}>Xác nhận mật khẩu</p>}
                name="password_confirmation"
                rules={[{ required: true, message: 'Vui lòng nhập xác nhận mật khẩu!' }]}
              >
                <Input.Password
                  className={styles['input-item']}
                  size="large"
                  type="password"
                  placeholder="Nhập mật khẩu xác nhận"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: '32px' }}>
                <ButtonCustom text={'Xác nhận'} />
              </Form.Item>
            </Form>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span className={styles['link']}>
                Bạn đã có tài khoản?
                <Link to="/login">
                  <span className={styles['link-1']}> Đăng nhập</span>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default ChangePassword
