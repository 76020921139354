import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Input, Modal, Rate, Row } from 'antd'
import { ButtonCustom } from 'components/Button/Button'
import React, { useState } from 'react'
import styled from 'styled-components'

const RateStyled = styled(Rate)`
  color: #fcac12;
  font-size: 14px;

  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
`

const styleSpan = (
    {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#8c8c8c',
    }
)
export const DialogVote = () => {
  const [open, setOpen] = useState(false)
  const openModal = () => {
    setOpen(!open)
  }

  return (
    <>
      <ButtonCustom classes="btn__yellow" text="Đánh giá" functionOnClick={openModal} />

      <Modal title={null} centered visible={open} width={700} closable={false} footer={null}>
        <Row style={{marginBottom: 20}} justify="space-between">
          <Col>
            <h2>Đánh giá đơn hàng</h2>
          </Col>
          <Col>
            <CloseOutlined style={{ fontSize: 17, cursor: 'pointer' }} onClick={openModal} />
          </Col>
        </Row>

        <Row style={{marginBottom: 20}}>
          <Col span={4}><span style={styleSpan}>Đánh giá</span></Col>
          <Col span={14}>
            <RateStyled defaultValue={4} allowHalf />
          </Col>
        </Row>

        <span style={styleSpan}>Tiêu đề</span>
        <Input style={{width: '100%' ,marginBottom: 20, marginTop: 4}} placeholder="Tiêu đề bài đánh giá" />

        <span style={styleSpan}>Ghi chú</span>
        <Input.TextArea  style={{width: '100%' ,marginBottom: 20, marginTop: 4}} rows={7}/>

        <Row gutter={[12, 12]} justify="end">
          <Col>
            <Button onClick={() => openModal()}>Đánh giá</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
