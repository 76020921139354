import React, {cloneElement} from 'react'
import {Redirect} from 'react-router-dom'
import {ROUTES} from 'consts'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

/**
 *
 * @param title
 * @param {Object} props
 * @param {Array<String>} props.permissions
 * @param {React.ReactChildren} props.children
 */
const Authentication = ({permissions, title, children, ...props}) => {
    const accessToken = Cookies.get('accessToken');
    const payload = accessToken && jwt_decode(accessToken)

    if (!payload) return <Redirect to={ROUTES.LOGIN}/>

    //modify title
    document.title = title

    //check đã đăng nhập chưa hoặc token còn hạn -> vào trang home hú
    if (payload) return cloneElement(children, props)

    return <div/>
}

export default Authentication
