import { getNotificationStaff } from 'apis/nofitication'

export const getNotificationsSystem = () => async (dispatch) => {
  try {
    const res = await getNotificationStaff()
    if (res.status === 200) {
      dispatch({ type: 'SET_NOTIFICATION', data: res.data.data })
    }
  } catch (error) {
    console.log(error)
  }
}
