import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import NotificationStaff from './notification-staff'
import styles from './notification.module.scss'

const Notification = () => {

  return (
    <div>
      <div className={styles['notificationBox']}>
        <div className={styles['title']}>
          <div>Thông báo</div>
          <div>
            <Link to="/notification-list">Xem tất cả</Link>
          </div>
        </div>
        <NotificationStaff />
      </div>
    </div>
  )
}

export default Notification
