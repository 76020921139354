import React, { useState } from 'react'
import styles from '../notification.module.scss'
import moment from 'moment'
import NotificationMenuItem from '../notification-menu-item'
import { Button, Space } from 'antd'
import { replyNotificationStaff, getNotificationStaff } from 'apis/nofitication'
import { useDispatch, useSelector } from 'react-redux'
import { myBusiness } from 'apis/business'
import { Staff } from '../ItemNotification/staff'
import { Comment } from '../ItemNotification/comment'
import { getNotificationsSystem } from 'redux/actions/notification'


const NotificationList = ({ notification }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()


  const onReplyNotification = async (confirm, notification_staff_id, phone, branch_id) => {
    try {
      setLoading(true)
      const body = {
        is_confirm: confirm,
        notification_staff_id: notification_staff_id,
        phone: phone,
        branch_id: branch_id,
      }
      await replyNotificationStaff({ ...body })

      dispatch(getNotificationsSystem())
      const loadBusiness = await myBusiness()
      dispatch({
        type: 'SET_MY_BUSINESS',
        data: loadBusiness.data.data,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }



  return (
    notification &&
    notification.map((item) => {
      return (
        <div className={styles['wrapperList']}>
          <div className={styles['notifications']}>
            {item.notification_staff_id && <Staff item={item} onReplyNotification={onReplyNotification}/>}
            {item.notification_comment_id && <Comment item={item}/>}
          </div>
        </div>
      )
    })
  )
}
export default NotificationList
