import { Badge, Tooltip, Dropdown, Modal, Select, Upload, Button } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../styles/modal-status.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import PublicIcon from 'assets/icons/public.png'
import PrivateIcon from 'assets/icons/private.png'
import EmotionIcon from 'assets/icons/emotion.png'
import MediaIcon from 'assets/icons/media.png'
import { imageShow, MenuIcon, videoShow } from '../items/items'

const { Dragger } = Upload

const ModalStatus = (props) => {
  const {
    visible,
    setVisible,
    title,
    setTitle,
    status,
    setStatus,
    images,
    setImages,
    handleChangeImages,
    deleteImages,
    handleSubmitForm,
  } = props
  let dataUser = useSelector((state) => state.userInfo)
  return (
    <Modal
      width={750}
      visible={visible}
      onCancel={() => {
        setVisible(false)
      }}
      footer={null}
      title={
        <div className={styles['title']}>
          <span>Tạo bài viết</span>
        </div>
      }
    >
      <div className={styles['header']}>
        <img
          style={{ width: '55px', height: '55px' }}
          alt=""
          src={dataUser?.avatar || AvatarDefault}
        />
        <div className={styles['information']}>
          <span>
            {dataUser?.first_name} {dataUser?.last_name}
          </span>
          <Select onChange={(e) => {setStatus(e)}} value={status} defaultValue="public" style={{ width: '140px' }}>
            <Select.Option value="public">
              <span
                style={{
                  fontSize: '13px',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ width: '18px', height: '18px', marginRight: '3px' }}
                  alt=""
                  src={PublicIcon}
                />
                Công khai
              </span>
            </Select.Option>
            <Select.Option value="private">
              <span
                style={{
                  fontSize: '13px',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ width: '18px', height: '18px', marginRight: '3px' }}
                  alt=""
                  src={PrivateIcon}
                />
                Chỉ mình tôi
              </span>
            </Select.Option>
          </Select>
        </div>
      </div>

      <div className={styles['body']}>
        <div className={styles['body-content']}>
          <textarea
            name="title"
            value={title}
            placeholder={`${dataUser.last_name} ơi, tình hình mua sắm của bạn thế nào rồi?`}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </div>
        {images?.length > 0 && (
          <div className={styles['body-media']}>
            {images?.map((img, index) => (
              <Badge
                count={
                  <CloseCircleFilled
                    onClick={() => {
                      deleteImages(index)
                    }}
                    style={{ fontSize: 18, color: '#313131' }}
                  />
                }
              >
                <div key={index} id="file_img">
                  {img.camera ? (
                    imageShow(img.camera)
                  ) : img.url ? (
                    <>{img.url.match(/video/i) ? videoShow(img.url) : imageShow(img.url)}</>
                  ) : (
                    <>
                      {img.type.match(/video/i)
                        ? videoShow(URL.createObjectURL(img))
                        : imageShow(URL.createObjectURL(img))}
                    </>
                  )}
                </div>
              </Badge>
            ))}
          </div>
        )}
        <div className={styles['body-footer']}>
          <span>Thêm vào bài viết của bạn</span>
          <div>
            <label style={{ cursor: 'pointer', marginRight: '16px' }}>
              <Tooltip placement="top" title="Thêm ảnh/video">
                <img alt="" src={MediaIcon} />
              </Tooltip>

              <input
                type="file"
                name="file"
                id="file"
                accept="image/*,video/*"
                multiple
                onChange={handleChangeImages}
                style={{ display: 'none' }}
              />
            </label>
            <Dropdown
              placement="top"
              trigger="click"
              overlay={<MenuIcon data={title} setData={setTitle} />}
            >
              <Tooltip placement="top" title="Biểu tượng cảm xúc">
                <img style={{ cursor: 'pointer' }} alt="" src={EmotionIcon} />
              </Tooltip>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className={styles['footer']}>
        <Button 
        onClick={handleSubmitForm}
        disabled={
                (images.length === 0 && title.length === 0 && true )
              }
           style={{ width: '100%', padding: 0, backgroundColor: images.length === 0 && title.length === 0 ? '#d9d9d9' : '#1e4db7' }}>
          <span className={styles['footer-span']}>Đăng</span>
        </Button>
      </div>
    </Modal>
  )
}

export default ModalStatus
