import React, { useState } from 'react'
import styles from '../notification.module.scss'
import moment from 'moment'
import NotificationMenuItem from '../notification-menu-item'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { followUser } from 'redux/actions/profileUser'
import { getNotificationsSystem } from 'redux/actions/notification'
import { cancelRequestNotificationFollow } from 'apis/profile'

export const Follow = ({ item }) => {
  let user = useSelector((state) => state.userInfo)
  const dispatch = useDispatch()
  const { profileUser, socket } = useSelector((state) => state)

  const handleFollow =  async () => {
    try {
      dispatch(followUser({users: profileUser.users, user: item.sender_info, auth: user, socket, notifi: item}))
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelRequestFollow = async (e) => {
    try {
      e.preventDefault()
      if (!item) return
      dispatch({type: 'DELETE_NOTIFICATION', data: item})
      await cancelRequestNotificationFollow(item.notification_user_id)
      
    } catch (error) {
      console.log(error)
    }
  }

  if (item?.recipient_id !== user?.user_id) return <></>
  return (
    <div
      className={
        item.status != 0 ? styles['notifications__item'] : styles['notifications__item__seen']
      }
    >
      <div className={styles['notifications__item__avatar']}>
        <img
          alt=""
          src={
            item?.sender_info?.avatar ||
            'https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU='
          }
        />
      </div>

      <div className={styles['notifications__item__content']}>
        <span className={styles['notifications__item__message']}>
          {item?.recipient_id !== null && (
            <strong>{item?.sender_info?.first_name + ' ' + item?.sender_info?.last_name}</strong>
          )}{' '}
          {item.type == 'request-follow' &&
            `đã yêu cầu theo dõi bạn ` } {item.type == 'request-follow' && item.status == 1 && '(bạn đã xác nhận yêu cầu theo dõi)'}
          {item.type == 'confirm-follow' && 'đã xác nhận yêu cầu theo dõi'}
        </span>
        <div>
          <small>{moment(item.create_date).fromNow()}</small>
        </div>
        {item.type == 'request-follow' && item.status == 0 && (
          <>
            <Button onClick={handleFollow} type="primary" size="small" style={{ width: '49%', marginRight: '2%' }}>
              Xác nhận yêu cầu
            </Button>
            <Button onClick={handleCancelRequestFollow} style={{ width: '49%' }} size="small">
              Huỷ bỏ yêu cầu
            </Button>
          </>
        )}
      </div>

      <div className={styles['notifications__item__button']}>
        <NotificationMenuItem notification_staff_id={item.notification_staff_id} />
      </div>
    </div>
  )
}
