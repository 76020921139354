/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, Divider, Modal, notification, Row } from 'antd'
import React from 'react'
import { useState } from 'react'
import styles from './detail-voucher.module.scss'
import { MoneyCollectOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { formatCash } from 'utils'
import moment from 'moment'
import { saveVoucherUser } from 'apis/voucher'

const DetailVoucher = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [readMore, setReadMore] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const saveVoucher = async (voucher_id, value) => {
    try {
      let body = {
        voucher_id: voucher_id,
      }
      if (value === true) {
        await saveVoucherUser(body)
        notification.success({ message: 'Voucher đã được thêm vào kho voucher của bạn' })
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <a  onClick={showModal}>
        Chi tiết
      </a>

      <Modal
        title="Thông tin chi tiết"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ backgroundColor: '#f0f2f5', padding: 0 }}
        footer={
          <Row justify="end">
            <Button
              onClick={() => {
                saveVoucher()
              }}
              type="primary"
            >
              Lưu mã
            </Button>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <img
              className={styles['img-detail-voucher']}
              alt="example"
              src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
            />
            <Card bodyStyle={{ padding: '10px 17px 5px 17px' }}>
              <div style={{ fontFamily: 'Inter', color: '#061178' }}>
                <MoneyCollectOutlined /> Giảm{' '}
                {item.type === 'PERCENT' ? (
                  <span style={{ fontFamily: 'Inter', color: '#061178' }}>
                    {item.value}% tối đa {item.max_discount_value}
                  </span>
                ) : (
                  <span style={{ fontFamily: 'Inter', color: '#061178' }}>
                    {formatCash(item.value)}
                  </span>
                )}
              </div>
              <div style={{ fontFamily: 'Inter' }}>
                <strong>
                  Mã voucher {item.promotion_code} được sử dụng cho toàn bộ chi nhánh trên cửa hàng
                </strong>
              </div>
              <Divider style={{ margin: '5px 0' }} />
              {item.start_date === '' && item.end_date === '' ? (
                <p style={{ fontFamily: 'Inter', fontSize: 13, color: '#6C757D' }}>
                  Ưu đãi cửa hàng này đang được mở
                </p>
              ) : (
                <p style={{ fontFamily: 'Inter', fontSize: 13, color: '#6C757D' }}>
                  Ưu đãi từ ngày {moment(item.start_date).format('DD/MM/YYYY')} đến ngày{' '}
                  {moment(item.end_date).format('DD/MM/YYYY')}
                </p>
              )}
            </Card>
          </Col>

          <Col span={24}>
            <Card bodyStyle={{ padding: '10px 17px 5px 17px' }}>
              <div style={{ fontFamily: 'Inter', fontWeight: 'bold' }}>Thông tin voucher</div>
              <div>
                <span>
                  {item.description.length < 100
                    ? item.description
                    : readMore
                    ? item.description + ' '
                    : item.description.slice(0, 100) + '...'}
                </span>
                {item.description.length > 100 && (
                  <div
                    style={{ textAlign: 'center' }}
                    className="readMore"
                    onClick={() => setReadMore(!readMore)}
                  >
                    {readMore ? (
                      <a style={{ fontFamily: 'Inter', fontSize: 13, color: '#6C757D' }}>
                        Ẩn <UpOutlined />
                      </a>
                    ) : (
                      <a style={{ fontFamily: 'Inter', fontSize: 13, color: '#6C757D' }}>
                        Xem thêm <DownOutlined />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
export default DetailVoucher
