import { Popover } from 'antd'
import React from 'react'
import QRCode from 'qrcode.react'

const QRCart = ({ item }) => {
  return (
    <Popover
      placement="rightTop"
      content={
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <QRCode id="qrCodeEl" size={150} value="https://staging.ekata.vn/" />
          <h3 style={{marginBottom: 0}}>{item.business_name}</h3>
        </div>
      }
      trigger="click"
    >
      <span>QR giỏ hàng</span>
    </Popover>
  )
}

export default QRCart
