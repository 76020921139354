import React from "react";
import CarouselCustom from "components/carousel/carouselCustom";
import styles from "./styles/index.module.scss";
import { ConfigProvider } from "antd";
import FormRegister from "./formRegister";
import BgAuth from '../../../assets/img/bg-auth.png'

const Register = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter",
          colorTextPlaceholder: "#667085",
          colorPrimary: "#335BD3",
        },
      }}
    >
      <div className={styles["layout-register"]}>
        <div style={{background: `url(${BgAuth})`}} className={styles["layout-carousel"]}>
          <CarouselCustom />
        </div>
        <div className={styles["layout-form-register"]}>
          <FormRegister />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Register;
