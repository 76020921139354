import React from 'react'
import PinDefault from 'assets/img/pindefault.png'
import { Card } from 'antd'
import styles from './pinPosts.module.scss'

const PinPosts = () => {
  return (
    <Card
      hoverable={true}
      style={{ maxWidth: '320px', marginTop: '5px', borderRadius: 10 }}
      size="small"
    >
      <div className={styles['title']}>
        <span>
          <img alt="" src={PinDefault} />{' '}
          Bài viết đã ghim
        </span>
      </div>
      <div className={styles['body']} style={{padding: '0 5% 0 5%'}}>
        <div className={styles['body-item']}>
          <span>Sản phẩm: D1011</span>
          <span>Mã sản phẩm: D1011</span>
        </div>
        <div className={styles['body-item']}>
          <span>Sản phẩm: AQ1057</span>
          <span>Nội dung: Quần áo</span>
        </div>
        <div className={styles['body-item']}>
          <span>Thông tin tuyển dụng</span>
          <span>Ekata Tuyển dụng nhân viên thời vụ...</span>
        </div>
      </div>
    </Card>
  )
}

export default PinPosts
