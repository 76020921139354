/* eslint-disable jsx-a11y/alt-text */
import { Button, Space, Skeleton, Divider } from 'antd'
import { replyNotificationStaff, getNotificationStaff } from 'apis/nofitication'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './notification.module.scss'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationMenuItem from './notification-menu-item'
import { myBusiness } from 'apis/business'
import { Staff } from './ItemNotification/staff'
import { Comment } from './ItemNotification/comment'
import { getNotificationsSystem } from 'redux/actions/notification'
import { Follow } from './ItemNotification/follow'

const NotificationStaff = () => {
  let notificationStaff = useSelector((state) => state.notification)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [load, setLoad] = useState(false)

  const loadMoreData = () => {
    if (load) {
      return
    }

    setLoad(true)
    if (notificationStaff) {
      setLoad(false)
    }
  }

  const onReplyNotification = async (confirm, notification_staff_id, phone, branch_id) => {
    try {
      setLoading(true)
      const body = {
        is_confirm: confirm,
        notification_staff_id: notification_staff_id,
        phone: phone,
        branch_id: branch_id,
      }
      await replyNotificationStaff({ ...body })

      dispatch(getNotificationsSystem())

      const loadBusiness = await myBusiness()
      dispatch({
        type: 'SET_MY_BUSINESS',
        data: loadBusiness.data.data,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: '650px',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={notificationStaff.length}
        next={loadMoreData}
        hasMore={notificationStaff.length > 10}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={<Divider plain>Đã hết thông báo</Divider>}
        scrollableTarget="scrollableDiv"
      >
        {notificationStaff &&
          notificationStaff.map((item) => {
            return (
              <div className={styles['wrapper']}>
                <div className={styles['notifications']}>
                  {item.notification_staff_id && (
                    <Staff item={item} onReplyNotification={onReplyNotification} />
                  )}
                  {item.notification_comment_id && <Comment item={item} />}
                  {item.notification_user_id && <Follow item={item} />}
                </div>
              </div>
            )
          })}
      </InfiniteScroll>
    </div>
  )
}

export default NotificationStaff
