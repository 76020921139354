/**
 *
 * MobileHeader
 *
 */

import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  HeaderBlock,
  HeaderContainer,
  Header,
  Logo,
  HeaderTitle,
  LeftBlock,
  RightBlock,
  SearchButton,
  SearchContainer,
  SearchInput,
  NotificationBell,
  NotificationDotted,
  UserAvatar,
} from './styled.components'

import * as components from './styled.components'
import { Link, useHistory } from 'react-router-dom'

function MobileHeader({ right = false }) {
  const history = useHistory()

  const RightConponent = right ? components[right.name] : Logo
  const RightAction = right
    ? right.onClick
    : () => {
        history.push('/notification')
      }

  const userInfo = useSelector((state) => state.userInfo)

  const [displaySearch, setDisplaySearch] = useState(false)

  return (
    <>
      <HeaderContainer key="header">
        <Header>
          <LeftBlock>
            <Logo
              onClick={() => {
                history.push(`/`)
              }}
            />
            {!displaySearch && <HeaderTitle>Tích điểm</HeaderTitle>}
          </LeftBlock>
          <RightBlock>
            {displaySearch && (
              <SearchContainer>
                <SearchInput
                  type="text"
                  onBlur={(e) => {
                    if (e.target.value == '') {
                      return
                    }
                    // router.push(`/search?search=${e.target.value}`)
                  }}
                  onKeyUp={(e) => {
                    if (e.key == 'Enter' && e.target.value != '') {
                      // router.push(`/search?search=${e.target.value}`)
                    }
                  }}
                  placeholder="Tìm kiếm"
                />
              </SearchContainer>
            )}
            {!displaySearch && <SearchButton onClick={() => setDisplaySearch(true)} />}

            <Link to={`/info`}>
              <UserAvatar avatar={userInfo.avatar} />
            </Link>
            {right ? (
              <RightConponent onClick={RightAction} />
            ) : (
              <NotificationBell>
                <NotificationDotted />
              </NotificationBell>
            )}
          </RightBlock>
        </Header>
      </HeaderContainer>

      <HeaderBlock />
    </>
  )
}
export default memo(MobileHeader)
