/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from '../styles/cart-shop.module.scss'
import { Button, Checkbox, InputNumber } from 'antd'
import { ShopOutlined, MessageOutlined } from '@ant-design/icons'
import { formatCash } from 'utils'
import ImageDefault from 'assets/img/logo-icon.png'
import QRCart from '../items/qr-cart'
const CartShop = ({orders, item, handleAddOrdersOfBusiness, handleAddOrdersOfProduct}) => {
  if(!item || !item?.carts) return
  return (
    <div className={styles['cart-shop']}>
      <div className={styles['header']}>
        <div className={styles['item-1']}>
          <Checkbox checked={orders.find(e => e.business_id == item.business_id) ? true : false} onChange={(e) => handleAddOrdersOfBusiness(e, item.business_id)}/>
        </div>
        <div className={styles['item-2']}>
          <img src={item.business_logo || ImageDefault} style={{height: '25px', width: '25px'}}/>
        </div>
        <div className={styles['item-3']}>
          <span>{item.business_name}</span>
        </div>
        <div className={styles['item-4']}>
          <MessageOutlined style={{ fontSize: '25px', color: '#1E4DB7' }} />
        </div>
        <div className={styles['item-5']}>
          <QRCart item={item}/>
        </div>
      </div>
      {item.carts.map((e, index) => {
          return (
            <div key={index} className={styles['body']}>
            <div className={styles['item-1']}>
              <Checkbox checked={orders.find(value => value.business_id == item.business_id && value.carts.find(value2 => value2.variant_id == e.variant_id)) ? true : false} onChange={(event) => handleAddOrdersOfProduct(event, item, e)} />
            </div>
            <div className={styles['item-2']}>
              <img
                style={{ maxWidth: '100px', height: '100px', marginRight: '9px' }}
                src={e.image[0] || 'https://th.bing.com/th/id/R.3e3ffe8a4853e0afca386c2458272d30?rik=%2fRRtGMWlzhOe0A&pid=ImgRaw&r=0'}
              />
              <div>
                <span>{e.title} {"Mã sku: " + e.sku}</span>
              </div>
            </div>
            <div className={styles['item-3']}>
              <div>
                <span className={styles['span-1']}>{formatCash(e.price)+ 'đ'}</span>
              </div>
              <div>
                <span className={styles['span-2']}>29.000.000đ</span>
              </div>
            </div>
            <div className={styles['item-4']}>
              <InputNumber defaultValue={e.quantity}/>
            </div>
            <div className={styles['item-5']}>
              <span>{formatCash(e.price * e.quantity || 0)+ 'đ'}</span>
            </div>
            <div className={styles['item-6']}>
              <Button style={{borderColor: 'red', color: 'red'}}>Xoá</Button>
            </div>
          </div>
          )
      })}


      <div className={styles['footer']}>
        <div className={styles['item-1']}>
          <img
            style={{ marginRight: '9px' }}
            src="static/media/voucher.a760be3d5f35f486cc165ef4fab8e6d3.svg"
          />
        </div>
        <div className={styles['item-2']}>
          <span>Xem thêm mã giảm giá của shop</span>
        </div>
      </div>
    </div>
  )
}

export default CartShop
