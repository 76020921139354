import React from 'react'
import Post from './posts/post'
import Status from './status/status'

const Forum = () => {
  return (
    <div>
        <Status/>
        <Post/>
    </div>
  )
}

export default Forum