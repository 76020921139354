import styles from './icon.module.scss';

function ThreeDotsIcon({ classes }) {
  return (
    <div>
      <ul>
        <li className={styles[classes]}></li>
        <li className={styles[classes]}></li>
        <li className={styles[classes]}></li>
      </ul>
    </div>
  );
}

export { ThreeDotsIcon };
