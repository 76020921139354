import {useDispatch} from 'react-redux'
import {myBusiness} from "../apis/business";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export async function LoadMyBusiness() {
    const dispatch = useDispatch();
        try {
            const accessToken = Cookies.get('accessToken');
            const payload = accessToken && jwt_decode(accessToken)
    
            if (!payload) return;
            const res = await myBusiness();
            dispatch({
                type: 'SET_MY_BUSINESS',
                data: res.data.data
            })
    
    
        } catch (error) {
            //
        }
   
}
