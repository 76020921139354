/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from './status.module.scss'
import ORDER1 from 'assets/icons/order-1.png'
import ORDER2 from 'assets/icons/order-2.png'
import CASH1 from 'assets/icons/cash-1.png'
import CASH2 from 'assets/icons/cash-2.png'
import CASH3 from 'assets/icons/cash-3.png'
import SHIP1 from 'assets/icons/ship-1.png'
import SHIP2 from 'assets/icons/ship-2.png'
import SHIP3 from 'assets/icons/ship-3.png'
import DELIVERY1 from 'assets/icons/delivery-1.png'
import DELIVERY2 from 'assets/icons/delivery-2.png'
import DELIVERY3 from 'assets/icons/delivery-3.png'
import FEEDBACK1 from 'assets/icons/feedback-1.png'
import FEEDBACK2 from 'assets/icons/feedback-2.png'
import FEEDBACK3 from 'assets/icons/feedback-3.png'

const StatusTag = () => {
  const iconsOrder = [
    { name: 'order-1', icon: ORDER1, status: 1 },
    { name: 'order-2', icon: ORDER2, status: 2 },
  ]
  const iconsCash = [
    { name: 'cash-1', icon: CASH1, status: 1 },
    { name: 'cash-2', icon: CASH2, status: 2 },
    { name: 'cash-3', icon: CASH3, status: 3 },
  ]
  const iconsShip = [
    { name: 'ship-1', icon: SHIP1, status: 1 },
    { name: 'ship-2', icon: SHIP2, status: 2 },
    { name: 'ship-3', icon: SHIP3, status: 3 },
  ]
  const iconsDelivery = [
    { name: 'delivery-1', icon: DELIVERY1, status: 1 },
    { name: 'delivery-2', icon: DELIVERY2, status: 2 },
    { name: 'delivery-3', icon: DELIVERY3, status: 3 },
  ]
  const iconsFeedback = [
    { name: 'feedback-1', icon: FEEDBACK1, status: 1 },
    { name: 'feedback-2', icon: FEEDBACK2, status: 2 },
    { name: 'feedback-3', icon: FEEDBACK3, status: 3 },
  ]
  return (
    <div className={styles['tab-status']}>
      <div className={styles['tab-status-text']}>
        <div>
          <div className={styles['tab-status-divider']}>a</div>
          <img style={{ position:'relative'}} src={iconsOrder[1].icon} />
          <p className={styles['p-0']}>Đơn Hàng Đã Đặt</p>
          <p className={styles['p-1']}>12:12 12-12-2022</p>
        </div>

        <div>
          <div className={styles['tab-status-divider']}>a</div>
          <img style={{ position:'relative'}} src={iconsCash[2].icon} />
          <p className={styles['p-0']}>
            Đã Xác Nhận<br></br>Thông Tin Thanh Toán
          </p>
          <p className={styles['p-1']}>12:12 12-12-2022</p>
        </div>

        <div>
          <div className={styles['tab-status-divider']}>a</div>
          <img style={{ position:'relative'}} src={iconsShip[2].icon} />
          <p className={styles['p-0']}>Đã Giao Cho ĐVVC</p>
          <p className={styles['p-1']}>12:12 12-12-2022</p>
        </div>

        <div>
          <div className={styles['tab-status-divider-2']}>a</div>
          <img style={{ position:'relative'}} src={iconsDelivery[1].icon} />
          <p className={styles['p-0']}>Đang Giao</p>
          <p className={styles['p-1']}>12:12 12-12-2022</p>
        </div>

        <div>
          <img style={{ position:'relative'}} src={iconsFeedback[0].icon} />
          <p className={styles['p-0']}>Đánh Giá</p>
          <p className={styles['p-1']}>12:12 12-12-2022</p>
        </div>
      </div>
    </div>
  )
}

export default StatusTag
