import { Card, Col, Rate, Row, Tooltip } from 'antd'
import { addToCart } from 'apis/cart'
import styled from 'styled-components'
import { ModalDetailProduct } from './items/detailProductCart'
import styles from './Product.module.scss'
import { formatCash } from 'utils'

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 10px;
    width: 100%
  }
`

function Description(props) {
  const { price, priceOld, amount, deal, discount} = props

  return (
    <div className={styles.product__description}>
      <div className={styles['product__block']}>
        <span className={styles.product__discount}>Giảm {discount}</span>
        <span className={styles.product__deal}>{deal}</span>
      </div>
      <div className={`${styles['product__block']} ${styles['product__block-price']}`}>
        <span className={styles.product__priceOld}>{priceOld}</span>
        <span className={styles.product__price}>{formatCash(price)}đ</span>
      </div>
      <div className={styles['product__block']}>
        <Rate allowHalf className={styles.product__rate} />
        <span className={styles.product__amount}>Đã bán {amount}</span>
      </div>
    </div>
  )
}

function Product({ product, handleAddToCart }) {
  const { image, name, priceOld, price, amount, deal, discount } = product

  return (
    <CardStyled
      hoverable
      style={{borderRadius: '8px'}}
      cover={<img src={image} alt="" className={styles.product__img} />}
      className={styles.product}
    >
      <Card.Meta
        className={styles.product__meta}
        title={<div className={styles.product__title}>{name}</div>}
        description={
          <div>
            <div>
              <Description
                price={price}
                priceOld={priceOld}
                amount={amount}
                deal={deal}
                discount={discount}
              />
            </div>
            <div>
              <Tooltip placement="topLeft" title="Thêm vào giỏ hàng" color="#108ee9">
                <ModalDetailProduct handleAddToCart={handleAddToCart} product={product} />
              </Tooltip>
            </div>
          </div>
        }
      />
      <Card.Meta />
    </CardStyled>
  )
}

export default Product
