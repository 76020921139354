const initialState = [];

export const EditData = (data, id, new_data) => {
    const newData = data.map(item => 
        (item?.notification_user_id == id ? new_data : item)
    )
    return newData;
}

export const DeleteData = (data, id,) => {
    const newData = data.filter(item => item.notification_user_id != id || !item.notification_user_id)
    return newData;
}


const notification = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATION': {
            state = action.data
            return [...state]
        }
        case 'UPDATE_NOTIFICATION': {
            return EditData(state, action.data.notification_user_id, action.data)
        }
        case 'DELETE_NOTIFICATION': {
            return DeleteData(state, action.data.notification_user_id)
        }
        default:
            return state
    }
};
export default notification
