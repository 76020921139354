export const ACTION = {
  CHECK_SUBDOMAIN: 'CHECK_SUBDOMAIN',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_STORE: 'GET_STORE',
  SELECT_VALUE: 'SELECT_VALUE',
  ERROR: 'ERROR',
  CHANGE_SIDER: 'CHANGE_SIDER',
}
export const ROUTES = {
  HOME_PAGE: '/',
  OTP: '/otp',
  CHANGE_PASSWORD: '/change-password',
  FORGET_PASSWORD: '/forget-password',
  LOGIN: '/login',
  REGISTER_V2: '/register-v2',
  REGISTER: '/register',
  REGISTER_BUSINESS: '/register-business',
  VERIFY_BUSINESS: '/verify-business',
  FINISHED_BUSINESS: '/finised-business',
  INFOR: '/infor',
  SETTING_INFOR: '/setting-infor',
  NOTIFICATION_LIST: '/notification-list',
  POINT: '/point',
  DETAILBUSINESS: `/:prefix`,
  DETAIL_POINT: '/detail-point',
  VOUCHER: '/voucher',
  CART: '/cart',
  DETAIL_POST: '/detail-post/:id',
  INFO_USER: '/info-user/:id',
  MESSAGE: '/message',
  CONVERSATION: '/conversation/:id',
  ABOUT: '/about',
  PRIVACY:'/privacy-policy',
  SEARCH:'/search',
}

export const VERSION_APP = '1.4.5.13'

export const regexRemoveUnicode =
  /^[a-zA-Z0-9@. aàảãáạăằẳẵắặâầẩẫấậAÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬdđDĐeèẻẽéẹêềểễếệEÈẺẼÉẸÊỀỂỄẾỆiìỉĩíịIÌỈĨÍỊoòỏõóọôồổỗốộơờởỡớợOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢuùủũúụưừửữứựUÙỦŨÚỤƯỪỬỮỨỰyỳỷỹýỵYỲỶỸÝỴ]*$/

export const regexPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
