import React from "react";
import styles from "../styles/footerForm.module.scss";
import { Link } from "react-router-dom";

const FooterForm = () => {
  return (
    <div className={styles["footer-form"]}>
      <p className={styles["p-1"]}>Bạn đã có tài khoản?</p>
      <Link to={'/login'}>
        <p className={styles["p-2"]}>Đăng Nhập</p>
      </Link>
    </div>
  );
};

export default FooterForm;
