import React from 'react'
import AvatarDefault from 'assets/img/avatar-profile.png'
import styles from '../../styles/detail-post.module.scss'
import moment from 'moment'
import PublicIcon from 'assets/icons/public.png'
import PrivateIcon from 'assets/icons/private.png'

const Header = ({ data }) => {
  return (
    <div style={{ padding: '15px', borderTop: '1px solid rgb(196, 196, 196)'}}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '15px' }}>
          <img
            style={{ width: '40px', height: '40px', marginRight: '11px' }}
            src={AvatarDefault}
            alt=""
          />
          <div className={styles['info']}>
            <span className={styles['span-1']}>
              {data?.creator_info?.first_name + ' ' + data?.creator_info?.last_name}
            </span>
            <span className={styles['span-2']}>
              {moment(data?.create_date).fromNow()}{' '}
              <img
                style={{ width: '15px', height: '15px', marginTop: '-2px' }}
                src={data?.status == 'public' ? PublicIcon : PrivateIcon}
                alt=""
              />
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              paddingLeft: '15px',
              fontSize: '16px',
              color: '#1E4DB7',
              fontFamily: 'Inter',
              fontWeight: 400,
              borderLeft: '1px solid #C8C8C8',
            }}
          >
            Theo dõi
          </span>
        </div>
      </div>

    </div>
  )
}

export default Header
