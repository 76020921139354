import React, { useEffect } from 'react'
import styles from '../styles/more-detail.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import {
  DeleteFilled,
  DownOutlined,
  FileImageOutlined,
  IssuesCloseOutlined,
  LinkOutlined,
  MinusCircleFilled,
  RightOutlined,
  SearchOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons'
import { useState } from 'react'
import PopupDeleteConversation from './popupDeleteConversation'
import MoreDetailMedia from './moreDetailMedia'
import { useDispatch, useSelector } from 'react-redux'
import { getMediaMessagesUser } from 'redux/actions/message'
import MoreDetailLink from './moreDetailLink'

const MoreDetail = ({ openLink, setOpenLink, openMedia, setOpenMedia, dataUser, id, user, setVisible, visible, handleDeleteConversation }) => {
  const [moreOptionFirst, setMoreOptionFirst] = useState(false)
  const [moreOptionSecond, setMoreOptionSecond] = useState(false)
  const [moreOptionThird, setMoreOptionThird] = useState(false)

  const dispatch = useDispatch()
  const { message } = useSelector((state) => state)

  useEffect(() => {
      dispatch(getMediaMessagesUser({ auth: dataUser, id }))
  }, [])


  return (
    <>
      <div style={{height: '100%'}}>
        <div className={styles['type']}>
          <span>Người dùng</span>
        </div>
        <div className={styles['header']}>
          <img
            src={user.avatar || AvatarDefault}
            alt=""
            style={{ width: '50px', height: '50px', marginRight: '7px', borderRadius: '50%' }}
          />
          <div className={styles['child-text']}>
            <span
              onClick={() => window.open(`/info-user/${user.user_id}`, '_blank')}
              className={styles['span-1']}
            >
              {user.first_name + ' ' + user.last_name}
            </span>
            <span className={styles['span-2']}>Hoạt động 7 phút trước</span>
          </div>
        </div>
        {openMedia === true && (
          <MoreDetailMedia allMedia={message.media.data.filter(item => item && item.type != 'link')} setOpenMedia={setOpenMedia}/>
        ) }
        {openLink === true && (
          <MoreDetailLink allLink={message.media.data.filter(item => item && item.type == 'link')} setOpenLink={setOpenLink}/>
        ) }
        {openMedia === false && openLink === false && (
          <div className={styles['body']}>
            <div className={styles['more-option']}>
              <span
                className={styles['span-1']}
                onClick={() => setMoreOptionFirst(!moreOptionFirst)}
              >
                Tuỳ chỉnh đoạn chat{' '}
                {moreOptionFirst === false ? <RightOutlined /> : <DownOutlined />}
              </span>
              {moreOptionFirst === true && (
                <span className={styles['span-2']}>
                  <SearchOutlined /> Tìm kiếm tin nhắn{' '}
                </span>
              )}
            </div>

            <div className={styles['more-option-2']}>
              <span
                className={styles['span-1']}
                onClick={() => setMoreOptionSecond(!moreOptionSecond)}
              >
                File phương tiện & Liên kết{' '}
                {moreOptionSecond === false ? <RightOutlined /> : <DownOutlined />}
              </span>
              {moreOptionSecond === true && (
                <span onClick={() => {
                  setOpenLink(false)
                  setOpenMedia(true)
                  }} className={styles['span-2']}>
                  <FileImageOutlined /> File phương tiện{' '}
                </span>
              )}
              {moreOptionSecond === true && (
                <span onClick={() => {
                  setOpenMedia(false)
                  setOpenLink(true)
                  }} className={styles['span-2']}>
                  <LinkOutlined /> Liên kết{' '}
                </span>
              )}
            </div>

            <div className={styles['more-option-3']}>
              <span
                className={styles['span-1']}
                onClick={() => setMoreOptionThird(!moreOptionThird)}
              >
                Quyền riêng tư & Hỗ trợ{' '}
                {moreOptionThird === false ? <RightOutlined /> : <DownOutlined />}
              </span>
              {moreOptionThird === true && (
                <span className={styles['span-3']}>
                  <MinusCircleFilled style={{ color: '#FF5555' }} /> Bỏ theo dõi{' '}
                </span>
              )}
              {moreOptionThird === true && (
                <span onClick={() => setVisible(true)} className={styles['span-3']}>
                  <DeleteFilled style={{ color: '#FF5555' }} /> Xoá đoạn chat{' '}
                </span>
              )}
              {moreOptionThird === true && (
                <span className={styles['span-2']}>
                  <UserDeleteOutlined /> Chặn{' '}
                </span>
              )}
              {moreOptionThird === true && (
                <span className={styles['span-2']}>
                  <IssuesCloseOutlined /> Báo cáo{' '}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <PopupDeleteConversation
        visible={visible}
        setVisible={setVisible}
        handleDeleteConversation={handleDeleteConversation}
      />
    </>
  )
}

export default MoreDetail
