import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'

const MoreDetailMedia = ({ allMedia, setOpenMedia }) => {
  return (
    <div style={{ width: '100%', height: 'calc(100% - 150px)' }}>
      <div style={{ padding: '0 10px' }}>
        <span
          onClick={() => setOpenMedia(false)}
          style={{ fontSize: '15px', fontWeight: 500, cursor: 'pointer' }}
        >
          <ArrowLeftOutlined /> Quay lại
        </span>
      </div>
      <div
        style={{
          marginTop: '10px',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '3px',
        }}
      >
        {allMedia?.map((item, index) => (
          <div style={{ padding: '1px', maxWidth: '75px', maxHeight: '75px' }}>
            {item.type === 'video' ? (
              <video
                controls
                src={item.url}
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <img
                style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                key={index}
                alt=""
                src={item.url}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MoreDetailMedia
