import { Typography } from 'antd'
import React from 'react'
import Comments from 'views/forum/comments/comments'
const { Paragraph } = Typography


const Body = ({ data }) => {
  return (
    <div style={{ padding: '0 15px', marginBottom: '63px' }}>
      <div>
        <Paragraph
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: 'Xem Thêm',
          }}
        >
          {data?.title}
        </Paragraph>
      </div>
      <Comments item={data} />
    </div>
  )
}

export default Body
