import React from 'react'
import { Button, Col, Image, Row, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
export const ImagesPost = ({ images }) => {
  let newImages = images?.filter((item) => item.length)
  if (!newImages || newImages.length === 0) return <></>
  if (newImages?.length === 1) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={newImages[0]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else if (newImages?.length === 2) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '100%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[0]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '100%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[1]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else if (newImages?.length === 3) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '100%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[0]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[1]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[2]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else if (newImages?.length === 4) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center" gutter={[20, 0]}>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[0]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[1]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[2]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[3]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else {
    return (
      <Row style={{ background: '#f0f0f0', height: '100%' }} justify="center">
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '50%',
              objectFit: 'cover',
              padding: '1px',
            }}
            src={newImages[0]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '50%',
              padding: '1px',
              objectFit: 'cover',
            }}
            src={newImages[1]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              padding: '1px',
              height: '50%',
            }}
            src={newImages[2]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '50%',
              display: 'block',
              padding: '1px',
              opacity: 0.7,
            }}
            src={newImages[3]}
            alt=""
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              width: '100%',
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
              padding: '1px',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: 40, color: 'white' }}>
              +{newImages.length - 3}
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}
