/* eslint-disable jsx-a11y/iframe-has-title */
import { Col, Image, Menu, Row } from 'antd'
import React from 'react'

export const imageShow = (src, check) => {
  return (
    <div
      style={{
        width: check == true ? '100px' : '170px',
        height: check == true ? '100px' : '170px',
        borderRadius: 10,
        padding: '3px',
      }}
    >
      <Image
        width={'100%'}
        height={'100%'}
        src={src}
        alt="images"
        style={{
          borderRadius: 10,
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const videoShow = (src , check) => {
  return (
    <div
      style={{
        width: check == true ? '100px' : '170px',
        height: check == true ? '100px' : '170px',
        borderRadius: 10,
        padding: '3px',
      }}
    >
      <video
        controls
        src={src}
        alt="images"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: 10,
        }}
      />
    </div>
  )
}

export const MenuIcon = ({ data, setData }) => {
    const reactions1 = ['❤️', '😆', '😯', '😢', '😡', '👍']
    const reactions2 = ['👎', '😄', '😂', '😍', '😘', '😗']
    const reactions3 = ['😚', '😳', '😭', '😓', '😤', '🤤']
    const reactions4 = ['👻', '💀', '🤐', '😴', '😷', '😵 ']
    return (
      <Menu>
        <Row>
          <Col>
            {reactions1.map((item, index) => (
              <Menu.Item key={index} onClick={() => setData(data + item)}>
                {item}
              </Menu.Item>
            ))}
          </Col>
          <Col>
            {reactions2.map((item, index) => (
              <Menu.Item key={index} onClick={() => setData(data + item)}>
                {item}
              </Menu.Item>
            ))}
          </Col>
          <Col>
            {reactions3.map((item, index) => (
              <Menu.Item key={index} onClick={() => setData(data + item)}>
                {item}
              </Menu.Item>
            ))}
          </Col>
          <Col>
            {reactions4.map((item, index) => (
              <Menu.Item key={index} onClick={() => setData(data + item)}>
                {item}
              </Menu.Item>
            ))}
          </Col>
        </Row>
      </Menu>
    )
  }

export const MenuGif = () => {
    const exampleGif = [
      1, 2, 3, 4, 5, 6, 7, 8, 9,
    ]
  
    return (
      <div style={{ overflow: 'scroll', height: '400px' }}>
        {exampleGif.map((item) => (
          <span>
            <div style={{ width: '100%', height: 0, paddingBottom: '60%', position: 'relative' }}>
              <iframe
                src="https://giphy.com/embed/d47HzDtYmyQg4uvC"
                width="100%"
                height="100%"
                style={{ position: 'absolute' }}
                frameBorder="0"
                class="giphy-embed"
                allowFullScreen
              ></iframe>
            </div>
            <div style={{ width: '250px' }}></div>
          </span>
        ))}
      </div>
    )
  }