import { get, post, patch } from './httpClient'

export const getAllProductPublic = () => get('/feed')
export const getProductPosts = () => get('/feed/getProductPosts')
export const getUserPosts = () => get('/feed/getUserPosts')
export const getDetailPost = (id) => get(`/feed/getDetailPost/${id}`)
export const createPost = (body) => post('/feed/createPost', body)
export const editPost = (id, body) => patch(`/feed/editPost/${id}`, body)
export const deletePost = (id) => patch(`/feed/deletePost/${id}`)
export const likePostFeed = (id) => patch(`/feed/post/${id}/like`)
export const unLikePostFeed = (id) => patch(`/feed/post/${id}/unlike`)
export const pinPostFeed = (id) => patch(`/feed/post/${id}/pin`)
export const unPinPostFeed = (id) => patch(`/feed/post/${id}/unpin`)
export const suggestUsers = (query) => get('/feed/suggestUsers', query)
