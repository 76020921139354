import { Affix, Card, Spin, Modal } from 'antd'
import {
  ShopOutlined,
  MessageOutlined,
  FieldTimeOutlined,
  StarOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import SettingsIcon from 'assets/icons/setting.svg'
import LogoIcon from 'assets/img/store-default.png'

import styles from './Profile.module.scss'
import CartAuth from './cartAuth'
import PinPosts from './pinPosts'
import { useSelector } from 'react-redux'
//components
import { ButtonCustom } from 'components/Button/Button'
import HistoryShoppingPoint from './items/HistoryShoppingPoint'
//apis
import { checkPointInBusiness } from 'apis/point'
import { getOrderCustomer } from 'apis/shopping-dairy'

import { useEffect, useState } from 'react'
import moment from 'moment'
function Description(props) {
  const {
    follower,
    following,
    quantity,
    feedbackRate,
    feedbackTime,
    rate,
    finishedRate,
    phone,
    name,
  } = props
  const dataUser = useSelector((state) => state.userInfo)
  const [customer, setCustomer] = useState(null)
  const [hidenSync, setHidenSync] = useState(false)
  const [historyOrder, setHistoryOrder] = useState([])
  const [countHistoryOrder, setCountHistoryOrder] = useState(0)
  const listData = [
    {
      icon: ShopOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Sản phẩm: <span className={styles['profile__item-number']}>{quantity}</span>
        </p>
      ),
    },
    {
      icon: MessageOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Tỉ lệ phản hồi: <span className={styles['profile__item-number']}>{feedbackRate}</span>
        </p>
      ),
    },
    {
      icon: FieldTimeOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Thời gian phản hồi: <span className={styles['profile__item-number']}>{feedbackTime}</span>
        </p>
      ),
    },
    {
      icon: StarOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Đánh giá shop: <span className={styles['profile__item-number']}>{rate}</span>
        </p>
      ),
    },
    {
      icon: CheckCircleOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Tỉ lệ hoàn thành đơn:
          <span className={styles['profile__item-number']}>{finishedRate}</span>
        </p>
      ),
    },
  ]
  const checkPoint = async () => {
    try {
      const res = await checkPointInBusiness({ business_phone: phone })
      let check = false
      if (res.status === 200 && res.data.success) {
        setCustomer(res.data.data)
        const resOrders = await getOrderCustomer({
          customer_id: res.data.data.customer_id,
          business_phone: phone,
        })
        if (resOrders.status === 200) {
          setHistoryOrder(resOrders.data.data)
          setCountHistoryOrder(resOrders.data.data.length)
          let index = resOrders.data.data.findIndex(
            (e) => moment(e.create_date).unix() < moment(dataUser.created_at).unix()
          )
          if (index !== -1) {
            check = true
          }
        }
      }
      if (res.data.success && !res.data.data.auto_sync && check) {
        setHidenSync(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    checkPoint()
  }, [phone])
  return (
    <div className={styles.profile__description}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
        <ButtonCustom classes="btn__blue" text="+ Theo dõi" />
        {hidenSync === false ? (
          <></>
        ) : (
          <HistoryShoppingPoint
            customer={customer}
            phone={phone}
            name={name}
            setHidenSync={setHidenSync}
            historyOrder={historyOrder}
            countHistoryOrder={countHistoryOrder}
          />
        )}
      </div>
      <div className={styles.profile__media}>
        <div className={styles.profile__follow}>
          <span className={styles['profile__follow-number']}>{follower}</span>
          <span className={styles['profile__follow-text']}>Lượt theo dõi</span>
        </div>
        <div className={styles.profile__follow}>
          <span className={styles['profile__follow-number']}>{following}</span>
          <span className={styles['profile__follow-text']}>Đang theo dõi</span>
        </div>
      </div>
      <div className={styles.profile__statistic}>
        <ul>
          {listData.map((item) => {
            const { icon: Icon, Content } = item

            return (
              <li className={styles.profile__item}>
                <Icon className={styles['profile__item-icon']} />
                <Content />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

function Title({ status, name }) {
  return (
    <div className={styles.profile__title}>
      <h2 className={styles.profile__name}>{name}</h2>
      <p className={styles.profile__status}>{status}</p>
    </div>
  )
}
function Profile({ profile, classes, setLoadingCart, loadingCart, setCarts, carts }) {
  if (!profile) return <></>

  const {
    avatar,
    bgAvatar,
    name,
    status,
    follower,
    following,
    quantity,
    feedbackRate,
    feedbackTime,
    rate,
    finishedRate,
    description,
    phone,
  } = profile

  return (
    <Affix offsetTop={80}>
      <Card
        cover={
          <div className={styles['profile__avatar-box']}>
            <img src={bgAvatar} alt="" className={styles['profile__bg-avatar']} />
            <img src={avatar || LogoIcon} alt="" className={styles.profile__avatar} />
          </div>
        }
        className={`${styles.profile} ${classes}`}
      >
        <Title name={name} status={description} />
        <Description
          follower={follower}
          following={following}
          feedbackRate={feedbackRate}
          feedbackTime={feedbackTime}
          quantity={quantity}
          finishedRate={finishedRate}
          rate={rate}
          phone={phone}
          name={name}
        />
      </Card>
      <Spin spinning={loadingCart}>
        <CartAuth carts={carts} setCarts={setCarts} />
      </Spin>

      <PinPosts />
    </Affix>
  )
}

export default Profile
