import {useDispatch} from 'react-redux'
import Icon from '../utils/antd-icons'
import {getMenu} from "../apis/menu";
import {each} from "underscore";

export async function LoadMenu() {
    const dispatch = useDispatch();

    try {
        const res = await getMenu();
        const menuList = res.data.data;

        let menuMap = [];

        let parentMenu = menuList.filter(menu => menu.parent_menu_id === '')

        each(parentMenu, (menu) => {
            menuMap.push({
                menu_id: menu.menu_id,
                icon: menu.icon ? Icon(menu.icon, {fontSize: '23px'}) : '',
                name: menu.name,
                url: menu.url,
                status: menu.status
            });
        })

        let subMenu = menuList.filter(menu => menu.parent_menu_id !== '')
        each(subMenu, menu => {
            const parentIndex = menuMap.findIndex(item => item.menu_id === menu.parent_menu_id);
            if (typeof menuMap[parentIndex].subMenu == 'undefined') {
                menuMap[parentIndex].subMenu = [];
            }
            menu.icon = menu.icon ? Icon(menu.icon, {fontSize: '15px', height: '15px'}) : false;
            menuMap[parentIndex].subMenu.push(menu);
        });

        dispatch({
            type: 'SET_MENU',
            data: menuMap
        })
    } catch (error) {
        //
    }
}
