import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel, Image } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getDetailPostUser } from 'redux/actions/postsUser'
import { InteractionIcons } from '../posts/interactionIcons'
import styles from '../styles/detail-post.module.scss'
import Body from './inforPosts/body'
import Footer from './inforPosts/footer'
import Header from './inforPosts/header'

export const PostItemDetail = ({ item }) => {
  const carousel = useRef()

  const [checkComment, setCheckComment] = useState(0)

  const handleNext = () => carousel.current.next()

  const handlePrev = () => carousel.current.prev()
  return (
    <div className={styles['full-side']}>
      <div className={styles['left-side']}>
        <div className={styles['item-left']}>
          <div
            onClick={handlePrev}
            style={{
              background: 'white',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #5F5F5F',
              cursor: 'pointer',
            }}
          >
            <LeftOutlined style={{ fontSize: 32, color: '#5F5F5F' }} />
          </div>
        </div>
        <div className={styles['item-middle']}>
          <Carousel slidesToShow={1} className={styles['carousel-style']} ref={carousel}>
            {item?.images?.map((img, index) => (
              <div className={styles['item-div-img']} key={index}>
                <img className={styles['item-img']} alt="" src={img?.url} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={styles['item-right']}>
          <div
            onClick={handleNext}
            style={{
              background: 'white',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #5F5F5F',
              cursor: 'pointer',
            }}
          >
            <RightOutlined style={{ fontSize: 32, color: '#5F5F5F' }} />
          </div>
        </div>
      </div>
      <div className={styles['right-side']}>
        <Header data={item} />
        <div className={styles['scroll-body']}>
          <Body data={item} />
        </div>
        <div
          style={{
            bottom: 0,
            position: 'fixed',
            padding: '15px',
            backgroundColor: 'white',
            borderTop: '1px solid #C4C4C4',
            width: '400px',
          }}
        >
          <InteractionIcons checkComment={checkComment} setCheckComment={setCheckComment} data={item} />
          <div className={styles['info-1']}>
            {item?.likes?.length} người <span>đã thích</span>
          </div>
          <Footer item={item} checkComment={checkComment}/>
        </div>
      </div>
    </div>
  )
}

const DetailPost = () => {
  const { id } = useParams()
  const [post, setPost] = useState([])

  const { detailPostUser } = useSelector((state) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDetailPostUser({ detailPost: detailPostUser, id }))

    if (detailPostUser.length > 0) {
      const newArr = detailPostUser.filter((post) => post.post_id == id)
      setPost(newArr)
    }
  }, [detailPostUser, dispatch, id])

  return post.map((item, index) => <PostItemDetail key={index} item={item} />)
}

export default DetailPost
