import io from 'socket.io-client'

export const socket = io(process.env.REACT_APP_HOST)

export const socketRegister = io(process.env.REACT_APP_ECOM_SOCKET)
export const socketAdmin = io(process.env.REACT_APP_ADMIN_SOCKET)

export const socketEcom = io(process.env.REACT_APP_ECOM_SOCKET)

export const socketPos = io(process.env.REACT_APP_POS_SOCKET)

