import { createMessage, deleteConversation, deleteMessage, getConversations, getMediaMessages, getMessages } from "apis/message"
import { DeleteMessage } from "redux/reducers/message"

export const addMessage = ({msg, auth, socket, share = false}) => async (dispatch) =>{
    dispatch({type: 'ADD_MESSAGE', payload: msg})

    const { user_id, avatar, first_name, last_name } = auth
    socket.emit('addMessage', {...msg, user: { user_id, avatar, first_name, last_name } })
    
    try {
        await createMessage(msg)
        if(share === false) {
            dispatch({type: 'UPDATE_MEDIA_MESSAGES', payload: msg.media})
        }
    } catch (err) {
        console.log(err)
    }
}

export const getConversationsUser = ({auth, page = 1}) => async (dispatch) => {
    try {
        const res = await getConversations({page: page})
        
        let newArr = [];
        res.data.data.forEach(item => {
            item.recipients.forEach(cv => {
                if(cv.user_id !== auth.user_id){
                    newArr.push({...cv, text: item.text, media: item.media, call: item.call})
                }
            })
        })

        dispatch({
            type: 'GET_CONVERSATIONS', 
            payload: {newArr, count: res.data.count}
        })

    } catch (err) {
        console.log(err)
    }
}

export const getMessagesUser = ({auth, id, page = 1}) => async (dispatch) => {
    try {
        const res = await getMessages(id, {page: page, page_size: page * 15})
        const newData = {...res.data, messages: res.data.data.reverse()}

        dispatch({type: 'GET_MESSAGES', payload: {...newData, user_id: id, page}})
    } catch (err) {
        console.log(err)
    }
}

export const getMediaMessagesUser = ({auth, id, page = 1}) => async (dispatch) => {
    try {
        const res = await getMediaMessages(id, {page: page, page_size: page * 15})
        if(res.status === 200) {
            const newData = {data: res.data.data}

            dispatch({type: 'GET_MEDIA_MESSAGES', payload: {...newData, user_id: id, page}})
        }
    } catch (err) {
        console.log(err)
    }
}

export const loadMoreMessages = ({auth, id, page = 1}) => async (dispatch) => {
    try {
        const res = await getMessages(id, {page_size: page * 15})
        const newData = {...res.data, messages: res.data.data.reverse()}

        dispatch({type: 'UPDATE_MESSAGES', payloawd: {...newData, user_id: id, page}})
    } catch (err) {
        console.log(err)
    }
}

export const deleteMessageUser = ({msg, data, auth}) => async (dispatch) => {
    const newData = DeleteMessage(data, msg.message_id)
    dispatch({type: 'DELETE_MESSAGES', payload: {newData, recipient_id: msg.recipient_id}})
    try {
        await deleteMessage(msg.message_id)
    } catch (err) {
        console.log(err)
    }
}

export const deleteConversationUser = ({auth, id}) => async (dispatch) => {
    dispatch({type: 'DELETE_CONVERSATION', payload: id})
    try {
        await deleteConversation(id)
    } catch (err) {
        console.log(err)
    }
}