import {get, post,patch} from './httpClient'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_DEV;

export const verificationBusiness = (body) => post('/business/verify', body, {}, API_ENDPOINT)
export const resendOtp = (body) => post('/business/send-otp', body, {}, API_ENDPOINT)
export const createNewBusiness = (body) => post('/business/new', body, {}, API_ENDPOINT)
export const myBusiness = () => get('/business/my-business', {}, {}, API_ENDPOINT)
export const detailBusiness = (params) => get(`/business/detail/${params}`)
export const getProductList = (params) => get(`/business/product-list/${params}`)
export const updateBusiness = (body, id) => patch(`/business/update/${id}`, body)
export const deleteBusiness = (id) => patch(`/business/delete/${id}`)



export const topBusiness = () => get('/top-business/')
