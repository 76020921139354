/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from '../styles/cart-header.module.scss'
import { Checkbox } from 'antd'

const CartHeader = ({orders, handleAddAllOrders}) => {
  return (
    <>
      <div className={styles['cart-header-info']}>
        <span>
          <img
            style={{ marginRight: '9px' }}
            src="static/media/voucher.a760be3d5f35f486cc165ef4fab8e6d3.svg"
          />
          Nhấn vào mục Mã giảm giá ở cuối trang để hưởng nhiều ưu đãi bạn nhé!
        </span>
      </div>
      <div className={styles['cart-header-product']}>
        <div className={styles['item-1']}>
          <Checkbox checked={orders.length > 0 ? true : false} onChange={handleAddAllOrders}/>
        </div>
        <div className={styles['item-2']}>
          <span>Sản phẩm</span>
        </div>
        <div className={styles['item-3']}>
          <span>Đơn giá</span>
        </div>
        <div className={styles['item-4']}>
          <span>Số lượng</span>
        </div>
        <div className={styles['item-5']}>
          <span>Số tiền</span>
        </div>
        <div className={styles['item-6']}>
          <span>Thao tác</span>
        </div>
      </div>
    </>
  )
}

export default CartHeader
