import React from 'react'
import styles from '../point.module.scss'
import group from 'assets/point/group.png'
import CusType1 from 'assets/icons/cus-type-1.png'
import CusType2 from 'assets/icons/cus-type-2.png'
import CusType3 from 'assets/icons/cus-type-3.png'
import CusType4 from 'assets/icons/cus-type-4.png'

const ProgressPoint = () => {
  const iconCus = [
    { icon: CusType1, type: 1 },
    { icon: CusType2, type: 2 },
    { icon: CusType3, type: 3 },
    { icon: CusType4, type: 4 },
  ]

  return (
    <div className={styles['progress-point']}>
      <div style={{ width: '100%' }}>
        <span className={styles['title']}>Tiến trình tích điểm</span>
        <div className={styles['group-item']}>
          <img src={group} alt="" className={styles['img-group-item']} />
        </div>

        <div className={styles['group-item-text']}>
          <div style={{ marginRight: '5%', marginLeft: '5%' }}>
            <div className={styles['group-price']}>100.000đ</div>
            <div className={styles['group-title']}>Khách hàng thân thiết</div>
          </div>
          <div style={{ marginRight: '6%', marginLeft: '4%' }}>
            <div className={styles['group-price']}>3.000.000đ</div>
            <div className={styles['group-title']}>Khách hàng bạc</div>
          </div>
          <div style={{ marginRight: '5%', marginLeft: '6%' }}>
            <div className={styles['group-price']}>5.000.000đ</div>
            <div className={styles['group-title']}>Khách hàng vàng</div>
          </div>
          <div style={{ marginRight: '5%', marginLeft: '5%' }}>
            <div className={styles['group-price']}>10.000.000đ</div>
            <div className={styles['group-title']}>Khách hàng bạch kim</div>
          </div>
        </div>
        <div className={styles['card-background']}>
          <div className={styles['card']}>
            <img src={iconCus[1].icon} alt="" className={styles['avatar']} />
            <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', marginLeft: '40px'}}>
                <li><span className={styles['span-text']}>Quyền lợi 1</span></li>
                <li><span className={styles['span-text']}>Quyền lợi 2</span></li>
                <li><span className={styles['span-text']}>Quyền lợi 3</span></li>
                <li><span className={styles['span-text']}>Quyền lợi 4</span></li>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressPoint
