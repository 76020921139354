/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import styles from '../../styles/infor-post.module.scss'
import { Badge, Button, Col, Dropdown, Input, notification, Popover, Row, Tooltip } from 'antd'
import {
  CameraOutlined,
  CloseCircleTwoTone,
  FileGifOutlined,
  FileImageOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { imageShow, MenuGif, MenuIcon, videoShow } from 'views/forum/items/items'
import { useDispatch, useSelector } from 'react-redux'
import { InteractionIcons } from 'views/forum/posts/interactionIcons'
import { uploadFile } from 'apis/upload'
import { createCommentUser } from 'redux/actions/postsUser'
import { useEffect } from 'react'
const Footer = ({ children, item, onReply, setOnReply, comment_reply_id, checkComment }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userInfo)
  const [comments, setComments] = useState('')
  const [image, setImage] = useState([])
  const [stream, setStream] = useState(false)
  const videoRef = useRef()
  const refCanvas = useRef()
  const [tracks, setTracks] = useState(null)
  const refInput = useRef()

  const handleChangeImages = (e) => {
    const files = [...e.target.files]
    let newImages = []

    files.forEach((file) => {
      if (!file) return notification.error({ message: 'File không tồn tại' })

      if (file.size > 1024 * 1024 * 5) {
        return notification.error({ message: 'The image/video largest is 5mb.' })
      }
      return newImages.push(file)
    })
    setImage([...newImages])
  }

  const deleteImages = () => {
    setImage([])
  }

  const handleStream = () => {
    setStream(true)

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          videoRef.current.srcObject = mediaStream
          videoRef.current.play()

          const track = mediaStream.getTracks()
          setTracks(track[0])
        })
        .catch((err) => console.log(err))
    }
  }

  const handleCapture = () => {
    const width = videoRef.current.clientWidth
    const height = videoRef.current.clientHeight

    refCanvas.current.setAttribute('width', width)
    refCanvas.current.setAttribute('height', height)

    const ctx = refCanvas.current.getContext('2d')
    ctx.drawImage(videoRef.current, 0, 0, width, height)
    let URL = refCanvas.current.toDataURL()
    setImage([{ camera: URL }])
  }

  const handleStopStream = (e) => {
    e.preventDefault()
    if (tracks) {
      tracks.stop()
    }
    setStream(false)
  }

  const handleSubmitComment = async (e) => {
    try {
      e.preventDefault()
      if (!comments.trim()) {
        if (onReply) return setOnReply(false)
        return notification.error({ key: 'message', message: 'Vui lòng nhập nội dung comment' })
      }
      let type_image = ''
      if (image.length > 0) {
        type_image = image[0].type.slice(0, 5)
      }

      const url_image = await uploadFile(image[0])
      const newComment = {
        comments,
        likes: [],
        user: user,
        image: url_image,
        type_image: type_image,
        comment_reply_id: onReply && onReply.comment_id,
        tag: onReply && onReply.user,
      }
      if (comment_reply_id) {
        newComment.comment_reply_id = comment_reply_id
      }

      dispatch(createCommentUser({ post: item, newComment, user }))
      setComments('')
      setImage([])

      if (onReply) return setOnReply(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
      refInput.current.focus()
  }, [checkComment])

  return (
    <div
    >
      <div className={styles['container-input']}>
        <Input
          prefix={children}
          className={styles['input-add-cmt']}
          placeholder="Thêm bình luận..."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          ref={refInput}
          suffix={
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Dropdown
                placement="top"
                trigger="click"
                overlay={<MenuIcon data={comments} setData={setComments} />}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tooltip placement="top" title="Biểu tượng cảm xúc">
                    <SmileOutlined className={styles['icon-cmt']} />
                  </Tooltip>
                </a>
              </Dropdown>
              {image.length == 0 && (
                <>
                  {stream ? (
                    <Tooltip placement="top" title="Chụp ảnh">
                      <CameraOutlined className={styles['icon-cmt']} onClick={handleCapture} />
                    </Tooltip>
                  ) : (
                    <>
                      {/* <Tooltip placement="top" title="Bật máy ảnh">
                        <CameraOutlined onClick={handleStream} className={styles['icon-cmt']} />
                      </Tooltip> */}
                      <Popover content={<MenuGif />} title="" trigger="click">
                        <Tooltip placement="top" title="file gif">
                          <FileGifOutlined className={styles['icon-cmt']} />
                        </Tooltip>
                      </Popover>
                      <label style={{ cursor: 'pointer' }}>
                        <Tooltip placement="top" title="Ảnh từ thiết bị">
                          <FileImageOutlined className={styles['icon-cmt']} />
                        </Tooltip>

                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept="image/*,video/*"
                          onChange={handleChangeImages}
                          style={{ display: 'none' }}
                        />
                      </label>
                    </>
                  )}
                </>
              )}
              <div>
                <span
                style={{color: '#1E4DB7', padding: 0, cursor: 'pointer'}}
                  onClick={handleSubmitComment}
                >
                  Đăng
                </span>
              </div>
            </div>
          }
        />
      </div>
      <Row>
        <Col>
          {stream && (
            <div style={{ marginTop: '20px' }}>
              <Badge
                count={
                  <CloseCircleTwoTone
                    onClick={handleStopStream}
                    twoToneColor="red"
                    style={{ fontSize: 24 }}
                  />
                }
              >
                <div>
                  <video
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: 10,
                      border: '1px solid #BABABA',
                    }}
                    autoPlay
                    muted
                    ref={videoRef}
                    width="100%"
                    height="100%"
                  />
                  <canvas ref={refCanvas} style={{ display: 'none' }} />
                </div>
              </Badge>
            </div>
          )}
        </Col>
        <Col>
          {image.length > 0 ? (
            <div style={{ marginTop: '20px' }}>
              <Badge
                count={
                  <CloseCircleTwoTone
                    onClick={deleteImages}
                    twoToneColor="red"
                    style={{ fontSize: 24 }}
                  />
                }
              >
                <div id="file_img">
                  {image[0].camera ? (
                    imageShow(image[0].camera, true)
                  ) : image[0].url ? (
                    <>
                      {image[0].url.match(/video/i)
                        ? videoShow(image[0].url, true)
                        : imageShow(image[0].url, true)}
                    </>
                  ) : (
                    <>
                      {image[0].type.match(/video/i)
                        ? videoShow(URL.createObjectURL(image[0]), true)
                        : imageShow(URL.createObjectURL(image[0]), true)}
                    </>
                  )}
                </div>
              </Badge>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Footer
