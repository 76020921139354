import React from "react";
import styles from "../styles/headerForm.module.scss";

const HeaderForm = () => {
  return (
    <div className={styles["header-form"]}>
        <h1>Đăng kí tài khoản</h1>
        <p>Chào bạn! Vui lòng nhập thông tin đăng kí</p>
    </div>
  );
};

export default HeaderForm;
