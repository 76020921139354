import React from "react";
import Logo from "../../../../assets/img/logo-icon.png";
import styles from "../styles/headerForm.module.scss";

const HeaderForm = () => {
  return (
    <div className={styles["header-form"]}>
      <img alt="logo" src={Logo} />
      <div>
        <h1>Chào mừng trở lại</h1>
        <p>Chào bạn! Vui lòng nhập thông tin đăng nhập</p>
      </div>
    </div>
  );
};

export default HeaderForm;
