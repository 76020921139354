import React from 'react'
import styles from '../style.module.scss'
import { Link,useHistory } from 'react-router-dom'

import defaultLogoBusiness from 'assets/img/store-default.png'
import { ButtonCustom } from 'components/Button/Button'
import Tooltip from './Tooltip'

export default function ListBusiness({ business }) {
  return (
    <div className={styles['people']}>
      <div className={styles['people__header']}>Cửa hàng</div>
      {business &&
        business.map((item, index) => (
          <div className={styles['item']}>
            <Link style={{ display: 'flex', alignItems: 'center' }} to={{pathname: `/${item.prefix}`,state:{business_id: item.business_id}}}><img
              src={item.business_logo ? item.business_logo : defaultLogoBusiness}
              className={styles['item__logo']}
              alt=""
            /></Link>
            <div className={styles['item__text']}>
              <div className={styles['item__title']}>
                {item.business_name}{' '}
                <Tooltip
                  id={item.business_id}
                  avatar={item.business_logo ? item.business_logo : defaultLogoBusiness}
                  name={item.business_name}
                  prefix={item.prefix}
                />
              </div>
              <div className={styles['people__bio']}> {item.business_address}</div>
            </div>
            <ButtonCustom classes="btn__blue-sky" text="+ Theo dõi" />
          </div>
        ))}
    </div>
  )
}
