const initialState = {
  loading: false,
  ids: [],
  users: [],
  posts: [],
}

export const EditData = (data, id, new_data) => {
    const newData = data.map(item => 
        (item.user_id == id ? new_data : item)
    )
    return newData;
}

export const DeleteData = (data, id) => {
  const newData = data.filter(item => item.user_id !== id)
  return newData;
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INFO_USER':
      return {
        ...state,
        users: [...state.users, action.payload],
      }
    case 'GET_ID_USER':
      return {
        ...state,
        ids: [...state.ids, action.payload],
      }
    case 'GET_POSTS_USER':
      return {
        ...state,
        posts: [...state.posts, ...action.payload],
      }
    case 'FOLLOW':
      return {
        ...state,
        users: EditData(state.users, action.payload.user_id, action.payload),
      }
    case 'UNFOLLOW':
      return {
        ...state,
        users: EditData(state.users, action.payload.user_id, action.payload)
    };
    default:
      return state
  }
}

export default profileReducer
