import { combineReducers } from 'redux'
import login from './login.js'
import store from './store.js'
import setting from './setting.js'
import menu from './menu.js'
import userInfo from './user-info.js'
import business from './business.js'
import myBusiness from './my-business.js'
import provinces from './provinces.js'
import districts from './districts.js'
import notification from './notification.js'
import voucher from './voucher'
import feed from './feed'
import postsProduct from './postsProduct'
import postsUser from './postsUser'
import cart from './cart'
import detailPostUser from './detailPostUser'
import profileUser from './profileUser'
import message from './message'
import socket from './socket'

const rootReducers = combineReducers({
  login,
  store,
  setting,
  menu,
  userInfo,
  business,
  myBusiness,
  provinces,
  districts,
  notification,
  voucher,
  feed,
  postsProduct,
  postsUser,
  cart,
  detailPostUser,
  profileUser,
  message,
  socket,
})
export default rootReducers
