import { ShoppingCartOutlined } from '@ant-design/icons'
import { Badge, Button, Card, Col, Image, Modal, notification, Row, Spin, Tag } from 'antd'
import { updateCart } from 'apis/cart'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { formatCash } from 'utils'
import CartItem from './items/cartItem'

const CartAuth = ({ carts, setCarts }) => {
  const { id } = useParams()
  const [isModalCart, setIsModalCart] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)

  const showModal = () => {
    setIsModalCart(true)
  }

  const handeUpdateCart = async () => {
    try {
      setLoadingConfirm(true)
      let res = await updateCart(id, carts)

      if (res.status === 200) {
        notification.success({ key: 'updateCart', message: 'Cập nhật giỏ hàng thành công' })
        setIsModalCart(false)
        setLoadingConfirm(false)
      }
    } catch (error) {
      setLoadingConfirm(false)
      console.log(error)
    }
  }

  return (
    <>
      <Card
        onClick={(e) => {
          e.stopPropagation()
          showModal()
        }}
        hoverable={true}
        style={{ maxWidth: '320px', marginTop: '5px', borderRadius: 10 }}
        size="small"
        title={
          <Row justify="space-between">
            <Col>
              <ShoppingCartOutlined /> Giỏ hàng
            </Col>
            <Col>
              <Badge
                className="site-badge-count-109"
                count={carts?.length || 0}
                style={{
                  backgroundColor: '#1e4db8',
                }}
              />
            </Col>
          </Row>
        }
        bodyStyle={{ display: 'none' }}
      ></Card>
      <Modal
        title={<span>Thông tin giỏ hàng</span>}
        visible={isModalCart}
        footer={[
          <Row gutter={[12, 12]} justify="end">
            <Col>
              <Button
                disabled={loadingConfirm === true ? true : false}
                onClick={async (e) => {
                  e.stopPropagation()
                  handeUpdateCart()
                }}
              >
                Xác nhận
              </Button>
            </Col>
            <Col>
              <Button style={{ backgroundColor: '#1e4db8', color: 'white' }}>Mua ngay</Button>
            </Col>
          </Row>,
        ]}
        closable={false}
        width={600}
        headStyle={{ backgroundColor: '#1e4db8' }}
        bodyStyle={{ padding: 5 }}
      >
        <Spin spinning={loadingConfirm}>
          <CartItem carts={carts} setCarts={setCarts} />
        </Spin>
      </Modal>
    </>
  )
}

export default CartAuth
