import { useEffect, useState } from 'react'
import { EmptyList, SubscriberCard } from 'components/card/card'
import { ButtonCustom } from 'components/Button/Button'
import LogoIcon from 'assets/img/user-new-feed.png'
import CommentAndFeedBacks from '../comment-feedbacks'
import History from './History'

import { getShopping } from 'apis/shopping-dairy'

import { useSelector } from 'react-redux'
import moment from 'moment'

export default function ShoppingHistory() {
  let user = useSelector((state) => state.userInfo)

  const [shopping, setShopping] = useState([])

  const _getShopping = async () => {
    try {
      const res = await getShopping(user.phone)
      if (res.status === 200) {
        setShopping(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getShopping()
  }, [])

  if (shopping === []) return <EmptyList text="Bạn chưa có đơn hàng nào! Hãy mua sắm nào" />

  return (
    <>
      {shopping && shopping.map((item) => (
        <div style={{ background: '#fff', padding: '25px 0', marginBottom:20, borderRadius:8 }}>
          <SubscriberCard
            classes="subscriber-feeds"
            name={item.business_name}
            description={moment(item.create_date).format('YYYY-MM-DD HH:mm')}
            avatar={{ status: true, image: `${item.business_logo}` }}
            business_id={item.business_id}
          >
            <ButtonCustom classes="btn__blue" text="+ Theo dõi" />
          </SubscriberCard>
            <History key={Math.random()} orders={item} />
        </div>
      ))}
    </>
  )
}
