import { Form, Input, Select } from "antd";
import React from "react";
import styles from "../styles/registerForm.module.scss";
import ButtonCustom from 'components/Button/buttonCustom'
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { register } from "apis/auth";
import { ROUTES } from "consts";
import PrefixSelector from "components/prefix/prefixSelector";

const marginBottomForm = {
  marginBottom: "16px",
};

const RegisterForm = () => {
  let history = useHistory()
  const [formRegister] = Form.useForm()

  const _register = async (dataForm) => {
    try {
      const regexPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()?])[A-Za-z\d!@#$%^&*()?]{8,}$/

      if (!regexPassword.test(dataForm.password)) {
        formRegister.setFields([
          {
            name: 'password',
            errors: ['Mật khẩu không được chứa dấu và tối thiểu 8 ký tự, ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt'],
          },
       ])
        return
      }
      if (dataForm.password !== dataForm.password_confirmation) {
        formRegister.setFields([
          {
            name: 'password_confirmation',
            errors: ['Mật khẩu và nhập lại mật khẩu phải giống nhau'],
          },
       ])
        return
      }
      const body = { ...dataForm, equipment: 1 }

      const res = await register(body)
      if (res.status === 200) {
        history.push({ pathname: ROUTES.OTP, state: { action: 'REGISTER', ...res.data.user } })
      } else {
        formRegister.setFields([
          {
            name: res.data.type,
            errors: [res.data.message],
          },
       ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Form requiredMark={false} form={formRegister} onFinish={_register} layout="vertical" style={{ width: "100%" }}>
        <Form.Item
          style={marginBottomForm}
          label={<p className={styles["label-style"]}>Họ và tên đệm</p>}
          name="last_name"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên đệm!" }]}
        >
          <Input className={styles["input-item"]} size="large" placeholder="Nhập họ và tên đệm" />
        </Form.Item>

        <Form.Item
          style={marginBottomForm}
          label={<p className={styles["label-style"]}>Tên</p>}
          name="first_name"
          rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
        >
          <Input className={styles["input-item"]} size="large" placeholder="Nhập tên" />
        </Form.Item>

        <Form.Item
          style={marginBottomForm}
          label={<p className={styles["label-style"]}>Số điện thoại</p>}
          name="phone"
          rules={[
            { required: true, message: "Vui lòng nhập số điện thoại", whitespace: true },
            {
              pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
              message: "Vui lòng nhập số điện thoại đúng định dạng",
            },
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            prefix={<PrefixSelector/>}
            allowClear
            className={styles["input-item"]}
            size="large"
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>

        <Form.Item
          style={marginBottomForm}
          label={<p className={styles["label-style"]}>Mật khẩu</p>}
          name="password"
          rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
        >
          <Input.Password className={styles["input-item"]} size="large" type="password" placeholder="Nhập mật khẩu" />
        </Form.Item>

        <Form.Item
          style={marginBottomForm}
          label={<p className={styles["label-style"]}>Xác nhận mật khẩu</p>}
          name="password_confirmation"
          rules={[{ required: true, message: "Vui lòng nhập xác nhận mật khẩu!" }]}
        >
          <Input.Password
            className={styles["input-item"]}
            size="large"
            type="password"
            placeholder="Nhập mật khẩu xác nhận"
          />
        </Form.Item>

        <Form.Item style={{marginTop: '5px', marginBottom: '20px'}}>
          <ButtonCustom text={"Đăng kí"} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterForm;
