import React, { useEffect, useState } from 'react'
import styles from '../styles/post.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import { Dropdown, Typography } from 'antd'
import { InteractionIcons, MenuEditPost, MenuInteraction } from './interactionIcons'
import { useDispatch, useSelector } from 'react-redux'
import { getPostsUser } from 'redux/actions/postsUser'
import moment from 'moment'
import { ImagesList } from './imageList'
import { EllipsisOutlined } from '@ant-design/icons'
import PublicIcon from 'assets/icons/public.png'
import PrivateIcon from 'assets/icons/private.png'
import { useHistory } from 'react-router-dom'
const { Paragraph } = Typography

export const DropdownEditPost = ({ data }) => {
  const [visibleMenuEdit, setVisibleMenuEdit] = useState(false)

  return (
    <Dropdown
      visible={visibleMenuEdit}
      onVisibleChange={() => setVisibleMenuEdit(!visibleMenuEdit)}
      placement="bottom"
      trigger="click"
      overlay={<MenuEditPost data={data} setVisibleMenuEdit={setVisibleMenuEdit} />}
    >
      <div className={styles['more-option']}>
        <EllipsisOutlined style={{ fontSize: 32 }} />
      </div>
    </Dropdown>
  )
}

export const DropdownInteraction = () => {
  const [visibleMenuEdit, setVisibleMenuEdit] = useState(false)

  return (
    <Dropdown
      visible={visibleMenuEdit}
      onVisibleChange={() => setVisibleMenuEdit(!visibleMenuEdit)}
      placement="bottom"
      trigger="click"
      overlay={<MenuInteraction/>}
    >
        <img style={{ width: '32px', height: '32px', cursor: 'pointer' }} src={AvatarDefault} alt="" />
    </Dropdown>
  )
}

const Post = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { posts, postsFilter } = useSelector((state) => state.postsUser)

  useEffect(() => {
    dispatch(getPostsUser())
  }, [])

  return (
    posts?.length > 0 &&
    posts.map((data, index) => data && (
      <div key={index} className={styles['post']}>
        <div className={styles['header']}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              style={{ width: '40px', height: '40px', marginRight: '11px', borderRadius: '50%' }}
              src={data?.creator_info?.avatar || AvatarDefault}
              alt=""
            />
            <div className={styles['info']}>
              <span onClick={() => history.push(`/info-user/${data.creator_id}`)} className={styles['span-1']}>
                {data?.creator_info?.first_name + ' ' + data?.creator_info?.last_name}
              </span>
              <span className={styles['span-2']}>{moment(data?.create_date).fromNow()} <img style={{width: '15px', height: '15px', marginTop: '-2px'}} src={data?.status == 'public' ? PublicIcon : PrivateIcon} alt=''/></span>
            </div>
          </div>
          <DropdownEditPost data={data} />
        </div>

        <div className={styles['body']}>
          <div className={styles['title']}>
            <Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Inter',
              }}
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: 'Xem Thêm',
              }}
            >
              {data?.title}
            </Paragraph>
          </div>
          <div>
            <ImagesList images={data?.images} post_id={data?.post_id} />
          </div>
        </div>
        <div className={styles['footer']}>
          <div className={styles['info']}>
            <div className={styles['info-1']}>
              {data?.likes?.length} người <span>đã thích</span>
            </div>
            <div className={styles['info-2']}>
              <span style={{ marginRight: '15px' }}>{data?.comments?.length} bình luận</span>
              <span>{data?.shares?.length || 0} lượt chia sẻ</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 14px 0 14px',
              alignItems: 'center',
            }}
          >
            <InteractionIcons data={data} check={true}/>
            <DropdownInteraction/>
          </div>
        </div>
      </div>
    ))
  )
}

export default Post
