import React from 'react'
import LeftSide from './left-side/leftSide'
import RightSide from './right-side/rightSide'

const Conversation = () => {
  return (
    <div style={{ minWidth: '1000px', padding: '50px', height: 'calc(100vh - 72px)', display: 'flex', flexDirection: 'row' }}>
      <LeftSide />
      <div
        style={{
          width: 'calc(100% - 350px)',
          background: 'white',
          borderRadius: '0px 4px 4px 0px',
          border: '1px solid #DBDBDB',
          borderLeft: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <RightSide />
      </div>
    </div>
  )
}

export default Conversation
