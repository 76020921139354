import { each } from 'lodash'

const initialState = {}
const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USERINFO': {
      state = action.data
      return { ...state }
    }
    case 'UPDATE_USERINFO': {
      each(action.data, (value, field) => {
        state[field] = value
      })
      return { ...state }
    }
    default:
      return state
  }
}
export default userInfo
