/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from '../styles/display-message.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import { imageShow, videoShow } from '../items/items'
import { Tooltip } from 'antd'
import moment from 'moment'
import { DeleteOutlined, MoreOutlined, ShareAltOutlined } from '@ant-design/icons'
import PopupDeleteMessage from './popupDeleteMessage'

const DisplayMessage = ({ showDetail, user, msg, data }) => {
  let dataUser = useSelector((state) => state.userInfo)
  const [visibleDelete, setVisibleDelete] = useState(false)

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
        {msg.sender_id != dataUser.user_id && (
          <img
            src={user.avatar || AvatarDefault}
            alt=""
            style={{ width: '25px', height: '25px', borderRadius: '50%', marginRight: '7px' }}
          />
        )}

        <div className={styles['content-message']}>
          {msg.is_delete === false && user.user_id === dataUser.user_id && (
            <div className={styles['more-options']}>
              <div
                onClick={() => setVisibleDelete(true)}
                style={{
                  height: '25px',
                  width: '25px',
                  borderRadius: '50%',
                  background: 'whitesmoke',
                  border: '1px solid rgb(239 239 239)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '5px',
                }}
              >
                <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: 'red' }} />
              </div>

              <div
                style={{
                  height: '25px',
                  width: '25px',
                  borderRadius: '50%',
                  background: 'whitesmoke',
                  border: '1px solid rgb(239 239 239)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ShareAltOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
              </div>
            </div>
          )}
          <div>
            <div
              className={styles['display-message']}
              style={{
                border: msg.sender_id != dataUser.user_id && '1px solid #DBDBDB',
                background: msg.sender_id == dataUser.user_id && '#DBDBDB',
                borderRadius: '18px',
              }}
            >
              <div className={styles['content']}>
                <Tooltip
                  placement={msg.sender_id != dataUser.user_id ? 'top' : 'top'}
                  title={moment(msg.create_date).format('DD-MM-YYYY hh:mm:ss')}
                >
                  <div
                    className={`${styles['content-text']} ${
                      showDetail === false ? styles['wrap-text'] : styles['wrap-text-2']
                    }`}
                  >
                    {msg.is_delete === true ? (
                      <div className={styles['chat_text_2']}>Tin nhắn đã bị thu hồi</div>
                    ) : (
                      msg.text && (
                        <div className={styles['chat_text']}>
                          {msg?.text?.includes('https://') ? <span>{msg.text.slice(0, msg.text.indexOf('https://'))} <a target="_blank" href={msg.text.slice(msg.text.indexOf('https://'), msg.text.length)}>{msg.text.slice(msg.text.indexOf('https://'), msg.text.length)}</a></span> : <span>{msg.text}</span>}
                          {msg?.media?.find((item) => item.type === 'link') && (
                            <img style={{width: '100%'}} alt="" src={msg?.media[0]?.url} />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              {msg.media.length > 0 && msg.is_delete === true ? (
                <div className={styles['chat_text_2']}>Tin nhắn đã bị thu hồi</div>
              ) : (
                msg.media.map((item, index) => (
                  <div key={index}>
                    {item.type !== 'link' && (item.type !== 'image'
                      ? videoShow(item.url)
                      : imageShow(item.url))}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <PopupDeleteMessage
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        data={data}
        msg={msg}
      />
    </>
  )
}

export default DisplayMessage
