import React from 'react'
import styles from '../styles/right-side.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
  FileImageOutlined,
  HeartOutlined,
  LoadingOutlined,
  PhoneOutlined,
  SmileOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { useState } from 'react'
import { imageShow, MenuIcon, videoShow } from '../items/items'
import { Badge, Dropdown, notification, Tooltip } from 'antd'
import { uploadFile } from 'apis/upload'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage, deleteConversationUser, getMediaMessagesUser, getMessagesUser, loadMoreMessages } from 'redux/actions/message'
import { useRef } from 'react'
import { useEffect } from 'react'
import DisplayMessage from './displayMessage'
import moment from 'moment'
import MoreDetail from './moreDetail'

export const ItemPerson = ({ item }) => {
  if (!item) return <></>
  return (
    <div className={styles['layout-item']}>
      <img
        src={item.avatar || AvatarDefault}
        alt=""
        style={{ width: '25px', height: '25px', marginRight: '7px', borderRadius: '50%' }}
      />
      <div className={styles['child-text']}>
        <span  onClick={() => window.open(`/info-user/${item.user_id}`, "_blank")} className={styles['span-1']}>{item.first_name + ' ' + item.last_name}</span>
        <span className={styles['span-2']}>Hoạt động 7 phút trước</span>
      </div>
    </div>
  )
}

const RightSide = () => {
  const [text, setText] = useState('')
  const [media, setMedia] = useState([])
  const [loadMedia, setLoadMedia] = useState(false)
  const [data, setData] = useState([])
  const [count, setCount] = useState(9)
  const [page, setPage] = useState(0)
  const [user, setUser] = useState([])
  const { id } = useParams()
  const history = useHistory()
  let dataUser = useSelector((state) => state.userInfo)
  const { message, socket } = useSelector((state) => state)
  const dispatch = useDispatch()
  const refDisplay = useRef()
  const pageEnd = useRef()
  const [isLoadMore, setIsLoadMore] = useState(0)
  const [showDetail, setShowDetail] = useState(true)
  const [visibleDeleteConversation, setVisibleDeleteConversation] = useState(false)
  const [openMedia, setOpenMedia] = useState(false)
  const [openLink, setOpenLink] = useState(false)

  useEffect(() => {
    const newData = message.data.find((item) => item.user_id == id)
    if (newData) {
      setData(newData.messages)
      setCount(newData.count)
      setPage(newData.page)
    }
  }, [message.data, id])

  useEffect(() => {
    if (id && message.users.length > 0) {
      setTimeout(() => {
        refDisplay.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 50)

      const newUser = message.users.find((user) => user.user_id == id)
      if (newUser) setUser(newUser)
    }
  }, [message.users, id])

  const handleChangeMedia = (e) => {
    const files = [...e.target.files]
    let err = ''
    let newMedia = []

    files.forEach((file) => {
      if (!file) return (err = 'File does not exist.')

      if (file.size > 1024 * 1024 * 5) {
        return (err = 'The image/video largest is 5mb.')
      }

      return newMedia.push(file)
    })

    if (err) notification.error({ message: err })
    setMedia([...media, ...newMedia])
  }

  const deleteImages = (index) => {
    let newArr = [...media]
    newArr.splice(index, 1)
    setMedia(newArr)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!text.trim() && media.length === 0) return
    setText('')
    setMedia([])
    setLoadMedia(true)

    let newArr = []

    if (media.length > 0) {
      for (let data of media) {
        const url_image = await uploadFile(data)
        let new_data = {
          url: url_image,
          type: data.type.slice(0, 5),
        }
        newArr.push(new_data)
      }
    }

    const msg = {
      sender_id: dataUser.user_id,
      recipient_id: id,
      text,
      media: newArr,
      create_date: new Date().toISOString(),
    }

    setLoadMedia(false)
    await dispatch(
      addMessage({
        msg,
        auth: dataUser,
        socket,
      })
    )
    if (refDisplay.current) {
      refDisplay.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

  }

  const handleDeleteConversation = () => {
      dispatch(deleteConversationUser({auth: dataUser, id}))
      return history.push('/message')
  }

  useEffect(() => {
    const getMessagesData = async () => {
      if (message.data.every((item) => item.user_id != id)) {

        await dispatch(getMessagesUser({ auth: dataUser, id }))
        setTimeout(() => {
          refDisplay.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }, 50)
      }
    }
    getMessagesData()
  }, [id, dispatch, dataUser, message.data])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoadMore((p) => p + 1)
        }
      },
      {
        threshold: 0.1,
      }
    )

    observer.observe(pageEnd.current)
  }, [setIsLoadMore])

  useEffect(() => {
    setOpenMedia(false)
    setOpenLink(false)
  }, [id])

  useEffect(() => {
    if (isLoadMore > 1) {
      if (count >= page * 9) {
        dispatch(loadMoreMessages({ auth: dataUser, id, page: page + 1 }))
        setIsLoadMore(1)
      }
    }
    // eslint-disable-next-line
  }, [isLoadMore])

  return (
    <div className={styles['right-side']}>
      <div className={styles['chat-info']} style={{width: showDetail === true ? 'calc(100% - 249px)' : '100%'}}>
        <div className={styles['header']}>
          <ItemPerson item={user} />
          <div>
            <PhoneOutlined style={{ fontSize: '20px', cursor: 'pointer', marginRight: '10px' }} />
            <VideoCameraOutlined
              style={{ fontSize: '20px', cursor: 'pointer', marginRight: '10px' }}
            />
            <ExclamationCircleOutlined style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => setShowDetail(!showDetail)} />
          </div>
        </div>

        <div
          style={{ height: media.length > 0 ? 'calc(100% - 250px)' : 'calc(100% - 120px)' }}
          className={styles['body']}
        >
          <div className={styles['chat_display']} ref={refDisplay}>
            <button style={{ marginTop: '-15px', opacity: 0 }} ref={pageEnd}>
              Load more message
            </button>

            {data.map((msg, index) => (
              <div key={index}>
                {moment(data[index + 1]?.create_date).unix() -
                  moment(data[index]?.create_date).unix() >
                  15 * 60 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 500,
                      fontSize: '0.75rem',
                      color: '#65676B',
                      marginTop: '7px',
                      marginBottom: '7px',
                    }}
                  >
                    {moment(msg.create_date).format('DD-MM-YYYY hh:mm:ss')}
                  </div>
                )}
                {msg.sender_id != dataUser.user_id && (
                  <div className={`${styles['chat_row']} ${styles['other_message']}`}>
                    <DisplayMessage showDetail={showDetail} user={user} msg={msg} />
                  </div>
                )}

                {msg.sender_id == dataUser.user_id && (
                  <div className={`${styles['chat_row']} ${styles['you_message']}`}>
                    <DisplayMessage showDetail={showDetail} user={dataUser} msg={msg} data={data} />
                  </div>
                )}
              </div>
            ))}

            {loadMedia && (
              <div className={styles['chat_row you_message']}>
                <LoadingOutlined />
              </div>
            )}
          </div>
        </div>
        <div
          className={styles['show_media']}
          style={{
            height: media.length > 0 ? '130px' : '0px',
            display: media.length > 0 ? '' : 'none',
          }}
        >
          {media.map((item, index) => (
            <Badge
              count={
                <CloseCircleFilled
                  onClick={() => {
                    deleteImages(index)
                  }}
                  style={{ fontSize: 18, color: '#313131' }}
                />
              }
            >
              <div key={index} id="file_media">
                {item.type.match(/video/i)
                  ? videoShow(URL.createObjectURL(item), true)
                  : imageShow(URL.createObjectURL(item), true)}
                {/* <span onClick={() => handleDeleteMedia(index)}>&times;</span> */}
              </div>
            </Badge>
          ))}
        </div>

        <div className={styles['footer']}>
          <form className={styles['chat_input']} onSubmit={handleSubmit}>
            <Dropdown
              placement="top"
              trigger="click"
              overlay={<MenuIcon data={text} setData={setText} />}
            >
              <Tooltip placement="top" title="Biểu tượng cảm xúc">
                <SmileOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Tooltip>
            </Dropdown>

            <input
              type="text"
              placeholder="Enter you message..."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />

            <label style={{ cursor: 'pointer', marginRight: '16px' }}>
              <Tooltip placement="top" title="Thêm ảnh/video">
                <FileImageOutlined style={{ fontSize: '20px' }} />
              </Tooltip>
              <input
                type="file"
                name="file"
                id="file"
                multiple
                accept="image/*,video/*"
                onChange={handleChangeMedia}
                style={{ display: 'none' }}
              />
            </label>

            <button
              type="submit"
              className="material-icons"
              disabled={text || media.length > 0 ? false : true}
            >
              <HeartOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
            </button>
          </form>
        </div>
      </div>
      {
        showDetail && (
          <div className={styles['more-detail']}>
            <MoreDetail openLink={openLink} setOpenLink={setOpenLink} openMedia={openMedia} setOpenMedia={setOpenMedia} dataUser={dataUser} id={id} user={user} setVisible={setVisibleDeleteConversation} visible={visibleDeleteConversation} handleDeleteConversation={handleDeleteConversation}/>            
          </div>
        )
      }

    </div>
  )
}

export default RightSide
