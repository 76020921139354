import React from 'react';
import { Pie } from '@ant-design/charts';

const DemoPie = () => {
  var data = [
    {
      type: 'Cửa hàng quần áo',
      value: 100,
    },
    {
      type: 'F&B',
      value: 25,
    },
    {
      type: 'Ngành hàng tiêu dùng',
      value: 190,
    },
    {
      type: 'Điện tử công nghệ',
      value: 15,
    },
    {
      type: 'Các loại dịch vụ khác',
      value: 10,
    },
  ];
  var config = {
    color: ({ type }) => {
        if(type === 'Cửa hàng quần áo'){
            return '#1E4DB7';
        }
        if(type === 'F&B'){
            return '#3B6CDC';
        }
        if(type === 'Ngành hàng tiêu dùng'){
            return '#6B98FD';
        }
        if(type === 'Điện tử công nghệ'){
            return '#ABC5FF';
        }
          return '#D6E2FF';
    },
    appendPadding: 6,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  return <Pie {...config} />;
};

export default DemoPie;