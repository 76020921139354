import React, { useEffect, useState } from 'react'
import { Button, Modal, Card, Skeleton, notification, Row, Col } from 'antd'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
//components
import { ButtonCustom } from 'components/Button/Button'

//apis
import { getOrderCustomer, getBranchs } from 'apis/shopping-dairy'
import { syncPoint } from 'apis/point'
const ConnectHistorySell = (props) => {
  const { customer, phone, name, setHidenSync, historyOrder, countHistoryOrder } = props
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [branchs, setBranchs] = useState([])
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const getBranchsBusiness = async () => {
    try {
      const res = await getBranchs({ business_phone: phone })
      if (res.status === 200) {
        setBranchs(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const syncPointUser = async () => {
    try {
      let body = {
        time_stamp: moment().format(),
        business_phone: phone,
        customer_phone: customer?.phone,
        funtion_id: 11,
      }
      const res = await syncPoint(body)
      if (res.status === 200) {
        if (res.data.success === true) {
          notification.success({ message: res.data.message || 'Đồng bộ thành công' })
        } else notification.warning({ message: res.data.message || 'Đồng bộ không thành công' })
        handleCancel()
        setHidenSync(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBranchsBusiness()
  }, [phone])
  return (
    <>
      <ButtonCustom functionOnClick={showModal} classes="btn__blue" text="Đồng bộ điểm" />
      <Modal
        width={700}
        title={`Đơn hàng của bạn tại ${name}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Row gutter={12}>
            <Col span={12}>
              <ButtonCustom functionOnClick={handleCancel} classes="btn__blue" text="Đóng" />
            </Col>
            <Col span={12}>
              <ButtonCustom functionOnClick={syncPointUser} classes="btn__blue" text="Đồng bộ điểm" />
            </Col>
          </Row>,
        ]}
      >
        <div
          id="scrollableDiv"
          style={{
            height: 350,
            overflow: 'auto',
            backgroundColor: '#DCDCDC',
          }}
        >
          <InfiniteScroll
            dataLength={historyOrder.length}
            hasMore={false}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            scrollableTarget="scrollableDiv"
          >
            {countHistoryOrder > 0 &&
              historyOrder.map((item, index) => (
                <Card
                  bodyStyle={{ padding: 10 }}
                  size="small"
                  key={index}
                  style={
                    item.bill_status == 'COMPLETE'
                      ? { backgroundColor: '#F6FFED', width: '100%', marginTop: 7, marginBottom: 7 }
                      : { marginTop: 7, marginBottom: 7, width: '100%' }
                  }
                >
                  {branchs.map(
                    (branch) =>
                      branch.branch_id == item.branch_id && (
                        <p style={{ marginBottom: 5 }}>
                          <strong>
                            Mua hàng tại {branch.name} - {branch.address}{' '}
                          </strong>
                        </p>
                      )
                  )}
                  <div>
                    <p
                      style={
                        item.bill_status == 'COMPLETE'
                          ? { backgroundColor: '#B7EB8F', marginBottom: 0, textAlign: 'center' }
                          : { textAlign: 'center' }
                      }
                    >
                      {item.bill_status}
                    </p>
                    <p style={{ marginBottom: 0 }}>Mã đơn hàng: {item.code}</p>
                    <p style={{ marginBottom: 0 }}>
                      Đặt hàng: {moment(item.create_date).format('YYYY-MM-DD hh:mm')}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      Điểm: {item.increasePoint - item.decreasePoint}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      Thành tiền: {String(item.final_cost).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}đ
                    </p>
                  </div>
                </Card>
              ))}
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  )
}
export default ConnectHistorySell
