/* eslint-disable jsx-a11y/alt-text */
import { Button, Checkbox } from 'antd'
import React, { useState } from 'react'
import styles from '../styles/cart-footer.module.scss'
import defaultLogo from 'assets/img/store-default.png'
import DiscountInfo from '../items/discount-info'
import Purchase from '../items/purchase'
import { formatCash } from 'utils'

const CartFooter = ({cart, orders, handleAddAllOrders }) => {
  // console.log(cart)
  return (
    <div style={{ position: 'sticky', bottom: 0 }}>
      <div
        style={{
          background: 'linear-gradient(transparent,rgba(0,0,0,.06))',
          height: '1.25rem',
        }}
      ></div>
      <div
        style={{
          boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.25)',
          width: '100%',
        }}
      >
        <div className={styles['cart-footer-1']}>
          <div className={styles['item-1']}>
            <Checkbox />
          </div>
          <div className={styles['item-2']}>
            <span>
              <img src={defaultLogo} /> Ekata Points
            </span>
          </div>
          <div className={styles['item-3']}>
            <span>Dùng 567 Ekata Point</span>
          </div>
          <div className={styles['item-4']}>
            <span>-5.670đ</span>
          </div>
        </div>
        <div className={styles['cart-footer-2']}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '30%',
            }}
          >
            <div className={styles['item-1']}>
              <Checkbox checked={orders.length > 0 ? true : false} onChange={handleAddAllOrders} />
            </div>
            <div className={styles['item-2']}>
              <span>Chọn tất cả {`(${cart.length})`}</span>
            </div>
            <div className={styles['item-3']}>
              <span>Xoá tất cả</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              width: '70%',
            }}
          >
            <div className={styles['item-4']}>
              <div>
                <span className={styles['span-1']}>Tổng thanh toán {`(${orders.length})`}: </span>
                <span className={styles['span-2']}>{`${formatCash(orders.reduce((total, item) => total += item.carts.reduce((total2, item2) => total2 += item2.quantity * item2.price, 0), 0))}`}đ</span>
              </div>
              <div>
                <DiscountInfo />
              </div>
            </div>
            <div className={styles['item-5']}>
              <Purchase orders={orders}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartFooter
