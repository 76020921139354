import React from 'react'
import { Button, Col, Image, Row, Space } from 'antd'
import { useHistory } from 'react-router-dom'
export const ImagesList = ({ images, post_id }) => {
  const history = useHistory()
  let newImages = images?.filter((item) => item.url.length)
  if (!newImages || newImages.length === 0) return <></>
  if (newImages?.length === 1) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          {newImages[0].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              src={newImages[0].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              controls
              src={newImages[0].url}
              alt=""
            />
          )}
        </Col>
      </Row>
    )
  } else if (newImages?.length === 2) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          {newImages[0].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '100%',
                paddingRight: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[0].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '100%',
                paddingRight: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[0].url}
              alt=""
            />
          )}
        </Col>
        <Col span={12}>
          {newImages[1].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '100%',
                paddingLeft: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[1].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '100%',
                paddingLeft: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[1].url}
              alt=""
            />
          )}
        </Col>
      </Row>
    )
  } else if (newImages?.length === 3) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          {newImages[0].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '100%',
                paddingRight: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[0].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '100%',
                paddingRight: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[0].url}
              alt=""
            />
          )}
        </Col>
        <Col span={12}>
          {newImages[1].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[1].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[1].url}
              alt=""
            />
          )}
          {newImages[2].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[2].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[2].url}
              alt=""
            />
          )}
        </Col>
      </Row>
    )
  } else if (newImages?.length === 4) {
    return (
      <Row style={{ background: '#f0f0f0' }} justify="center">
        <Col span={12}>
          {newImages[0].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingBottom: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[0].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingBottom: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[0].url}
              alt=""
            />
          )}
          {newImages[1].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingTop: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[1].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingTop: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[1].url}
              alt=""
            />
          )}
        </Col>
        <Col span={12}>
          {newImages[2].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[2].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[2].url}
              alt=""
            />
          )}
          {newImages[3].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[3].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[3].url}
              alt=""
            />
          )}
        </Col>
      </Row>
    )
  } else {
    return (
      <Row style={{ background: '#f0f0f0', height: '100%' }} justify="center">
        <Col span={12}>
          {newImages[0].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingBottom: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[0].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingBottom: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[0].url}
              alt=""
            />
          )}
          {newImages[1].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingTop: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[1].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingRight: '1px',
                paddingTop: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[1].url}
              alt=""
            />
          )}
        </Col>
        <Col span={12}>
          {newImages[2].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[2].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingBottom: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[2].url}
              alt=""
            />
          )}
          {newImages[3].type === 'image' ? (
            <img
              onClick={() => {
                history.push(`/detail-post/${post_id}`)
              }}
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              src={newImages[3].url}
              alt=""
            />
          ) : (
            <video
              style={{
                width: '100%',
                height: '50%',
                paddingLeft: '1px',
                paddingTop: '1px',
                objectFit: 'cover',
              }}
              controls
              src={newImages[3].url}
              alt=""
            />
          )}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              width: '100%',
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: '1px',
              paddingTop: '1px',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: 40, color: 'white' }}>
              +{newImages.length - 3}
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}
