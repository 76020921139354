import React, { useState } from 'react'
import styles from './register-business.module.scss'
import { Button, Col, Form,  notification, Row, Upload, message } from 'antd'
import {  verificationBusiness } from 'apis/business'
import { uploadFile } from 'apis/upload'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import ImgCrop from 'antd-img-crop';
import { myBusiness } from 'apis/business'
import { useDispatch, useSelector } from 'react-redux'

const VerifyBusiness = ({ form, gotoStep, current, formLoading, formData }) => {
  const dataUser = localStorage.getItem('accessToken')
    ? jwtDecode(localStorage.getItem('accessToken'))
    : {}
  const [businessRegisterImage, setBusinessRegisterImage] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  let myBusinessNew = useSelector((state) => state.myBusiness)
  let myBusiness = myBusinessNew.find((item) => item.user_id === item.creator_id)
  const { subdomain } = myBusiness
  const formConf = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  }

  const props = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions = ['jpg', 'jpeg', 'png','svg']
      if (allowedExtensions.includes(extension)) {
        const { status } = info.file
        if (status === 'done') {
          setBusinessRegisterImage(info.file.response.url)
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } else {
        notification.warning({ key: 'fail file', message: 'Yêu cầu nhập file đúng định dạng' })
      }
    },
  }
  const _verifyBusiness = async () => {
    try {
      if (form != undefined) {
        await form.validateFields()
      }
      if(businessRegisterImage !== ''){
        var body = {
          business_phone:
            (formData && formData.business_phone && formData.business_phone) || dataUser.data.phone,
          business_registration_image: businessRegisterImage,
        }
        const res = await verificationBusiness(body)
        if (res.status === 200 && res.data.status) {
          notification.success({ message: res.data.message })
          gotoStep ? gotoStep(current + 1) : history.push(ROUTES.FINISHED_BUSINESS)
          const loadBusiness = await myBusiness()
          dispatch({
            type: 'SET_MY_BUSINESS',
            data: loadBusiness.data.data,
          })
        } else {
          notification.warning({
            message: res.data.message,
          })
        }
      }else{
        notification.warning({message: 'Vui lòng cung cấp ảnh đăng ký kinh doanh'})
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Row>
      <div id="TTKD" className={styles['card-overview']}>
        <Form form={form}>
          <Col>
            <Form.Item label="Xác nhận SĐT" {...formConf}>
              <span style={{ fontSize: 16 }}>
                {(formData && formData.business_phone && formData.business_phone) ||
                  dataUser.data.phone}
              </span>
            </Form.Item>
          </Col> 
          <Col>
            <Form.Item label="Đăng ký kinh doanh" name="business_registration_image" {...formConf} 
           
            >
              <ImgCrop>
              <Upload
              {...props}
              onRemove={() => {
                setBusinessRegisterImage('')
              }}
                className={styles['ant-upload-select']}
              >
                {businessRegisterImage ? (
                  <img
                    src={businessRegisterImage}
                    alt="Đăng ký kinh doanh"
                    style={{
                      width: '100%',
                      height: 178,
                      objectFit: 'cover',
                      borderRadius: 10,
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <div>
                    <div className={styles['upload-image-text']}>
                      Tải ảnh giấy đăng ký kinh doanh lên
                    </div>
                  </div>
                )}
              </Upload>
              </ImgCrop>
              
            </Form.Item>
          </Col>
          <div className={styles['containerItemUpload']} />
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            * Vui lòng liên hệ số điện thoại: 0967487179 để được tạo cửa hàng cho phiên bản dùng thử
            của EKATA
          </span>
          <Form.Item style={{ marginTop: 40, textAlign: 'center' }}>
            <Button
              className={styles['register-business-btn']}
              type="primary"
              loading={formLoading}
              onClick={_verifyBusiness}
            >
              Xác thực cửa hàng
            </Button>
            <a href={['http://', subdomain, '.', process.env.REACT_APP_POS_HOST].join('')}
            target="_blank"
            rel="noreferrer">
            <Button type="primary" className={styles['visit-business-btn']}>
              Vào cửa hàng
            </Button>
            </a>
          </Form.Item>
        </Form>
      </div>
    </Row>
  )
}

export default VerifyBusiness
