import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'

const MoreDetailLink = ({allLink, setOpenLink}) => {
  return (
    <div style={{ width: '100%', height: 'calc(100% - 150px)' }}>
      <div style={{ padding: '0 10px' }}>
        <span
          onClick={() => setOpenLink(false)}
          style={{ fontSize: '15px', fontWeight: 500, cursor: 'pointer' }}
        >
          <ArrowLeftOutlined /> Quay lại
        </span>
      </div>

    </div>
  )
}

export default MoreDetailLink