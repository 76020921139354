import removeVietnameseTones from '../../utils/removeUnicode'
export const EditData = (data, id, post) => {
    const newData = data.map(item => 
        (item.post_id == id ? post : item)
    )
    return newData;
}

export const DeleteData = (data, id) => {
    const newData = data.filter(item => item.post_id != id)
    return newData;
}

export const SearchData = (data, value) => {
    let filteredData = [] 
    if(value.length > 0) {
        filteredData = data.filter((item) => {
            let newObject = { name: item?.products?.name, sku: item?.products?.sku, barcode: item?.barcode, business_name: item?.business?.business_name }
            return removeVietnameseTones(Object.values(newObject).join('').toLowerCase()).includes(value.toLowerCase().trim())
        })
    } else {
        filteredData = data
    }
    return filteredData
}