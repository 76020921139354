import React, { useState } from 'react'
import styles from '../voucher.module.scss'
import { formatCash } from 'utils'
import { Card, Col, Row, Badge, Button, Tag, notification, Pagination, Input } from 'antd'
import { MoneyCollectOutlined, SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import DetailVoucher from './detail-voucher/detail-voucher'
import { deleteVoucher, getVoucherPageSize } from 'redux/actions/voucher'
import { useEffect } from 'react'
import ImageDefault from 'assets/img/logo-icon.png'

const MyVoucher = ({ tabs }) => {
  const { my_voucher, my_voucher_count } = useSelector((state) => state.voucher)
  const [search, setSearch] = useState('')
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
    type: 'me'
  })
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.userInfo.user_id)

  const handleSearch = (e) => {
    if (e.target.value) {
      paramsFilter['search'] = e.target.value
    }
    else {
      delete paramsFilter['search']
    }
    setParamsFilter({ ...paramsFilter })
  }

  useEffect(() => {
    dispatch(getVoucherPageSize({ paramsFilter }))
  }, [paramsFilter])

  useEffect(() => {
    if (tabs == 2) {
      setParamsFilter({
        page: 1,
        page_size: 20,
        type: 'me'
      })
      setSearch('')
    }
  }, [tabs])

  return (
    <div>
      <Row style={{ marginBottom: '20px' }} justify="space-between">
        <Col>
          <Input
            placeholder="Tìm kiếm voucher"
            suffix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.45)',
                }}
              />
            }
            value={search}
            onInput={(e) => setSearch(e.target.value)}
            onPressEnter={handleSearch}
          />
        </Col>
        <Col>
          <Pagination
            current={paramsFilter.page}
            size="small"
            showSizeChanger={false}
            total={my_voucher_count}
            pageSize={15}
            onChange={(page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter, page_size: 20 })
            }}
          />
        </Col>
      </Row>
      <div style={{ overflowY: 'scroll', height: '670px' }}>
        <Row style={{ padding: 10 }}>
          {my_voucher &&
            my_voucher?.map((item, index) => (
              <Col style={{ height: '140px' }} lg={24} xl={12} xxl={12} key={index}>
                <Badge.Ribbon
                  color="#ffc069"
                  placement="start"
                  style={{
                    width: '80px',
                    height: '32px',
                    lineHeight: '15px',
                    borderWidth: '149px',
                  }}
                  text={
                    <div>
                      <div style={{ textAlign: 'center', marginTop: '1px', fontSize: '0.75rem' }}>
                        GIẢM
                      </div>
                      {item.type === 'PERCENT' ? (
                        <div
                          style={{
                            textAlign: 'center',
                            color: 'red',
                            fontWeight: 700,
                            fontSize: '0.65rem',
                          }}
                        >
                          {item.value}%
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: 'center',
                            color: 'red',
                            fontWeight: 'bold',
                            fontSize: '0.65rem',
                          }}
                        >
                          {formatCash(item.value)}đ
                        </div>
                      )}
                    </div>
                  }
                >
                  <Card
                    disabled
                    bodyStyle={{ padding: '0px 0px 0px 0px' }}
                    className={styles['card-voucher']}
                  >
                    <Row gutter={[10, 0]}>
                      <Col span={9}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          {item.voucher_image ? (
                            <img className={styles['img-voucher']} alt="example" src={item.voucher_image} />
                          ) : (
                            <img
                              className={styles['img-voucher-default']}
                              alt="example"
                              src={ImageDefault}
                            />
                          )}
                        </div>
                      </Col>
                      <Col style={{ marginTop: '.8rem' }} span={11}>
                        <div
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '.75rem',
                            color: '#6C757D',
                          }}
                        >
                          Cửa hàng {item.business_info?.business_name?.toUpperCase()}
                        </div>
                        <Tag className={styles[`tag-code`]} color="default">
                          Mã KM: {item.promotion_code}
                        </Tag>
                        <div style={{ fontFamily: 'Inter', fontSize: '.75rem' }}>
                          Mã voucher sử dụng cho...
                        </div>
                        <Tag className={styles[`tag-money`]} style={{ marginTop: 10, marginBottom: 5 }} color="red">
                          Hoá đơn trên {formatCash(item.order_value_require)} VNĐ
                        </Tag>
                        <p
                          style={{
                            fontFamily: 'Inter',
                            color: '#6C757D',
                            fontSize: '.65rem',
                          }}
                        >
                          <MoneyCollectOutlined /> Giảm{' '}
                          {item.type === 'PERCENT' ? (
                            <span
                              style={{
                                fontFamily: 'Inter',
                                color: '#6C757D',
                                fontSize: '.65rem',
                              }}
                            >
                              {item.value}% tối đa {formatCash(item.max_discount_value)}đ
                            </span>
                          ) : (
                            <span
                              style={{
                                fontFamily: 'Inter',
                                color: '#6C757D',
                                fontSize: '.65rem',
                              }}
                            >
                              {formatCash(item.value)}đ
                            </span>
                          )}
                        </p>
                      </Col>

                      <Col span={4}>
                        <Button
                          onClick={() => {
                            dispatch(deleteVoucher({ voucher: item, user_id: userId }))
                          }}
                          style={{
                            boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
                            top: '10px',
                          }}
                          size="middle"
                          type="danger"
                        >
                          Xoá
                        </Button>

                        <div style={{ marginTop: '50px' }}>
                          <DetailVoucher item={item} />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  )
}

export default MyVoucher
