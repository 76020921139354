import React, { useState } from 'react'
import styles from '../notification.module.scss'
import moment from 'moment'
import NotificationMenuItem from '../notification-menu-item'
import { Button, Space } from 'antd'
import { useSelector } from 'react-redux'

export const Comment = ({item}) => {
  let user = useSelector((state) => state.userInfo)

  if(item?.tag_id !== user?.user_id) return <></>
  if(item?.user_id === item?.tag_id) return <></>
    return (
          <div
            className={
              item.status != 0
                ? styles['notifications__item']
                : styles['notifications__item__seen']
            }
          >
            <div className={styles['notifications__item__avatar']}>
              <img src={item?.user_info?.avatar || 'https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU='} />
            </div>

            <div className={styles['notifications__item__content']}>
              <span className={styles['notifications__item__message']}>
                {
                  item?.tag_id !== null && <strong>{item?.user_info?.first_name + " " + item?.user_info?.last_name}</strong>
                } nhắc đến bạn trong một bình luận
              </span>
              <div>
                <small>{moment(item.create_date).fromNow()}</small>
              </div>

            </div>

            <div className={styles['notifications__item__button']}>
              <NotificationMenuItem notification_staff_id={item.notification_staff_id} />
            </div>
          </div>

    )
}