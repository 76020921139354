import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import styles from './forget-password.module.scss'
import ButtonCustom from 'components/Button/buttonCustom'
import CarouselCustom from 'components/carousel/carouselCustom'
import { ConfigProvider, Form, Input, Select, notification } from 'antd'
import BgAuth from '../../../assets/img/bg-auth.png'
import { Link, useHistory } from 'react-router-dom'
import { forgetPasswordSendOtp } from 'apis/auth'
import { ACTION, ROUTES } from 'consts'
import { useDispatch } from 'react-redux'
import PrefixSelector from 'components/prefix/prefixSelector'

const ForgetPassword = () => {
  const [formForgetPassword] = Form.useForm()

  let history = useHistory()
  const dispatch = useDispatch()

  const _sendOtp = async () => {
    try {
      await formForgetPassword.validateFields()
      const dataForm = formForgetPassword.getFieldsValue()
      const res = await forgetPasswordSendOtp(dataForm.phone)

      if (res.status === 200 && res.data.status) {
        notification.success({message: res.data.message})
        history.push({
          pathname: ROUTES.OTP,
          state: {
            phone: dataForm.phone,
            action: 'FORGET_PASSWORD',
          },
        })
      } else {
        if (res.data.type === 'phone') {
          formForgetPassword.setFields([
            {
              name: 'phone',
              errors: [res.data.message],
            },
          ])
        }
        if (res.data.type === 'otp') {
            history.push({
                pathname: ROUTES.OTP,
                state: {
                  phone: dataForm.phone,
                  action: 'FORGET_PASSWORD',
                },
              })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
          colorTextPlaceholder: '#667085',
          colorPrimary: '#335BD3',
        },
      }}
    >
      <div className={styles['layout-forget-password']}>
        <div style={{ background: `url(${BgAuth})` }} className={styles['layout-carousel']}>
          <CarouselCustom />
        </div>
        <div className={styles['layout-forget-password-form']}>
          <div className={styles['form-forget-password-responsive']}>
            <p className={styles['title']}>Đặt lại mật khẩu</p>
            <p className={styles['note']}>
              Nhập số điện thoại mà bạn đã sử dụng khi đăng ký để khôi phục mật khẩu. Bạn sẽ nhận
              được một mã xác nhận đặt lại mật khẩu.
            </p>

            <Form
              requiredMark={false}
              form={formForgetPassword}
              onFinish={_sendOtp}
              layout="vertical"
              style={{ width: '100%' }}
            >
              <Form.Item
                style={{ marginBottom: '24px' }}
                label={<p className={styles['label-style']}>Số điện thoại</p>}
                name="phone"
                rules={[
                  { required: true, message: 'Vui lòng nhập số điện thoại', whitespace: true },
                  {
                    pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
                    message: 'Vui lòng nhập số điện thoại đúng định dạng',
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input
                  prefix={<PrefixSelector/>}
                  allowClear
                  className={styles['input-item']}
                  size="large"
                  placeholder="Nhập số điện thoại"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <ButtonCustom htmlType="submit" text={'Tiếp tục'} />
              </Form.Item>
            </Form>
            <div
              style={{
                marginTop: '32px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link to="/login">
                <span className={styles['link']}>Trở về đăng nhập</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default ForgetPassword
