// const initialState = []

import { DeleteData, EditData, SearchData } from "redux/actions/global";

const initialState = {
  loading: false,
  posts: [],
  postsFilter: [],
  result: 0,
  page: 2
}


const postsProduct = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_POSTS_USER': 
    return {
      ...state,
      postsFilter: action.payload,
      posts: action.payload,
    };
    case 'CREATE_POST_USER':
        return {
            ...state,
            posts: [action.payload, ...state.posts]
        };
    case 'UPDATE_POST_USER':
      return {
        ...state,
        postsFilter: EditData(state.posts, action.payload.post_id, action.payload),
        posts: EditData(state.posts, action.payload.post_id, action.payload),
    };
    case 'DELETE_POST_USER':
      return {
          ...state,
          postsFilter: DeleteData(state.posts, action.payload.post_id),
          posts: DeleteData(state.posts, action.payload.post_id)
      };
    case 'SEARCH_POSTS_USER': 
    return {
      ...state,
      postsFilter: SearchData(state.posts, action.payload),
    };
    default:
      return state
  }
}
export default postsProduct
