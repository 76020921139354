import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles/status.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import IconImage from 'assets/icons/icon_image.png'
import { Divider, notification, Typography } from 'antd'
import { useState } from 'react'
import ModalStatus from './modalStatus'
import { createPostUser } from 'redux/actions/postsUser'
const { Paragraph } = Typography
const Status = () => {
  const dispatch = useDispatch()
  let dataUser = useSelector((state) => state.userInfo)
  const [visible, setVisible] = useState(false)

  const [images, setImages] = useState([])
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('public')

  const handleChangeImages = (e) => {
    const files = [...e.target.files]
    let newImages = []

    files.forEach((file) => {
      if (!file) return notification.error({ message: 'File không tồn tại' })

      if (file.size > 1024 * 1024 * 5) {
        return notification.error({ message: 'The image/video largest is 5mb.' })
      }
      return newImages.push(file)
    })
    if(images.length > 0) {
      newImages = newImages.concat(images)
  }
    setImages([...newImages])
  }
  
  const deleteImages = (index) => {
    let newArr = [...images]
    newArr.splice(index, 1)
    setImages(newArr)
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    dispatch(createPostUser({title, images, status, user: dataUser}))

    setTitle('')
    setImages([])
    setVisible(false)
}

  return (
    <>
      <div className={styles['status']}>
        <div className={styles['status-header']}>
          <div className={styles['status-avatar']}>
            <img
              style={{ width: '40px', height: '40px' }}
              alt=""
              src={dataUser?.avatar || AvatarDefault}
            />
          </div>
          <div
            onClick={() => {
              setVisible(true)
            }}
            className={styles['status-info']}
          >
            <Paragraph style={{color: '#A0A0A0', fontSize: '14px', fontWeight: 600, fontFamily: 'Inter', fontStyle: 'normal', marginBottom: 0}} className={styles['status-text']} ellipsis={{rows: 2}}>{title.length > 0 ? title : 'Hôm nay bạn thế nào?'}</Paragraph>
          </div>
        </div>
        <Divider style={{ marginTop: '14px', marginBottom: '8px' }} />
        <div className={styles['status-media']}>
          <div className={styles['media-button']}>
            <span>
              <img src={IconImage} alt="" />
              Ảnh/video
            </span>
          </div>
          <div className={styles['media-button']}>
            <span>
              <img src={IconImage} alt="" />
              Ảnh/video
            </span>
          </div>
        </div>
      </div>

      <ModalStatus
        visible={visible}
        setVisible={setVisible}
        title={title}
        setTitle={setTitle}
        status={status}
        setStatus={setStatus}
        images={images}
        setImages={setImages}
        handleChangeImages={handleChangeImages}
        deleteImages={deleteImages}
        handleSubmitForm={handleSubmitForm}
      />
    </>
  )
}

export default Status
