/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Button, Divider, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import styles from './layout.module.scss'
import defaultLogo from 'assets/img/logo-icon.png'
import { socketAdmin, socketRegister } from 'socket'
import { getMyBusiness } from 'redux/actions/my-business'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import AddBusiness from 'assets/img/add-business.png'

function MyBusiness({ collapsed, showBusiness, setShowBusiness }) {
  let myBusiness = useSelector((state) => state.myBusiness)
  const dispatch = useDispatch()
  const [showMore, setShowMore] = useState(3)

  const socketListenerAdmin = () => {
    socketAdmin.on(`business:updateAdmin`, () => dispatch(getMyBusiness()))
    socketRegister.on(`business:updateAdmin`, () => dispatch(getMyBusiness()))
  }

  useEffect(() => {
    socketListenerAdmin()
  }, [])

  const displayBusinessItem = (list) => {
    return list.map((business, index) => {
      const { subdomain, logo, name, business_id } = business
      if (index >= showMore) return null
      if(business.role_id === 1){
        return business.profile_status === 0 ? (
          <Link to={{ pathname: `register-business`, state: { current: 1 } }}>
            <div key={Math.random()} className={styles['business-item']}>
              {collapsed ? (
                <Tooltip placement="right" title={name}>
                  <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
                </Tooltip>
              ) : (
                <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
              )}
  
              {collapsed ? (
                ''
              ) : (
                <span
                  style={
                    business &&
                    business.user_id &&
                    business.creator_id &&
                    business.user_id === business.creator_id
                      ? { textDecoration: 'underline' }
                      : {}
                  }
                  className={styles['business-name']}
                >
                  {name}
                </span>
              )}
            </div>
          </Link>
        ) : business.profile_status === 1 ? (
          <Link to={{ pathname: `register-business`, state: { current: 2 } }}>
            <div key={Math.random()} className={styles['business-item']}>
              {collapsed ? (
                <Tooltip placement="right" title={name}>
                  <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
                </Tooltip>
              ) : (
                <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
              )}
  
              {collapsed ? (
                ''
              ) : (
                <span
                  style={
                    business &&
                    business.user_id &&
                    business.creator_id &&
                    business.user_id === business.creator_id
                      ? { textDecoration: 'underline' }
                      : {}
                  }
                  className={styles['business-name']}
                >
                  {name}
                </span>
              )}
            </div>
          </Link>
        ) : (
          <div key={Math.random()} className={styles['business-item']}>
            <a
              href={['http://', subdomain, '.', process.env.REACT_APP_POS_HOST].join('')}
              target="_blank"
              rel="noreferrer"
            >
              {collapsed ? (
                <Tooltip placement="right" title={name}>
                  <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
                </Tooltip>
              ) : (
                <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
              )}
  
              {collapsed ? (
                ''
              ) : (
                <span
                  style={
                    business &&
                    business.user_id &&
                    business.creator_id &&
                    business.user_id === business.creator_id
                      ? { textDecoration: 'underline' }
                      : {}
                  }
                  className={styles['business-name']}
                >
                  {name}
                </span>
              )}
            </a>
          </div>
        )
      }else {
        return (
        <div key={Math.random()} className={styles['business-item']}>
            <a
              href={['http://', subdomain, '.', process.env.REACT_APP_POS_HOST].join('')}
              target="_blank"
              rel="noreferrer"
            >
              {collapsed ? (
                <Tooltip placement="right" title={name}>
                  <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
                </Tooltip>
              ) : (
                <img src={logo ? logo : defaultLogo} className={styles['business-logo']} alt="" />
              )}
  
              {collapsed ? (
                ''
              ) : (
                <span
                  style={
                    business &&
                    business.user_id &&
                    business.creator_id &&
                    business.user_id === business.creator_id
                      ? { textDecoration: 'underline' }
                      : {}
                  }
                  className={styles['business-name']}
                >
                  {name}
                </span>
              )}
            </a>
          </div>
        )
      }
      
    })
  }

  const ShowMore = () => {
    if (showMore > myBusiness.length) return null

    return (
      <div>
        <Button
          className={styles['show-more-business']}
          size="small"
          style={{
            fontSize: collapsed ? '11px' : '',
            width: collapsed ? '53px' : '90%',
            height: '100%',
            marginLeft: collapsed ? '-7px' : '',
          }}
          onClick={() => setShowMore(showMore + 3)}
        >
          {collapsed ? (
            <>
              Xem <br></br>Thêm
            </>
          ) : (
            'Xem thêm'
          )}
        </Button>
      </div>
    )
  }
  return (
    <>
      {collapsed ? (
        <Divider style={{ marginTop: 0, marginBottom: '7px' }} />
      ) : (
        <Divider style={{ margin: 0 }} plain>
          <div style={{ cursor: 'pointer' }} className="master-menu">
            Cửa hàng
          </div>
        </Divider>
      )}
      {myBusiness.find((item) => item.user_id === item.creator_id) ? (
        ''
      ) : collapsed ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <Link to="/register-business">
          <img src={AddBusiness} />
        </Link>
        </div>

      ) : (
        <div className={styles['new-business-block']}>
          <Link to="/register-business">
            <Button style={{ color: 'white' }} className={styles['new-business-btn']}>
              Tạo cửa hàng
            </Button>
          </Link>
        </div>
      )}

      <div
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          transition: 'all 0.5s linear',
          opacity: showBusiness == false ? 0 : 1,
          paddingLeft: '24px',
          height: '200px',
        }}
        className={styles['business-list']}
      >
        {displayBusinessItem(myBusiness)}
        <ShowMore />
      </div>
    </>
  )
}

export default MyBusiness
