import React, { useEffect, useState } from 'react'
import { HeartOutlined, MessageOutlined, ShareAltOutlined } from '@ant-design/icons'
import CommentAndFeedBacks from '../comment-feedbacks'
import UserNewFeedImg from 'assets/img/user-new-feed.png'
import LogoIcon from 'assets/img/store-default.png'
import { SubscriberCard } from 'components/card/card'
import { ButtonCustom } from 'components/Button/Button'
import styles from './feeds.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getPostsSystem } from 'redux/actions/postsProduct'
import moment from 'moment'
import { ImagesPost } from './items/imagesPost'
import { formatCash } from 'utils'
import { BodyPost } from './cardPost/bodyPost'
import { FooterPost } from './cardPost/footerPost'
import { useParams } from 'react-router-dom'


function NewFeeds({setCarts, carts}) {
  const [newPost, setNewPost] = useState([])
  const {id} = useParams()
  const {posts, postsFilter} = useSelector((state) => state.postsProduct)

  const dispatch = useDispatch()

  const PostItem = ({item}) => {
    const [showComment, setShowComment] = useState(false)
    if(!item) return <></>
    return (
      <div
        style={{
          background: '#fff',
          padding: '25px 0',
          marginBottom: '25px',
          borderRadius: 10,
        }}
    >
      <div style={{position: 'relative'}} className={styles['container']}>
        <SubscriberCard
          classes="subscriber-feeds"
          name={item?.business?.business_name}
          description={moment(item?.create_date).fromNow()}
          avatar={{
            status: true,
            image:
              item?.business?.business_logo?.length > 0 ? item.business.business_logo : LogoIcon,
          }}
          id={id}
          business_id={item?.business?.business_id}
          item={item}
          prefix={item?.business?.prefix}
        >
          <ButtonCustom classes="btn__blue" text="+ Theo dõi" />
        </SubscriberCard>

        <div style={{position: 'relative'}}>
          <ImagesPost images={item.products?.images} />
        </div>
      </div>

      <BodyPost item={item} />
      <FooterPost item={item} setCarts={setCarts} carts={carts} showComment={showComment} setShowComment={setShowComment} />

      <CommentAndFeedBacks item={item} showComment={showComment} />
    </div>
    )
  } 

  useEffect(() => {
    dispatch(getPostsSystem())
  }, [])

  useEffect(() => {
    if(id) {
      let newData = [...posts]
      newData = newData.filter(item => item.business_id == id)
      setNewPost(newData)
    } else {
      setNewPost([])
    }
  }, [id, posts])

  return (
      <div>
      {(newPost?.length > 0 ? newPost : postsFilter)?.map((item, index) => item && (
        <PostItem key={index} item={item}/>
      ))}
    </div>


  )
}

export default NewFeeds
