import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { _getCartAllBusiness } from 'redux/actions/cart'
import styles from './layout.module.scss'
import jwt_decode from 'jwt-decode'
import CartData from './MyCart'

const MyCart = () => {
  const dispatch = useDispatch()
  const dataUser = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken'))
    : {}
  const cart = useSelector(state => state.cart)

  useEffect(() => {
    if(dataUser) {
      dispatch(_getCartAllBusiness({phone: dataUser.data.phone}))
    }
  },[])

    return (
        <div>
        <div className={styles['cart-box']}>
          <div className={styles['title']}>
            <div>Giỏ hàng của bạn</div>
            <div>
              <Link to="/cart">
                Xem tất cả
              </Link>
            </div>
          </div>
          <div style={{width: '398px', maxHeight: '350px', overflow: 'scroll', marginBottom: '10px'}}>
            {cart?.reduce((arr, item) => arr.concat(item.carts), []).map((item, index) => (
              <CartData item={item} key={index}/>
            ))}
          </div>
        </div>
      </div>
    )
}

export default MyCart