import { useEffect, useState, memo } from 'react'
import { Space } from 'antd'
import { EmptyList, SubscriberCard } from 'components/card/card'
import { ThreeDotsIcon } from 'components/icon/icon'
import styles from './top-business.module.scss'
import LogoIcon from 'assets/img/store-default.png'
import { ButtonCustom } from 'components/Button/Button'


//api
import { topBusiness } from 'apis/business'

function TopBusiness({ classes = '' }) {
  const [shopList, setShopList] = useState([])

  const _getTopBusiness = async () => {
    try {
      const res = await topBusiness() 
      if(res.status === 200) setShopList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getTopBusiness()
  }, [])
  const displayListBussiness = () => {
    return shopList.map((business) => (
      <SubscriberCard
        key={business.business_id}
        prefix={business.prefix}
        classes="subscriber-bussiness"
        business_id={business.business_id}
        name={business.business_name}
        description={business.business_description || ''}
        avatar={{status: business.business_status || true, image: business.business_logo || LogoIcon}}
      >
        <ButtonCustom classes="btn__blue-sky" text="+ Theo dõi" />
      </SubscriberCard>
    ))
  }

  return (
    <div className={`${styles['top-business-container']} ${classes}`}>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <div className={styles['header']}>TOP CỬA HÀNG</div>
        <ThreeDotsIcon classes="icon__dot" />
      </Space>
      <div
        className={styles['top-business__list']}
        style={{ display: 'flex', rowGap: 24, flexDirection: 'column', marginTop: 21 }}
      >
        {shopList.length ? displayListBussiness() : <EmptyList />}
      </div>
    </div>
  )
}

export default memo(TopBusiness)
