import React from 'react'

import {Affix, Row} from 'antd'


export default function TitlePage({children, title = '', isAffix = false, top = 70, styles = {}}) {
    return isAffix ? (
        <Affix offsetTop={top} style={styles}>
            <Row
                wrap={false}
                align="middle"
                justify="space-between"
                style={{
                    borderBottom: '1px solid rgb(235, 223, 223)',
            paddingBottom: '1rem',
            padding: 15,
            backgroundColor: 'white',
            borderRadius: '5px',
                }}
            >
                <h3 style={{fontSize: 19, marginBottom: 0}}>{title}</h3>
                {children}
            </Row>
        </Affix>
    ) : (
        <Row
            wrap={false}
            align="middle"
            justify="space-between"
            style={{
                borderBottom: '1px solid rgb(235, 223, 223)',
                paddingBottom: '1rem',
                paddingTop: 5,
                backgroundColor: 'white',
            }}
        >
            <h3 style={{fontSize: 19, marginBottom: 0}}>{title}</h3>
            {children}
        </Row>
    )
}
