import { Button, Divider, Modal, notification, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import AvatarDefault from 'assets/img/avatar-profile.png'
import moment from 'moment'
import styles from '../styles/modal-share-post.module.scss'
import PublicIcon from 'assets/icons/public.png'
import PrivateIcon from 'assets/icons/private.png'
import { useDispatch, useSelector } from 'react-redux'
import { suggestUsers } from 'apis/feed'
import { SearchOutlined } from '@ant-design/icons'
import { addMessage } from 'redux/actions/message'
import { uploadFile } from 'apis/upload'
import html2canvas from 'html2canvas'

const ModalSharePost = ({ visible, setVisible, data }) => {
  const [text, setText] = useState('')
  const [search, setSearch] = useState('')
  const [suggestUserId] = useState(
    data?.creator_info?.followers?.filter((item) => data?.creator_info?.following?.includes(item))
  )
  const [suggestUser, setSuggestUser] = useState([])
  const [loading, setLoading] = useState(false)
  const refPost = useRef(null)
  const dispatch = useDispatch()
  const { socket } = useSelector((state) => state)
  let dataUser = useSelector((state) => state.userInfo)

  const handleInputText = (e) => {
    const textarea = document.getElementById('txt')

    textarea.addEventListener('input', function (e) {
      this.style.height = 'auto'
      this.style.height = this.scrollHeight + 'px'
    })
    setText(e.target.value)
  }

  const ItemUser = ({ item, index }) => {
    const [checkSend, setCheckSend] = useState(false)

    const handleSendText = async (id) => {
      try {
        if (checkSend === true) return

        const canvas = await html2canvas(refPost.current)
        const image = canvas.toDataURL('image/png', 1.0)

        let new_data = {
          url: image,
          type: "link",
        }
        let message = `${text} https://${process.env.REACT_APP_HOST}/detail-post/${data.post_id}`
        setText('')
        const msg = {
          sender_id: dataUser.user_id,
          recipient_id: id,
          text: message,
          media: [new_data],
          create_date: new Date().toISOString(),
        }
        setCheckSend(true)

        await dispatch(
          addMessage({
            msg,
            auth: dataUser,
            socket,
            share: true,
          })
        )
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <div
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div className={styles['info']}>
          <img
            style={{ width: '32px', height: '32px', marginRight: '11px', borderRadius: '50%' }}
            src={item.avatar || AvatarDefault}
            alt=""
          />
          <span className={styles['span-1']}>{item.first_name + ' ' + item.last_name}</span>
        </div>
        {checkSend === false ? (
          <Button onClick={async () => handleSendText(item.user_id)} type="primary">
            Gửi
          </Button>
        ) : (
          <Button disabled>Đã gửi</Button>
        )}
      </div>
    )
  }

  const getSuggestUsers = async () => {
    try {
      setLoading(true)
      let res = await suggestUsers({ ids: suggestUserId, search: search })
      if (res.status === 200) {
        setSuggestUser(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (visible === true && suggestUserId?.length > 0) {
      getSuggestUsers()
    }
  }, [visible])

  if (!data) return <></>
  return (
    <Modal
      bodyStyle={{ padding: '16px' }}
      visible={visible}
      title={[<h2>Chia sẻ qua tin nhắn</h2>]}
      closable={null}
      style={{ top: '10px' }}
      footer={[
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <Button
            onClick={() => {
              setVisible(false)
              setSearch('')
              setText('')
            }}
          >
            Đóng
          </Button>
        </div>,
      ]}
    >
      <div ref={refPost}>
        <div className={styles['box-post']}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              style={{ width: '40px', height: '40px', marginRight: '11px', borderRadius: '50%' }}
              src={data?.creator_info?.avatar || AvatarDefault}
              alt=""
            />
            <div className={styles['info']}>
              <span className={styles['span-1']}>
                {data?.creator_info?.first_name + ' ' + data?.creator_info?.last_name}
              </span>
              <span className={styles['span-2']}>
                {moment(data?.create_date).fromNow()}{' '}
                <img
                  style={{ width: '15px', height: '15px', marginTop: '-2px' }}
                  src={data?.status == 'public' ? PublicIcon : PrivateIcon}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ width: 'calc(100% - 111px)' }}>
              {data.title.length > 100 ? data.title.slice(0, 200) + '...' : data.title}
            </span>
            {data.images.length > 0 && (
              <img
                alt=""
                src={data?.images[0]?.url}
                style={{ width: '100px', height: '90px', marginLeft: '11px', borderRadius: '10px' }}
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles['box-message']}>
        <img
          alt=""
          src={dataUser.avatar || AvatarDefault}
          style={{ width: '30px', height: '30px', marginRight: '11px', borderRadius: '50%' }}
        />
        <textarea
          name="title"
          value={text}
          id="txt"
          placeholder={`Viết gì đó để gửi cho người ta...`}
          onChange={handleInputText}
        />
      </div>
      <Divider style={{ margin: 0 }} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div className={styles['box-search']}>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Tìm kiếm..."
            style={{
              borderStyle: 'hidden',
              outline: 'none',
              width: '80%',
              marginLeft: '7px',
              marginRight: '7px',
            }}
          />
        </div>
        <div onClick={getSuggestUsers} className={styles['box-icon-search']}>
          <SearchOutlined style={{ fontSize: 20 }} />
        </div>
      </div>

      <h3 style={{ marginTop: '8px' }}>Gợi ý:</h3>
      <div className={styles['box-user']}>
        {loading == true ? (
          <Spin />
        ) : (
          suggestUser?.map((item, index) => item && <ItemUser item={item} index={index} />)
        )}
      </div>
    </Modal>
  )
}

export default ModalSharePost
