import { getCartAllBusiness } from 'apis/cart'

export const _getCartAllBusiness = ({phone}) => async (dispatch) => {
    try {
      const res = await getCartAllBusiness({phone})
      if(res.status === 200) {
        dispatch({type: 'SET_MY_CART', data: res.data})
      }
    } catch (error) {
      console.log(error)
    }
  };