import { getUserPosts, likePostFeed, unLikePostFeed, pinPostFeed, unPinPostFeed, createPost, editPost, deletePost, getDetailPost } from 'apis/feed'
import { createCommentPost, deleteCommentPost, likeCommentPost, unLikeCommentPost, updateCommentPost } from "apis/comment"
import { DeleteData, EditData } from "./global"
import { uploadFile } from 'apis/upload'

export const getPostsUser = () => async (dispatch) => {
    try {
      const res = await getUserPosts()
      dispatch({ type: 'SET_POSTS_USER', payload: res.data.data });
    } catch (error) {
      console.log(error)
    }
  };
export const createPostUser = ({title, images, status, user}) => async (dispatch) => {
    let media = []
    try {
        if(images.length > 0) {
            for(let data of images) {
                const url_image = await uploadFile(data)
                let new_data = {
                    url: url_image,
                    type: data.type.slice(0,5)
                }
                media.push(new_data)
            }
        }
        let body = {
            title: title,
            images: media,
            status: status
        }

        const res = await createPost(body)

        dispatch({ 
            type: 'CREATE_POST_USER', 
            payload: {...res.data, creator_info: user} 
        })

    } catch (error) {
        console.log(error)
    }
}

export const getDetailPostUser = ({detailPost, id}) => async (dispatch) => {
  if(detailPost.every(post => post.post_id != id)){
      try {
          const res = await getDetailPost(id)
          console.log(res)
          dispatch({ type: 'GET_DETAIL_POST', payload: res.data })
      } catch (err) {
          console.log(err)
      }
  }
}

export const editPostUser = ({title, images, status, old_data}) => async (dispatch) => {
  let media = []
  const imgNewUrl = images.filter(img => !img.url)
  const imgOldUrl = images.filter(img => img.url)

  if(old_data.title === title 
      && imgNewUrl.length === 0
      && imgOldUrl.length === old_data.images.length
  ) return;

  try {
      if(imgNewUrl.length > 0) {
        for(let data of imgNewUrl) {
            const url_image = await uploadFile(data)
            let new_data = {
                url: url_image,
                type: data.type.slice(0,5)
            }
            media.push(new_data)
        }
    }
      const res = await editPost(old_data.post_id,{ 
        title, images: [...imgOldUrl, ...media] , status
      })

      dispatch({ type: 'UPDATE_POST_USER', payload: res.data })

  } catch (error) {
    console.log(error)  
  }
}

export const deletePostUser = ({post}) => async (dispatch) => {
  dispatch({ type: 'DELETE_POST_USER', payload: post })

  try {
      await deletePost(post.post_id)
      
  } catch (error) {
      console.log(error)
  }
}

export const likePostUser = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: [...post.likes, user.user_id]}
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost})
  
    try {
      await likePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  
export const unLikePostUser = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: post.likes.filter(id => id !== user.user_id )}
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost})
    try {
      await unLikePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  export const pinPostUser = ({post, user}) => async (dispatch) => {
    const newPost = {...post, pins: [...post?.pins, user.user_id]}
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost})
    try {
      await pinPostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  
export const unPinPostUser = ({post, user}) => async (dispatch) => {
    const newPost = {...post, pins: post?.pins?.filter(id => id !== user.user_id )}
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost})
    try {
      await unPinPostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  export const createCommentUser = ({post, newComment, user}) => async (dispatch) => {
    const newPost = {...post, comments: [...post.comments, newComment]}
    
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost })

    try {
        const data = {...newComment, post_id: post.post_id, post_user_id: post.creator_id}
        const res = await createCommentPost(data)
        const newData = {...res.data.data, user: user, tag: newComment.tag}
        const newPost = {...post, comments: [...post.comments, newData]}
        dispatch({ type: 'UPDATE_POST_USER', payload: newPost })

    } catch (error) {
        console.log(error)
    }
}

export const updateCommentUser = ({comment, post, content}) => async (dispatch) => {
    const newComments = EditData(post.comments, comment.comment_id, {...comment, comments: content})
    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost })
    try {
        await updateCommentPost(comment.comment_id, { content })
    } catch (error) {
        console.log(error)
    }
}

export const likeCommentUser = ({comment, post, user}) => async (dispatch) => {
    const newComment = {...comment, likes: [...comment.likes, user]}

    const newComments = EditData(post.comments, comment.comment_id, newComment)

    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost })

    try {
        await likeCommentPost(comment.comment_id)
    } catch (error) {
        console.log(error)
    }
}

export const unLikeCommentUser = ({comment, post, user}) => async (dispatch) => {

    const newComment = {...comment, likes: DeleteData(comment.likes, user.user_id)}

    const newComments = EditData(post.comments, comment.comment_id, newComment)

    const newPost = {...post, comments: newComments}
    
    dispatch({ type: 'UPDATE_POST_USER', payload: newPost })

    try {
        await unLikeCommentPost(comment.comment_id)
    } catch (error) {
        console.log(error)
    }
}

export const deleteCommentUser = ({post, comment, auth}) => async (dispatch) => {
    const deleteArr = [...post.comments.filter(cm => cm.comment_reply_id === comment.comment_id), comment]
    
    const newPost = {
        ...post,
        comments: post.comments.filter(cm => !deleteArr.find(da => cm.comment_id === da.comment_id))
    }

    dispatch({ type: 'UPDATE_POST_USER', payload: newPost })

    try {
       deleteArr.forEach(item => {
            deleteCommentPost(item.comment_id)
       })
    } catch (error) {
        console.log(error)
    }

}