/* eslint-disable jsx-a11y/alt-text */
import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _getCartAllBusiness } from 'redux/actions/cart'
import styles from './cart.module.scss'
import CartFooter from './layout-items/cart-footer'
import CartHeader from './layout-items/cart-header'
import CartShop from './layout-items/cart-shop'
import jwt_decode from 'jwt-decode'

const Cart = () => {
  const dispatch = useDispatch()
  let dataUser = useSelector((state) => state.userInfo)

  const cart = useSelector(state => state.cart)

  const [orders, setOrders] = useState([])

  console.log(orders)
  console.log(cart)
  
  const handleAddOrdersOfBusiness = (e, business_id) => {
    let newOrders = [...orders]
    let newCart = [...cart]
    if(newCart && newCart.length > 0) {
      if(e.target.checked === true) {
        if(!newOrders.find(item => item.business_id == business_id)) {
          newOrders.push(newCart.find(item => item.business_id == business_id))
        }
      } else {
        newOrders = newOrders.filter(item => item.business_id != business_id)
      }
    }
    setOrders(newOrders)
  }

  const handleAddOrdersOfProduct = (e, item_business, item_product ) => {
    let newOrders = JSON.parse(JSON.stringify(orders))
    let new_item_business = {...item_business}
    let new_item_product = {...item_product}


    if(cart && cart.length > 0) {
      let index = newOrders.findIndex(item => item.business_id == new_item_business.business_id)

      if(e.target.checked === true) {
        if(index !== -1) {
          let index_product = newOrders[index].carts.findIndex(item => item.variant_id == new_item_product.variant_id)
          if(index_product !== -1) {
            newOrders[index].carts[index_product] = new_item_product
          } else {
            newOrders[index].carts.push(new_item_product)
          }
        } else {
          let new_data = {
            business_id: new_item_business.business_id,
            business_logo: new_item_business.business_logo,
            business_name: new_item_business.business_name,
            carts: [new_item_product]
          }
          newOrders.push(new_data)
        }
      } else {
        let index_cart = cart.findIndex(item => item.business_id == new_item_business.business_id)

        console.log(index_cart)
        let index_product = newOrders[index].carts.findIndex(item => item.variant_id == new_item_product.variant_id)
        console.log(index_product)
        if(index_product !== -1) {
          newOrders[index].carts.splice(index_product, 1)

          if(newOrders[index].carts.length == 0) {
            newOrders.splice(index, 1)
          }
        }

      }
    }
    setOrders(newOrders)
  }

  const handleAddAllOrders = (e) => {
    let newOrders = []
    if(e.target.checked === true) {
      if(cart && cart.length > 0) {
        for(let data of cart) {
          newOrders = newOrders.concat(data)
        }
      }
    }
    setOrders(newOrders)
  }


  useEffect(() => {
    if(dataUser) {
      dispatch(_getCartAllBusiness({phone: dataUser.phone}))
    }
  },[dataUser])

  return (
    <div style={{marginRight: '15px'}}>
      <div className={styles['cart-layout']}>
        <h1 className={styles['cart-header-h1']}>Giỏ hàng</h1>

        <CartHeader orders={orders} handleAddAllOrders={handleAddAllOrders}/>
        {cart && cart.map((item, index) => (
          <CartShop orders={orders} key={index} item={item} handleAddOrdersOfBusiness={handleAddOrdersOfBusiness} handleAddOrdersOfProduct={handleAddOrdersOfProduct}/>
        ))}
      </div>
      <CartFooter cart={cart} orders={orders} handleAddAllOrders={handleAddAllOrders} />
    </div>
  )
}

export default Cart
