import { Select } from 'antd'
import React from 'react'

export default function PrefixSelector() {
  return (
      <Select
        size="large"
        defaultValue={'+84'}
        value={'+84'}
        style={{
          width: 80,
        }}
        className={'input-phone-antd'}
      >
        <Select.Option value="+84">+84</Select.Option>
        <Select.Option value="+83">+83</Select.Option>
      </Select>
  )
}
