import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import defaultLogoUser from 'assets/img/avatar-round.png'
import defaultLogoBusiness from 'assets/img/store-default.png'

import ListPeople from './item/ListPeople'
import ListBusiness from './item/ListBusiness'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { searchBusinessAndUser } from 'apis/search'

export default function Search() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const state = JSON.parse(decodeURIComponent(params.get('q')))
  const [business, setBusiness] = useState([])
  const [users, setUsers] = useState([])
  const getDataBusinessAndUser = async () => {
    let search = state !== '' ? state.trim() : ''
    const res = await searchBusinessAndUser({ search: search })
    if (res.status === 200) {
      setBusiness(res.data.data.business)
      setUsers(res.data.data.users)
    }
  }
  useEffect(() => {
    getDataBusinessAndUser()
  }, [state])
  return (
    <div className={styles['container']}>
      {users.length > 0 ? <ListPeople people={users} /> : null}
      {business.length > 0 ? <ListBusiness business={business} /> : null}
    </div>
  )
}
