import { postVisit } from 'apis/visit'

// Tạo Session ID ngẫu nhiên
function generateSessionId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}
// Hàm hỗ trợ lưu trữ cookie
function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

// Hàm hỗ trợ lấy giá trị cookie
function getCookie(name) {
  var nameEQ = name + '='
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }
  return null
}
// Kiểm tra và lấy Session ID từ cookie
function getSessionId() {
  var sessionId = getCookie('sessionId')
  if (!sessionId) {
    sessionId = generateSessionId()
    setCookie('sessionId', sessionId, 1) // Lưu trữ Session ID trong cookie trong 1 ngày
  }
  return sessionId
}

export async function Visit() {
  try {
    let sessionId = getSessionId()
    setTimeout(500)
    if (sessionId) {
      await postVisit({ sessionId: sessionId})
    }
  } catch (error) {
    // log
  }
}
