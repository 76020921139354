import { Button, Radio, Space } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import styles from '../styles/purchase.module.scss'

import MomoLogo from 'assets/icons/Momo.png'
import ATMLogo from 'assets/icons/ATM.png'
import BankingLogo from 'assets/icons/Banking.png'
import VNPayLogo from 'assets/icons/VNPay.png'
import { CloseOutlined } from '@ant-design/icons'
import { getUrlVNPay } from 'apis/payment'

const Purchase = ({orders}) => {
  const [visiblePurchase, setVisiblePurchase] = useState(false)

  const [value, setValue] = useState(null)

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }

  const handlePurchase = async () => {
    try {
      switch (value) {
        case 1:
          
          break;
      
        default:
          break;
      }

      let body = {
        amount: orders.reduce((total, item) => total += item.carts.reduce((total2, item2) => total2 += item2.quantity * item2.price, 0), 0),
        orderInfo: 'Thanh toán đơn hàng',
        orderType: '',
      }
      let res = await getUrlVNPay(body)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Button
        onClick={() => setVisiblePurchase(true)}
        style={{ background: '#1e4db7', color: 'white' }}
      >
        Mua hàng
      </Button>
      <Modal
        width={'524.11px'}
        visible={visiblePurchase}
        footer=""
        closable={false}
      >
        <div style={{display: 'flex', justifyContent: 'end'}}>
          <CloseOutlined onClick={() => setVisiblePurchase(false)} style={{fontSize: '20px', cursor: 'pointer'}}/>
        </div>
        <div className={styles['title']}>
          <h1>Chọn phương thức thanh toán</h1>
        </div>
        <div>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}><img style={{width: '22px', height: '22px'}} alt="" src={BankingLogo}/> Chuyển khoản ngân hàng</Radio>
              <Radio value={2}>
              <img style={{width: '22px', height: '22px'}} alt="" src={ATMLogo}/> Qua thẻ ATM {'('}có Internet Banking{')'}
              </Radio>
              <Radio value={3}><img style={{width: '22px', height: '22px'}} alt="" src={VNPayLogo}/> Qua VNPAY-QR</Radio>
              <Radio value={4}><img style={{width: '22px', height: '22px'}} alt="" src={MomoLogo}/> Ví Momo</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div style={{marginTop: '10px', padding: '0 20%'}}>
          <Button onClick={handlePurchase} style={{ width: '100%' }} type="primary">
            Xác nhận
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default Purchase
