import { Button } from 'antd'
import React from 'react'
import styles from './status.module.scss'

const StatusCheck = () => {
    return (
        <div className={styles['check-status']}>
            <div>
                <span className={styles['span-0']}>Đơn hàng sẽ được giao trước 12-12-2022. Bạn có thể kiểm tra hàng sau khi thanh toán</span>
            </div>
            <Button disabled><span className={styles['span-1']}>Đã nhận hàng</span></Button>
        </div>
    )
}

export default StatusCheck