import { Button, Dropdown, Menu, Space } from 'antd'
import React, { useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import styles from './notification.module.scss'
import { deleteNotificationStaff } from 'apis/nofitication'

const NotificationMenuItem = (notification) => {
  const [loading, setLoading] = useState(false)

  const onDeleteNotification = async () => {
    try {
      setLoading(true)
      const body = {
        notification_staff_id: notification.notification_staff_id,
      }
      let res
      res = await deleteNotificationStaff(body)
      console.log(body)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const menu = (
    <Menu
      items={[
        {
          label: <a href="">Đã xem thông báo</a>,
          key: '0',
        },
        {
          label: <Button onClick={onDeleteNotification}>Xoá Thông Báo</Button>,
          key: '1',
        },
        {
          label: <a href="">Tắt Thông Báo</a>,
          key: '2',
        },
      ]}
    />
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Button
            className={styles['hover-button']}
            style={{ borderRadius: '50%' }}
            shape="circle"
            icon={<EllipsisOutlined />}
          />
        </Space>
      </a>
    </Dropdown>
  )
}
export default NotificationMenuItem
