import { Checkbox, Form, Input, Select, notification } from 'antd'
import React, { useState } from 'react'
import styles from '../styles/loginForm.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { login } from 'apis/auth'
import ButtonCustom from 'components/Button/buttonCustom'
import { ROUTES } from 'consts'
import { isMobile } from 'react-device-detect'
import {ExclamationCircleOutlined} from "@ant-design/icons"
import PrefixSelector from 'components/prefix/prefixSelector'

const LoginForm = () => {
  const [formLogin] = Form.useForm()
  const history = useHistory()

  const _login = async () => {
    try {
      await formLogin.validateFields()
      const dataForm = formLogin.getFieldsValue()
      const res = await login(dataForm)
      if (res.status === 200) {
        localStorage.setItem('accessToken', res.data.accessToken)
        history.push(isMobile ? '/point' : '/')
      } else {
        if (res.data.type === 'auth') {
          formLogin.setFields([
            {
              name: 'phone',
              errors: [res.data.message],
            },
          ])
        }
        if (res.data.type === 'password') {
          formLogin.setFields([
            {
              name: 'password',
              errors: [res.data.message],
            },
          ])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Form
        requiredMark={false}
        form={formLogin}
        onFinish={_login}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Form.Item
          style={{ marginBottom: '30px' }}
          label={<p className={styles['label-style']}>Số điện thoại</p>}
          name="phone"
          rules={[
            { required: true, message: 'Vui lòng nhập số điện thoại', whitespace: true },
            {
              pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
              message: 'Vui lòng nhập số điện thoại đúng định dạng',
            },
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            prefix={<PrefixSelector/>}
            allowClear
            className={styles['input-item']}
            size="large"
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: '24px' }}
          label={<p className={styles['label-style']}>Mật khẩu</p>}
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
        >
          <Input.Password
            className={styles['input-item']}
            size="large"
            type="password"
            placeholder="Nhập mật khẩu"
          />
        </Form.Item>

        <div className={styles['another-frame']}>
          <Form.Item style={{ marginBottom: '24px' }} name="remember" valuePropName="checked">
            <Checkbox>
              <p className={styles['checkbox-style']}>Lưu đăng nhập 30 ngày</p>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Link to={ROUTES.FORGET_PASSWORD}>
              <p className={styles['link-style']}>Quên mật khẩu</p>
            </Link>
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonCustom htmlType="submit" text={'Đăng nhập'} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginForm
