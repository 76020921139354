import { HeartFilled, HeartOutlined, MessageOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Divider, Menu, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from '../styles/post.module.scss'
import DeleteIcon from 'assets/icons/delete-icon.png'
import ShareIcon from 'assets/icons/share-icon.png'
import CopyLinkIcon from 'assets/icons/link-icon.png'
import PublicIcon from 'assets/icons/public-icon.png'
import EditPost from '../status/editPost'
import { useDispatch, useSelector } from 'react-redux'
import { deletePostUser, likePostUser, unLikePostUser } from 'redux/actions/postsUser'
import { useHistory } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import ModalSharePost from './modalSharePost'

export const InteractionIcons = ({ data, setCheckComment, checkComment, check }) => {
  const [like, setLike] = useState(false)
  const [loadLike, setLoadLike] = useState(false)
  const [visibleSharePost, setVisibleSharePost] = useState(false)
  const dispatch = useDispatch()
  let dataUser = useSelector((state) => state.userInfo)
  const history = useHistory()

  const handleLike = async () => {
    if (loadLike) return

    setLoadLike(true)
    await dispatch(likePostUser({ post: data, user: dataUser }))
    setLoadLike(false)
  }

  const handleUnLike = async () => {
    if (loadLike) return

    setLoadLike(true)
    await dispatch(unLikePostUser({ post: data, user: dataUser }))
    setLoadLike(false)
  }

  useEffect(() => {
    if (data.likes.find((like) => like === dataUser.user_id)) {
      setLike(true)
    } else {
      setLike(false)
    }
  }, [data.likes, dataUser.user_id])
  return (
    <>
      <Row className={styles['icons']}>
        <div className={styles['icon']}>
          {like ? (
            <HeartFilled style={{ color: 'red' }} onClick={handleUnLike} />
          ) : (
            <HeartOutlined onClick={handleLike} />
          )}
        </div>
        <div className={styles['icon']}>
          <MessageOutlined
            onClick={() => {
              if (check) {
                history.push(`/detail-post/${data.post_id}`)
              }
              setCheckComment(checkComment + 1)
            }}
          />
        </div>
        <div className={styles['icon']}>
          <ShareAltOutlined  onClick={() => setVisibleSharePost(true)}/>
        </div>
      </Row>
      <ModalSharePost visible={visibleSharePost} setVisible={setVisibleSharePost} data={data}/>
    </>
  )
}

export const MenuEditPost = ({ data, setVisibleMenuEdit }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  let dataUser = useSelector((state) => state.userInfo)

  const handleDeletePost = () => {
    if (window.confirm('Bạn có muốn xoá bài viết không?')) {
      dispatch(deletePostUser({ post: data }))
      setVisibleMenuEdit(false)
      return history.push('/')
    }
  }

  const handleCopyLink = () => {
    try {
      copy(`https://${process.env.REACT_APP_HOST}/detail-post/${data.post_id}`)
      notification.success({ key: 'Copy Link', message: 'Sao chép liên kết thành công!' })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Menu>
      <div style={{ padding: '10px 16px 10px 16px' }}>
        {dataUser?.user_id === data?.creator_id && (
          <>
            <EditPost data={data} setVisibleMenuEdit={setVisibleMenuEdit} />
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
          </>
        )}

        <div onClick={handleCopyLink} className={styles['item-edit']}>
          <span>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px' }}
              alt=""
              src={CopyLinkIcon}
            />{' '}
            Sao chép liên kết
          </span>
        </div>
        <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
        <div className={styles['item-edit']}>
          <span>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px' }}
              alt=""
              src={ShareIcon}
            />{' '}
            Chia sẻ
          </span>
        </div>
        {dataUser?.user_id === data?.creator_id && (
          <>
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            <div className={styles['item-edit']}>
              <span>
                <img
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                  alt=""
                  src={PublicIcon}
                />{' '}
                Chỉnh sửa đối tượng
              </span>
            </div>
          </>
        )}
        {dataUser?.user_id === data?.creator_id && (
          <>
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            <div onClick={handleDeletePost} className={styles['item-edit']}>
              <span>
                <img
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                  alt=""
                  src={DeleteIcon}
                />{' '}
                Xoá bài viết
              </span>
            </div>
          </>
        )}
      </div>
    </Menu>
  )
}

export const MenuInteraction = () => {
  return (
    <Menu>
      <div style={{ padding: '10px 16px 10px 16px' }}>
        <h2>Chọn cách tương tác</h2>
        <span>
          Bạn có thể tương tác dưới tên<br></br> trang cá nhân hoặc Trang mình<br></br> quản lý
        </span>
        <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />

        <div className={styles['item-edit']}>
          <span>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px' }}
              alt=""
              src={CopyLinkIcon}
            />{' '}
            Trang cá nhân
          </span>
        </div>
        <div className={styles['item-edit']}>
          <span>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px' }}
              alt=""
              src={ShareIcon}
            />{' '}
            Cửa hàng của bạn
          </span>
        </div>
      </div>
    </Menu>
  )
}
