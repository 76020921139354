import React, { useState } from 'react'
import { UserAddOutlined } from '@ant-design/icons'
import styles from '../styles/left-side.module.scss'
import AvatarDefault from 'assets/img/avatar-profile.png'
import AddUserInbox from './addUserInbox'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { searchUserChat } from 'apis/message'
import { notification } from 'antd'
import { useEffect } from 'react'
import { getConversationsUser } from 'redux/actions/message'
import { useRef } from 'react'

export const ItemPerson = ({ item, handleAddUser }) => {
  const { id } = useParams()

  if (!item) return <></>
  return (
    <div
      style={{ backgroundColor: id && item.user_id == id ? '#f0f0f0' : '', cursor: 'pointer' }}
      className={styles['layout-item']}
      onClick={() => handleAddUser(item)}
    >
      <img
        src={item.avatar || AvatarDefault}
        alt=""
        style={{ width: '56px', height: '56px', marginRight: '7px', borderRadius: '50%' }}
      />
      <div className={styles['child-text']}>
        <span className={styles['span-1']}>{item.first_name + ' ' + item.last_name}</span>
        <span className={styles['span-2']}>{item.message || 'Hello Ekata'}</span>
      </div>
    </div>
  )
}

const LeftSide = () => {
  const { message } = useSelector((state) => state)
  let dataUser = useSelector((state) => state.userInfo)
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [searchUsers, setSearchUsers] = useState([])

  const pageEnd = useRef()
  const [page, setPage] = useState(0)

  const history = useHistory()
  const handleSearch = async (e) => {
    e.preventDefault()
    if (!search) return setSearchUsers([])

    try {
      const res = await searchUserChat({ phone: search })
      if (res.data.data) {
        let new_user = [...searchUsers]
        if(!new_user?.find(item => item?.user_id == res.data.data.user_id)) {
          new_user.push(res.data.data)
        }
        setSearchUsers(new_user)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddUser = (item) => {
    if(item) {
      setSearch('')
      setSearchUsers([])
      dispatch({type: 'ADD_USER', payload: {...item, text: '', media: []}})
      return history.push(`/conversation/${item.user_id}`)
    } else {
      if (searchUsers?.length > 0) {
        for (let data of searchUsers) {
          dispatch({ type: 'ADD_USER', payload: { ...data, text: '', media: [] } })
        }
      } else {
        return notification.error({ message: 'Danh sách người dùng thêm vào tin nhắn không có' })
      }
      setSearchUsers([])
      setSearch('')
      setVisible(false)
      return history.push(`/conversation/${searchUsers[0].user_id}`)
    }
  }

  useEffect(() => {
    if (message.firstLoad) return
    dispatch(getConversationsUser({ auth: dataUser }))
  }, [dispatch, dataUser, message.firstLoad])

  // Load More
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((p) => p + 1)
        }
      },
      {
        threshold: 0.1,
      }
    )

    observer.observe(pageEnd.current)
  }, [setPage])

  useEffect(() => {
    if (message.resultUsers >= (page - 1) * 9 && page > 1) {
      dispatch(getConversationsUser({ auth: dataUser, page }))
    }
  }, [message.resultUsers, page, dataUser, dispatch])

  return (
    <>
      <div className={styles['left-side']}>
        <div className={styles['header']}>
          <div className={styles['header-name']}>
            <span>{dataUser?.first_name + ' ' + dataUser?.last_name}</span>
          </div>
          <div className={styles['header-icon']}>
            <UserAddOutlined onClick={() => setVisible(true)} style={{ cursor: 'pointer' }} />
          </div>
        </div>

        <div className={styles['body']}>
          {message.users.map((item, index) => (
            <ItemPerson key={index} item={item} handleAddUser={handleAddUser} />
          ))}
          <button ref={pageEnd} style={{opacity: 0}}>Load More Message</button>
        </div>
      </div>

      <AddUserInbox
        visible={visible}
        setVisible={setVisible}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        searchUsers={searchUsers}
        handleAddUser={handleAddUser}
        setSearchUsers={setSearchUsers}
      />
    </>
  )
}

export default LeftSide
