import { Col, Row } from 'antd'
import Product from 'components/Product/Product'

function Products({ list, handleAddToCart }) {
  if (list && list.length)
    return (
      <Row style={{ width: '100%' }} gutter={[10, 10]}>
        {list.map((product, index) => (
          <Col span={8} key={index}>
            <Product product={product} handleAddToCart={handleAddToCart} />
          </Col>
        ))}
      </Row>
    )

  return <h1 style={{ textAlign: 'center', marginTop: '15px', fontWeight: 700 }}>Danh sách rỗng</h1>
}

export default Products
