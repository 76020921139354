import React from 'react'
import { Card, Table } from 'antd'
import styles from '../point.module.scss'
import moment from 'moment'
import { formatCash } from 'utils'
import defaultLogo from 'assets/img/store-default.png'

const HistoryPoint = ({ point }) => {
  const columnSpending = [
    {
      render: (text, record) => (
        <img
          src={record.logo ? record.logo : defaultLogo}
          className={styles['business-logo']}
          alt=""
        />
      ),
      width: '15%',
      align: 'center',
    },
    {
      render: (text, record) => record.point > 0 ? (
        <>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Tích điểm tại {record.business_name} - {record.branch}
            </p>
            <p>{moment(record.time).format('DD/MM/YYYY HH:mm')}</p>
          </div>
        </>
      ) : (
        <>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Dùng điểm tại {record.business_name} - {record.branch}
            </p>
            <p>{moment(record.time).format('DD/MM/YYYY HH:mm')}</p>
          </div>
        </>
      ), 
      maxWidth: '65%',
    },
    {
      render: (text, record) =>
        Number(record.point) > 0 ? (
          <>
            <p style={{textAlign: 'end' }}>+{formatCash(record.point)}</p>
            <p style={{ color: 'green', textAlign: 'end' }}>Thành công</p>
          </>
        ) : (
          <>
            <p style={{textAlign: 'end' }}>{formatCash(record.point)}</p>
            <p style={{ color: 'green', textAlign: 'end' }}>Thành công</p>
          </>
        ),
        width:'20%'
    },
  ]

  return (
    <Card disabled style={{ marginTop: '16px', borderRadius: '14px', marginBottom: '16px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.04)' }}>
      <div className={styles['title']}>Giao dịch gần đây</div>
      <div>
        <Table
          size="small"
          columns={columnSpending}
          dataSource={point.listPoints}
          style={{ width: '100%' }}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </div>
    </Card>
  )
}

export default HistoryPoint
