import { notification } from 'antd'
import { deleteVoucherUser, getAllVoucher, getMyVoucher, saveVoucherUser } from 'apis/voucher'

export const getVoucherPageSize =
  ({ paramsFilter }) =>
  async (dispatch) => {
    try {
      if(paramsFilter.type == 'total') {
        const res = await getAllVoucher({ ...paramsFilter })
        dispatch({
          type: 'SET_VOUCHER',
          data: res.data.data,
          count: res.data.count,
        })
      }

      if(paramsFilter.type == 'me') {
        const res2 = await getMyVoucher({ ...paramsFilter })
        dispatch({
          type: 'SET_MY_VOUCHER',
          data: res2.data.data,
          count: res2.data.count,
        })
      }

    } catch (error) {
      console.log(error)
    }
  }

export const deleteVoucher =
  ({ voucher, user_id, value }) =>
  async (dispatch) => {
    dispatch({type: 'UPDATE_DELETE_VOUCHER', data: voucher, user_id: user_id})
    try {
      let body = {
        voucher_id: voucher.voucher_id,
      }
      await deleteVoucherUser(body)
      notification.success({ message: 'Xoá voucher thành công!' })
    } catch (error) {
      console.log(error)
    }
  }
export const saveVoucher =
  ({ voucher, user_id, value }) =>
  async (dispatch) => {
    dispatch({type: 'UPDATE_SAVE_VOUCHER', data: voucher, user_id: user_id})
    try {
      let body = {
        voucher_id: voucher.voucher_id,
      }
      if (value === true) {
        await saveVoucherUser(body)
        notification.success({ message: 'Voucher đã được thêm vào kho voucher của bạn' })
      }
    } catch (error) {
      console.log(error)
    }
  }
