import styles from './register-business.module.scss'
import businessSuccess from 'assets/img/success-business-1.png'
import businessSuccessTick from 'assets/img/success-business-2.png'
import { Button, notification } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBusiness } from 'apis/business'
import { Redirect, useHistory } from 'react-router-dom'


const BusinessFinished = () => {
  const history = useHistory()
  let myBusinessNew = useSelector((state) => state.myBusiness)
  let myBusiness = myBusinessNew.find((item) => item.user_id === item.creator_id)
  const { subdomain, business_id } = myBusiness
  const _delete = async () => {
    try {
      const res = await deleteBusiness(business_id)
      if (res.status === 200) {
        notification.success({ key: 'delete', message: 'Xóa cửa hàng thành công' })
        const location = {
          pathname: '/point',
        }
        history.push(location)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  
  if (myBusinessNew.find((item) => item.user_id === item.creator_id && item.active === true)) {
    return <Redirect to="/" />
  }
  return (
    <div className={styles['result']}>
      <img
        style={{
          width: 300,
        }}
        src={businessSuccess}
      />
      <img src={businessSuccessTick} />
      <h2>Xin chúc mừng!</h2>
      <p>Cửa hàng của bạn đã được đăng ký. Bạn vui lòng đợi để hệ thống xác nhận cửa hàng.</p>
      <p>Mọi thắc mắc xin vui lòng liên hệ đến hotline: 0967487179 để được hổ trợ</p>
      <div>
        <Button onClick={() => _delete()} type="primary" className={styles['delete-business-btn']}>
          Xóa cửa hàng
        </Button>
        <a href={['http://', subdomain, '.', process.env.REACT_APP_POS_HOST].join('')}
            target="_blank"
            rel="noreferrer">
        <Button type="primary" className={styles['visit-business-btn']}>
          Vào cửa hàng
        </Button>
        </a>
        
      </div>
    </div>
  )
}

export default BusinessFinished
