import {each} from 'underscore'

let initialState = [];

const business = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BUSINESS': {
            state = action.data;
            return [...state]
        }

        case 'UPDATE_BUSINESS': {
            const updateField = [
                'status', 'profile_status', 'business_name',
                'company_website', 'company_address', 'company_district',
                'company_province', 'company_phone', 'career_id'
            ];
            each(state, (item, index) => {
                if (item.business_id == action.data.business_id) {
                    each(updateField, (field) => {
                        if (typeof action.data[field] != 'undefined') {
                            state[index][field] = action.data[field];
                        }
                    })
                }
            })
            return [...state]
        }

        default:
            return state
    }
}

export default business
