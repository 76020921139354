let initialState = [];
const myBusiness = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MY_BUSINESS': {
            state = action.data;
            return [...state]
        }

        default:
            return state
    }
}

export default myBusiness
