import React, { memo, useEffect, useState } from 'react'

import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  ShopInfo,
  ShopAvatar,
  ShopName,
  TotalSpending,
  TotalPoint,
  ShopNameHeader,
  TotalSpendingHeader,
  TotalPointHeader,
} from './styled.components'
import { getPointBusiness } from 'apis/point'
import { formatCash } from 'utils'
import defaultLogo from 'assets/img/logo-icon.png'
import emptyPointIcon from './icons/empty.png'

function BusinessPointTable() {
  const [pointInBusiness, setPointInBusiness] = useState([])

  const _getPointInBusiness = async () => {
    try {
      const res = await getPointBusiness()
      if (res.status === 200) {
        setPointInBusiness(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    _getPointInBusiness()
  }, [])

  return (
    <>
      <Table>
        <TableHeader>Điểm tại cửa hàng</TableHeader>
        <TableBody>
          <TableItem>
            <ShopInfo>
              <ShopNameHeader>Thông tin cửa hàng</ShopNameHeader>
            </ShopInfo>
            <TotalSpendingHeader>Tổng chi tiêu</TotalSpendingHeader>
            <TotalPointHeader>Tổng điểm</TotalPointHeader>
          </TableItem>

          {pointInBusiness.length == 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={emptyPointIcon} />
              <p>Chưa có tích điểm nào. Tiếp tục mua sắm để tích điểm nhé</p>
            </div>
          )}

          {pointInBusiness.length > 0 &&
            pointInBusiness.map((business) => (
              <TableItem>
                <ShopInfo>
                  <ShopAvatar avatar={business.logo ? business.logo : defaultLogo} />
                  <ShopName>{business.business_name}</ShopName>
                </ShopInfo>
                <TotalSpending>{formatCash(business?.order_total_cost)}đ</TotalSpending>
                <TotalPoint>{formatCash(business?.point + business?.used_point) || 0}</TotalPoint>
              </TableItem>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

export default memo(BusinessPointTable)
